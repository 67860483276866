import React, { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';

import AccountOffersPage from './Offers';
import OfferCreatorPage from './OfferCreator';
import InvoicesPage from './Invoices';
import InquiriesPage from './Inquiries';
import SettingsPage from './Settings';
import NotFound from '../static/NotFound';

const Account = ({ USER }) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === '/konto' || location.pathname === '/konto/')
      history.push('/konto/kuulutused');
  }, [history, location]);

  return (
    <Switch>
      <Route
        path="/konto/kuulutused/lisa"
        render={() => <OfferCreatorPage USER={USER} />}
      />
      <Route
        path="/konto/kuulutused/muuda/:id"
        render={() => <OfferCreatorPage editMode USER={USER} />}
      />
      <Route
        path="/konto/kuulutused"
        render={() => <AccountOffersPage USER={USER} />}
      />
      <Route
        path="/konto/kuulutused/muuda/:id"
        render={() => <OfferCreatorPage USER={USER} editMode />}
      />
      <Route path="/konto/arved" render={() => <InvoicesPage USER={USER} />} />
      <Route
        path="/konto/paringud"
        render={() => <InquiriesPage USER={USER} />}
      />
      <Route path="/konto/seaded" render={() => <SettingsPage USER={USER} />} />
      {/* 404 ROUTE */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Account;
