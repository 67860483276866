import React, { useState } from 'react';

import Button from '../components/UI/Button';
import Box from '../components/UI/Layout/Box';
import Image from '../components/UI/Image';
import Container from '../components/UI/Layout/Container';
import Search from '../components/Search';
import Catalogue from '../components/Catalogue';
import Hero from '../components/Hero';
import { OffsetWrapper } from '../components/Hero/styles';
import Header from '../components/Header';
import Banner from '../components/Banner';
import { HideMobile, HideDesktop } from '../components/UI/GlobalStyle';
import Flex from '../components/UI/Layout/Flex';
import Latest from '../components/Catalogue/Latest';
import Link from '../components/UI/Link';
import MailingForm from '../components/MailingForm';
import Footer from '../components/Footer';

const Home = ({ offers }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [fields, setFields] = useState(null);
  return (
    <>
      <Header />
      <Hero open={searchOpen} setOpen={setSearchOpen} />
      <Container>
        <OffsetWrapper>
          <Flex display={['flex', 'inline-flex']}>
            <Flex width={[0, 0, 0, 0, '320px']}>
              <Search
                open={searchOpen}
                setOpen={setSearchOpen}
                fields={fields}
                setFields={setFields}
              />
              <HideMobile>
                <Banner mt="1rem">
                  <Image
                    src="https://jayinniss.files.wordpress.com/2015/03/rhino-metaphor.jpg"
                    alt=""
                    borderRadius="5px"
                    objectFit="cover"
                  />
                </Banner>
              </HideMobile>
            </Flex>
            <Flex width={[1, 1, 1, 1, 3 / 4]}>
              <Catalogue fields={fields} home offers={offers} />
            </Flex>
          </Flex>
        </OffsetWrapper>
        <HideDesktop>
          <Banner m="1.5rem">
            <Image
              src="https://jayinniss.files.wordpress.com/2015/03/rhino-metaphor.jpg"
              alt=""
              borderRadius="5px"
              objectFit="cover"
              width="100%"
              maxHeight="300px"
            />
          </Banner>
        </HideDesktop>
        <Latest offers={offers} />
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          m="2rem 0"
        >
          <Link to="/kuulutused">
            <Button version="primary-hollow">Kõik kuulutused</Button>
          </Link>
        </Flex>
        <HideMobile>
          <Box m="3rem 6rem">
            <Banner>
              <Image
                src="https://www.tvadsongs.uk/wp-content/uploads/peugeot-2008-suv-2020-advert-music-8211-waiting-for-the-stars-8OJKL9O3UWU.jpg"
                alt=""
                height="234px"
                width="100%"
                objectFit="cover"
              />
            </Banner>
          </Box>
        </HideMobile>
      </Container>
      <MailingForm />
      <Footer />
    </>
  );
};

const svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.728"
    height="10.234"
    viewBox="0 0 7.728 10.234"
  >
    <path
      d="M.131-3.293H1.19a5.933,5.933,0,0,1,.473-1.592A4.3,4.3,0,0,1,2.489-9.1a3.421,3.421,0,0,1,1.134-.733,3.305,3.305,0,0,1,1.4-.233,3.9,3.9,0,0,1,.838.091,3.323,3.323,0,0,1,.739.259,3.033,3.033,0,0,1,.323.413,3.99,3.99,0,0,1,.532.553l-.434.473a.3.3,0,0,1-.109.109.253.253,0,0,1-.157.045.332.332,0,0,1-.249-.133,2.907,2.907,0,0,0-.343-.294A2.431,2.431,0,0,0,5.933-8.9a2.333,2.333,0,0,0-.831-.133,2.23,2.23,0,0,0-1.7.7,3.732,3.732,0,0,0-.9,2.044H3.307v.385a.323.323,0,0,1-.094.228.35.35,0,0,1-.27.1H2.394q-.007.14-.01.28t0,.287V-4.8q0,.105.007.21H5.353v.392a.308.308,0,0,1-.1.22.355.355,0,0,1-.233.1H2.45a3.888,3.888,0,0,0,.834,2.133,2.203,2.203,0,0,0,1.391.721,2.7,2.7,0,0,0,.335-.074,2.235,2.235,0,0,0,.5-.183,1.977,1.977,0,0,0,.331-.242q.15-.13.232-.242t.2-.183a.239.239,0,0,1,.171-.073.234.234,0,0,1,.105.021.407.407,0,0,1,.1.077l.525.49A3.3,3.3,0,0,1,3.334-.294a3.53,3.53,0,0,1-1.722.4A3.708,3.708,0,0,1,3.479-.172,3.282,3.282,0,0,1,2.349-.933a4.033,4.033,0,0,1-.78-1.253,3.444,3.444,0,0,1-.413-1.353H.131v-.714H1.1q-.007-.105-.007-.21t0-.217q0-.14,0-.284t.01-.284H.131Z"
      transform="translate(-0.131 10.69)"
      fill="#494949"
    />
  </svg>
);

export default Home;
