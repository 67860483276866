import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  ${(props) =>
    props.size === "medium" &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.size === "small" &&
    css`
      width: 170px;
    `}
  ${(props) =>
    props.size === "xsmall" &&
    css`
      width: 100px;
    `}
  ${(props) =>
    props.size === "xxsmall" &&
    css`
      width: 50px;
    `}
`;
export const Label = styled.label`
  font-size: 1rem;
  font-family: "Lato-Bold";
  line-height: 1.8;
`;

export const Icon = styled.div`
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  user-select: none;
  pointer-events: none;
  svg {
    width: 15px;
    height: 15px;
  }
  ${(props) =>
    props.position === "right" &&
    css`
      left: unset;
      right: 0.6rem;
    `}
`;
export const ErrorIcon = styled(Icon)`
  right: 2.85rem;
  left: unset;
`;

export const NumberController = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid #bebeca;
  user-select: none;
`;

export const Input = styled.div`
  position: relative;
  input {
    border: 1px solid #bebeca;
    padding: 0.5rem;
    padding-right: 2.75rem;
    font-size: 14px;
    color: #494949;
    font-family: "Lato-Bold";
    border-radius: 3px;
    width: 100%;
    transition: 0.15s border ease;
    -moz-appearance: textfield;
    &::placeholder {
      font-family: "Lato-Regular";
      color: #8c8c8c;
    }
    &:hover {
      transition: 0.15s border ease;
      border: 1px solid #596d87;
    }
    &:focus {
      transition: 0.15s border ease;
      border: 1px solid #1774ed;
    }
    &:disabled {
        background: #F9F9F9;
        cursor: not-allowed;
        &::placeholder {
            color: #D4D4D4;
        }
        &:hover {
            border: 1px solid #bebeca;
        }
    }
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
  }
  ${(props) =>
    props.invalid &&
    css`
      input {
        border: 1px solid #dd2121;
        padding-right: 4.25rem;
      }
      ${NumberController} {
        border-left: 1px solid #dd2121;
      }
    `}
  ${(props) =>
    props.icon &&
    css`
      input {
        padding-left: 2rem;
      }
    `}
    ${(props) =>
      props.icon &&
      props.invalid &&
      css`
        ${Icon} {
          right: 4rem;
          ~ input {
            padding-left: 2rem;
          }
        }
        ~ input {
          padding-right: 3.25rem;
        }
        ${props.icon !== "left" &&
        css`
          ${Icon} {
            ~ input {
              padding-right: 5rem;
              padding-left: 0.5rem;
            }
          }
        `}
        ${ErrorIcon} {
          right: 2.85rem;
        }
      `}
`;

export const InputError = styled.div`
  line-height: 1.6;
  color: #dd2121;
  font-family: "Lato-Regular";
  font-size: 14px;
  padding-left: 0.5rem;
`;

export const Increment = styled.div`
  margin-top: 1px;
  width: 25px;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s background ease;
  &:hover {
    transition: 0.15s background ease;
    background: #f4f4f4;
    cursor: pointer;
  }
  border-bottom: 1px solid #bebeca;
`;
export const Decrement = styled(Increment)`
  margin-top: 0;
  margin-bottom: 1px;
  border-bottom: none;
`;
