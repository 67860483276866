import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL } from '../../constants';

const cookies = new Cookies(),
  token = cookies.get('token');

export const uploadImage = (file) => {
  return () => {
    const query = `
        mutation($file: Upload!) {
          uploadFile(file: $file)
        }
      `;

    const operations = JSON.stringify({ query, variables: { file: null } });
    const map = { 0: ['variables.file'] };
    const formData = new FormData();
    formData.append('operations', operations);
    formData.append('map', JSON.stringify(map));
    formData.append('0', file);
    return axios({
      url: API_URL,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    });
  };
};
