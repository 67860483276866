import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUser, getUser } from "../../../store/actions/user";
import getToken from "../../helpers/getToken";
import { CreateToast } from "../../helpers/CreateToast";

import Account from "..";
import Heading from "../../UI/Heading";
import Form from "../../Form";
import TextField from "../../Form/TextField";
import Box from "../../UI/Layout/Box";
import CheckboxGroup from "../../Form/Checkbox/Group";
import Checkbox from "../../Form/Checkbox";
import Button from "../../UI/Button";

const Settings = ({ USER }) => {
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState(USER || null);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  // Set fields if not already set
  useEffect(() => {
    if (USER && !fields) setFields(USER);
  }, [USER]);

  const handleInputChange = (value, name) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
    if (errors?.[name]) {
      setErrors((prevState) => ({ ...prevState, [name]: null }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const token = getToken();
    setErrors(null);
    dispatch(updateUser(fields, token))
      .then((res) => {
        setSubmitting(false);
        const { data, errors } = res.data;
        if (errors && errors[0]?.message) {
          CreateToast({
            msg: "Kontrolli sisestatud välju.",
            type: "warn",
          });
          setErrors(JSON.parse(errors[0]?.message));
          return;
        }
        const { success } = JSON.parse(data.editUser);
        if (success) {
          CreateToast({
            msg: "Seaded edukalt muudetud",
            type: "success",
          });
          dispatch(getUser(token));
        }
      })
      .catch(() => {
        setSubmitting(false);
        CreateToast({
          msg: "Tekkis tundmatu viga, proovige uuesti.",
          type: "warn",
        });
      });
  };

  return (
    <Account>
      <Heading as="h3" mt="-4px" color="#2E2E46">
        Seaded
      </Heading>
      <Box py="0.5rem" my="0.5rem">
        <Form onSubmit={handleSubmit}>
          <Box maxWidth="340px" mr="auto">
            <TextField
              size="large"
              placeholder="Sisesta eesnimi"
              label="Eesnimi"
              name="firstName"
              value={fields?.firstName}
              onChange={handleInputChange}
              error={errors?.firstName}
            />
            <TextField
              size="large"
              placeholder="Sisesta perekonnanimi"
              label="Perekonnanimi"
              name="lastName"
              value={fields?.lastName}
              onChange={handleInputChange}
              error={errors?.lastName}
            />
            <TextField
              size="large"
              placeholder="Sisesta e-mail"
              label="E-mail"
              name="email"
              disabled
              value={fields?.email}
              onChange={handleInputChange}
              error={errors?.email}
            />
            <TextField
              size="large"
              placeholder="Sisesta telefoni number"
              label="Telefon"
              name="phone"
              value={fields?.phone}
              onChange={handleInputChange}
              error={errors?.phone}
            />
            <Box mt="2rem" mb="1rem">
              <Heading as="h5" mb="1rem">
                Muuda parooli
              </Heading>
              <TextField
                type="password"
                size="large"
                placeholder="Sisesta parool"
                label="Parool"
                name="password"
                autoComplete="off"
                value={fields?.password}
                onChange={handleInputChange}
                error={errors?.password}
              />
              <TextField
                type="password"
                size="large"
                placeholder="Sisesta parool"
                label="Parool uuesti"
                name="repeat-password"
                autoComplete="off"
                value={fields?.["repeat-password"]}
                onChange={handleInputChange}
                error={errors?.["repeat-password"]}
              />
            </Box>
            <CheckboxGroup size="full">
              <Checkbox
                fontFamily="Lato-Regular"
                fontSize="14px"
                label="right"
                name="newsletter"
                checked={fields?.newsletter}
                onChange={handleInputChange}
              >
                Soovin liituda meililistiga
              </Checkbox>
            </CheckboxGroup>
            <Box my="2rem" mx="4rem">
              <Button loading={submitting} type="submit" version="success">
                Salvesta
              </Button>
            </Box>
          </Box>
        </Form>
      </Box>
    </Account>
  );
};

export default Settings;
