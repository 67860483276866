import React from "react";
import Select from "react-select";

import {
  Wrapper,
  Label,
  Input,
  ErrorIcon,
  InputError,
  SelectStyles,
} from "./styles";

const SelectField = (props) => {
  return (
    <Wrapper size={props.size || "medium"}>
      {props.label && <Label>{props.label}</Label>}
      <Input invalid={props.error}>
        <Select
          placeholder={props.placeholder}
          name={props.name}
          value={props.value || null}
          options={props.options}
          isMulti={props.isMulti}
          closeMenuOnSelect={props.closeMenuOnSelect}
          onChange={props.onChange}
          styles={SelectStyles}
          noOptionsMessage={() => "Valikud puuduvad"}
          maxMenuHeight={260}
          isClearable={props.isClearable}
          isDisabled={props.disabled}
          classNamePrefix="styled"
        />
        {props.error && <ErrorIcon>{errorIcon}</ErrorIcon>}
      </Input>
      {props.error && <InputError>{props.error.msg}</InputError>}
    </Wrapper>
  );
};

const errorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.375"
    height="19"
    viewBox="0 0 21.375 19"
  >
    <path
      d="M21.134,16.329A1.782,1.782,0,0,1,19.592,19H1.783A1.782,1.782,0,0,1,.24,16.329L9.145.89a1.782,1.782,0,0,1,3.086,0ZM10.687,13.137a1.707,1.707,0,1,0,1.707,1.707A1.707,1.707,0,0,0,10.687,13.137ZM9.067,7l.275,5.047a.445.445,0,0,0,.445.421h1.8a.445.445,0,0,0,.445-.421L12.308,7a.445.445,0,0,0-.445-.47H9.511A.445.445,0,0,0,9.067,7Z"
      fill="#dd2121"
    />
  </svg>
);

export default SelectField;
