import React from "react";

import { Wrapper, Button, Icon, Loader, LoaderRing } from "./styles";

const ButtonWrapper = (props) => {
  return (
    <Wrapper size={props.size || "medium"} position={props.position}>
      <Button
        version={props.version || "primary"} // ONE OF: primary, primary-reverse, primary-hollow, secondary, success, success-hollow, light, light-secondary, light-reverse danger
        type={props.type || "button"}
        onClick={props.onClick}
        value={props.type === "submit" ? props.value : ""}
        disabled={props.loading}
      >
        {props.icon && props.icon.position !== "right" && (
          <Icon position={props.icon.position}>{props.icon.svg}</Icon>
        )}
        {props.children}
        {props.icon && props.icon.position === "right" && (
          <Icon position={props.icon.position}>{props.icon.svg}</Icon>
        )}
        {props.loading && (
          <Loader>
            <LoaderRing />
          </Loader>
        )}
      </Button>
    </Wrapper>
  );
};

export default ButtonWrapper;
