import React from "react";
import Flex from "../UI/Layout/Flex";
import Heading from "../UI/Heading";
import Box from "../UI/Layout/Box";
import Text from "../UI/Text";

const SingleOfferAdditional = () => {
  return (
    <Flex
      display={["flex", null, "inline-flex"]}
      flexDirection={["column", null, "row"]}
      flexWrap="wrap"
      width="100%"
      justifyContent="space-between"
      bg="#FFF"
      p={["1rem", null, "2rem"]}
      pr={[null, null, null, null, "8rem", "10rem"]}
    >
      <Flex>
        <Heading as="h3" color="#3B5588">
          Varustus
        </Heading>
        <Box
          as="ul"
          p={["1rem 0.5rem", null, null, "1.5rem 1rem", "1.5rem"]}
          mb="2rem"
        >
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Roolivõimendi
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Kesklukustus (puldiga)
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              ABS pidurid
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Elektrooniline seisupidur
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Turvapadi
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Turvakardinad
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Kõrvalistuja turvapadja väljalülitamise võimalus
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Juhi väsimuse tuvastamise süsteem
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Immobilisaator
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              5x peatoed (esiistmetel, tagaistmetel)
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Stabiilsuskontroll
            </Text>
          </Box>
        </Box>
      </Flex>
      <Flex>
        <Heading as="h3" color="#3B5588">
          Viled ja kellad
        </Heading>
        <Box
          as="ul"
          p={["1rem 0.5rem", null, null, "1.5rem 1rem", "1.5rem"]}
          mb="2rem"
        >
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Roolivõimendi
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Kesklukustus (puldiga)
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              ABS pidurid
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Elektrooniline seisupidur
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Turvapadi
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Turvakardinad
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Kõrvalistuja turvapadja väljalülitamise võimalus
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Juhi väsimuse tuvastamise süsteem
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Immobilisaator
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              5x peatoed (esiistmetel, tagaistmetel)
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Stabiilsuskontroll
            </Text>
          </Box>
        </Box>
      </Flex>
      <Flex>
        <Heading as="h3" color="#3B5588">
          Lisainfo
        </Heading>
        <Box
          as="ul"
          p={["1rem 0.5rem", null, null, "1.5rem 1rem", "1.5rem"]}
          mb="2rem"
        >
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Toodud riigist: Saksamaa
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Garantii
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Vahetuse võimalus
            </Text>
          </Box>
          <Box as="li" padding="0.4rem 0">
            <Text as="p" fontFamily="Lato-Regular">
              Auto on väga heas korras
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SingleOfferAdditional;
