import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  width: 100%;
  ${(props) =>
    props.size === "medium" &&
    css`
      max-width: 288px;
    `}
  ${(props) =>
    props.size === "small" &&
    css`
      max-width: 170px;
    `}
  ${(props) =>
    props.size === "full" &&
    css`
      min-width: 100%;
    `}
`;
export const Label = styled.label`
  font-size: 1rem;
  font-family: "Lato-Bold";
  line-height: 1.8;
  white-space: nowrap;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: 0.6rem;
  right: 3rem;
  user-select: none;
  pointer-events: none;
  svg {
    width: 15px;
    height: 15px;
  }
`;
export const Input = styled.div`
  position: relative;
  input {
    &:hover {
      transition: 0.15s border ease;
      border: 1px solid #596d87;
    }
    &:focus {
      transition: 0.15s border ease;
      border: 1px solid #1774ed;
    }
    &:disabled {
      background: #f9f9f9;
      cursor: not-allowed;
      &::placeholder {
        color: #d4d4d4;
      }
      &:hover {
        border: 1px solid #bebeca;
      }
    }
  }
  ${(props) =>
    props.invalid &&
    css`
      .styled__control {
        border: 1px solid #dd2121;
      }
      .styled__value-container {
        padding-right: 2rem;
      }
    `}
`;

export const InputError = styled.div`
  line-height: 1.6;
  color: #dd2121;
  font-family: "Lato-Regular";
  font-size: 14px;
  padding-left: 0.5rem;
`;

export const SelectStyles = {
  menuList: (provided) => ({
    ...provided,
    fontFamily: "Lato-Regular",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#FFF" : "#000",
    padding: 10,
    cursor: "pointer",
    fontFamily: "Lato-Regular",
    fontSize: "14px",
  }),
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    borderRadius: "3px",
    border: state.isFocused ? "1px solid #1774ed" : "1px solid #bebeca",
    boxShadow: "none",
    background: state.isDisabled && "#f9f9f9",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    paddingLeft: ".5rem",
    fontFamily: "Lato-Regular",
    transition: "0.15s border ease",
    minHeight: "34.8px",
    "&:hover": {
      transition: "0.15s border ease",
      border: "1px solid #596d87",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    lineHeight: "1.5",
    fontFamily: "Lato-Regular",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "6px 0.5rem",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    margin: "0",
    backgroundColor: "#bebeca",
    width: "0.5px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: "0",
    fontFamily: "Lato-Regular",
    fontSize: "14px",
    color: "#8c8c8c",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily: "Lato-Regular",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#d4d4d4" : "#8c8c8c",
  }),
};
