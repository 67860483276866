import React from 'react';
import Flex from '../UI/Layout/Flex';
import Image from '../UI/Image';
import Box from '../UI/Layout/Box';
import Text from '../UI/Text';
import Badge from '../UI/Badge';
import Heading from '../UI/Heading';
import Link from '../UI/Link';

const LatestItem = (props) => {
  const { id, imageUrl, title, discount, price, attributes } = props;
  return (
    <Flex
      display={['inline-flex', 'flex']}
      flexDirection={['row', 'column']}
      border="1px solid #CFE0EC"
      borderRadius="3px"
      mb="1rem"
      mr={[0, '1rem']}
      width={[1, '47%', null, null, '23%']}
    >
      <Link to={`/kuulutus/${id}`}>
        <Flex
          display={['inline-flex', 'flex']}
          flexDirection={['row', 'column']}
        >
          <Flex
            width={[1]}
            display="inline-flex"
            p={['0.5rem', null, null, null, '1rem']}
          >
            <Image src={imageUrl} alt="" borderRadius="3px" objectFit="cover" />
          </Flex>
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column']}
            width="100%"
          >
            <Flex
              display={[null, 'inline-flex']}
              mb={[0, '0.5rem']}
              p={['0.5rem 0.5rem 0 0', '0.5rem', '0.5rem', '1rem']}
            >
              <Box pb={['0.4rem', 0]} width={[1, 3 / 4]}>
                <Heading as="h5" color="#000000">
                  {title}
                </Heading>
              </Box>
              <Flex
                width={[1, 1 / 4]}
                display={['flex', null]}
                flexDirection={['column', null]}
                alignItems={['flex-start', 'flex-end']}
                p={['.85rem 0', 0]}
                pr={['0.25rem', '0']}
                m={['0.25rem 0', '0']}
              >
                {discount && (
                  <Text
                    as="span"
                    fontFamily="Lato-Bold"
                    color="#A50000"
                    textDecoration="line-through"
                    fontSize={['0.75rem', null, 1]}
                  >
                    {discount}.-
                  </Text>
                )}
                <Heading as="h3" color="#252525" mt="4px" fontSize={[1, 2, 3]}>
                  {price}.-
                </Heading>
              </Flex>
            </Flex>
            <Flex
              m={['0.25rem 0', 0]}
              display="inline-flex"
              flexWrap="wrap"
              p={[0, '0.5rem', null, null, '1rem 0.5rem']}
              borderTop={[null, '1px solid #CFE0EC']}
            >
              {attributes.map((attr, i) => {
                const { name, highlighted } = attr;
                return (
                  <Badge
                    key={i}
                    background={highlighted ? '#898989' : 'none'}
                    color={highlighted && '#FFF'}
                    fontFamily={[
                      highlighted && 'Lato-Bold',
                      null,
                      null,
                      'Lato-Bold',
                    ]}
                    fontSize={['12px', '14px', '14px']}
                    p={[null, null, null, '0.25rem 0.4rem']}
                    pl={[!highlighted && '0', null, null, '0.25rem']}
                    mb={[null, null, null, '0.25rem']}
                  >
                    {name}
                  </Badge>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  );
};

export default LatestItem;
