import React, { useState } from 'react';
import { validateFour } from '../../../../store/actions/offer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CreateToast } from '../../../helpers/CreateToast';

import Form from '../../../Form';
import Box from '../../../UI/Layout/Box';
import Number from '../../../Form/Number';
import Select from '../../../Form/Select';
import Button from '../../../UI/Button';
import Flex from '../../../UI/Layout/Flex';
import CheckboxGroup from '../../../Form/Checkbox/Group';
import Checkbox from '../../../Form/Checkbox';
import TextArea from '../../../Form/TextArea';

const StepFour = ({ setTab, masterFields, setMasterFields, setCompleted }) => {
  const [fields, setFields] = useState(masterFields || null);
  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleInputChange = (value, name) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
    if (errors?.[name])
      setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const handleSelectChange = (value, action) => {
    setFields((prevState) => ({ ...prevState, [action.name]: value }));
    if (errors?.[action.name])
      setErrors((prevState) => ({ ...prevState, [action.name]: null }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: VALIDATE
    history.push('/konto/kuulutused');
    /*     dispatch(validateFour(fields))
      .then((res) => {
        const { errors, data } = res.data;
        setSubmitting(false);
        if (errors && errors[0]?.message) {
          setErrors(JSON.parse(errors[0]?.message));
          CreateToast({
            msg: 'Kontrolli sisestatud välju.',
            type: 'warn',
          });
          return;
        }
        const parsed = JSON.parse(data?.createOfferThree);
        if (parsed?.success) {
          setMasterFields((prevState) => ({ ...prevState, ...fields }));
          setCompleted(2);
          setTab(3);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log('err', err);
      }); */
  };

  const handleBackward = () => {
    setMasterFields((prevState) => ({ ...prevState, ...fields }));
    setTab(2);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Flex
        display={['flex', null, null, null, 'inline-flex']}
        flexDirection={['column', null, null, null, 'row']}
        width="100%"
        maxWidth={[null, null, null, null, '670px', '870px']}
        justifyContent="space-between"
      >
        <Box mr={[null, null, null, null, null, '1rem']}>
          <CheckboxGroup label="Turva- ja ohutusvarustus">
            <Checkbox
              size="small"
              label="right"
              value="roolivoimendi"
              name="roolivoimendi"
              checked={fields?.roolivoimendi}
              error={errors?.roolivoimendi}
              onChange={handleInputChange}
            >
              Roolivõimendi
            </Checkbox>
            {fields?.roolivoimendi && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Checkbox
                  size="small"
                  label="right"
                  value="kiirustundlik"
                  name="kiirustundlik"
                  checked={fields?.kiirustundlik}
                  error={errors?.kiirustundlik}
                  onChange={handleInputChange}
                >
                  Kiirustundlik
                </Checkbox>
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="kesklukustus"
              name="kesklukustus"
              checked={fields?.kesklukustus}
              error={errors?.kesklukustus}
              onChange={handleInputChange}
            >
              Kesklukustus
            </Checkbox>
            {fields?.kesklukustus && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Checkbox
                  size="small"
                  label="right"
                  value="kesklukustusPuldiga"
                  name="kesklukustusPuldiga"
                  checked={fields?.kesklukustusPuldiga}
                  error={errors?.kesklukustusPuldiga}
                  onChange={handleInputChange}
                >
                  Puldiga
                </Checkbox>
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="ABS"
              name="ABS"
              checked={fields?.ABS}
              error={errors?.ABS}
              onChange={handleInputChange}
            >
              ABS pidurid
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="turvapadi"
              name="turvapadi"
              checked={fields?.turvapadi}
              error={errors?.turvapadi}
              onChange={handleInputChange}
            >
              Turvapadi
            </Checkbox>
            {fields?.turvapadi && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Number
                  size="small"
                  step={1}
                  float={0}
                  placeholder="tk"
                  name="turvapadiCount"
                  value={fields?.turvapadiCount}
                  error={errors?.turvapadiCount}
                  onChange={handleInputChange}
                />
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="signalisatsioon"
              name="signalisatsioon"
              checked={fields?.signalisatsioon}
              error={errors?.signalisatsioon}
              onChange={handleInputChange}
            >
              Signalisatsioon
            </Checkbox>
            {fields?.signalisatsioon && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Select
                  placeholder="Kirjuta ja vali"
                  name="signalisatsioonExtra"
                  isMulti
                  value={fields?.signalisatsioonExtra}
                  error={errors?.signalisatsioonExtra}
                  onChange={handleSelectChange}
                  options={signaOptions}
                />
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="immobilisaator"
              name="immobilisaator"
              checked={fields?.immobilisaator}
              error={errors?.immobilisaator}
              onChange={handleInputChange}
            >
              Immobilisaator
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="stabiilsuskontroll"
              name="stabiilsuskontroll"
              checked={fields?.stabiilsuskontroll}
              error={errors?.stabiilsuskontroll}
              onChange={handleInputChange}
            >
              Stabiilsuskontroll
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="pidurdusjoukontroll"
              name="pidurdusjoukontroll"
              checked={fields?.pidurdusjoukontroll}
              error={errors?.pidurdusjoukontroll}
              onChange={handleInputChange}
            >
              Pidurdusjõukontroll
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="veojoukontroll"
              name="veojoukontroll"
              checked={fields?.veojoukontroll}
              error={errors?.veojoukontroll}
              onChange={handleInputChange}
            >
              Veojõukontroll
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="vihmasensor"
              name="vihmasensor"
              checked={fields?.vihmasensor}
              error={errors?.vihmasensor}
              onChange={handleInputChange}
            >
              Vihmasensor
            </Checkbox>
            <TextArea
              name="addSafety"
              value={fields?.addSafety}
              placeholder="Turva- ja ohutusvarustus lisainfo"
              onChange={handleInputChange}
            />
          </CheckboxGroup>
        </Box>
        <Box minWidth={[null, null, null, null, null, '375px']}>
          <CheckboxGroup label="Audio, video, kommunikatsioon">
            <Checkbox
              size="small"
              label="right"
              value="stereo"
              name="stereo"
              checked={fields?.stereo}
              error={errors?.stereo}
              onChange={handleInputChange}
            >
              Stereo
            </Checkbox>
            {fields?.stereo && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Select
                  placeholder="Kirjuta ja vali"
                  name="stereoExtra"
                  isMulti
                  value={fields?.stereoExtra}
                  error={errors?.stereoExtra}
                  onChange={handleSelectChange}
                  options={stereoOptions}
                />
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="helivoimendi"
              name="helivoimendi"
              checked={fields?.helivoimendi}
              error={errors?.helivoimendi}
              onChange={handleInputChange}
            >
              Helivõimendi
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="kolarid"
              name="kolarid"
              checked={fields?.kolarid}
              error={errors?.kolarid}
              onChange={handleInputChange}
            >
              Kõlarid
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="subwoofer"
              name="subwoofer"
              checked={fields?.subwoofer}
              error={errors?.subwoofer}
              onChange={handleInputChange}
            >
              Subwoofer
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="cdbox"
              name="cdbox"
              checked={fields?.cdbox}
              error={errors?.cdbox}
              onChange={handleInputChange}
            >
              CD Box
            </Checkbox>
            <Checkbox
              size="medium"
              label="right"
              value="AppleAndroid"
              name="AppleAndroid"
              checked={fields?.AppleAndroid}
              error={errors?.AppleAndroid}
              onChange={handleInputChange}
            >
              Apple CarPlay/Android Auto
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="elektrilineantenn"
              name="elektrilineantenn"
              checked={fields?.elektrilineantenn}
              error={errors?.elektrilineantenn}
              onChange={handleInputChange}
            >
              Elektriline antenn
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="ekraan"
              name="ekraan"
              checked={fields?.ekraan}
              error={errors?.ekraan}
              onChange={handleInputChange}
            >
              Ekraan
            </Checkbox>
            {fields?.ekraan && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Checkbox
                  size="small"
                  label="right"
                  value="ekraanEes"
                  name="ekraanEes"
                  checked={fields?.ekraanEes}
                  error={errors?.ekraanEes}
                  onChange={handleInputChange}
                >
                  Ees
                </Checkbox>
                <Checkbox
                  size="small"
                  label="right"
                  value="ekraanTaga"
                  name="ekraanTaga"
                  checked={fields?.ekraanTaga}
                  error={errors?.ekraanTaga}
                  onChange={handleInputChange}
                >
                  Taga
                </Checkbox>
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="navigatsiooniseade"
              name="navigatsiooniseade"
              checked={fields?.navigatsiooniseade}
              error={errors?.navigatsiooniseade}
              onChange={handleInputChange}
            >
              Navigatsiooniseade
            </Checkbox>
            {fields?.navigatsiooniseade && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Checkbox
                  size="small"
                  label="right"
                  value="navKaardiga"
                  name="navKaardiga"
                  checked={fields?.navKaardiga}
                  error={errors?.navKaardiga}
                  onChange={handleInputChange}
                >
                  Kaardiga
                </Checkbox>
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="autokompuuter"
              name="autokompuuter"
              checked={fields?.autokompuuter}
              error={errors?.autokompuuter}
              onChange={handleInputChange}
            >
              Autokompuuter
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="autotelefon"
              name="autotelefon"
              checked={fields?.autotelefon}
              error={errors?.autotelefon}
              onChange={handleInputChange}
            >
              Autotelefon
            </Checkbox>
            <Checkbox
              size="medium"
              label="right"
              value="handsfree"
              name="handsfree"
              checked={fields?.handsfree}
              error={errors?.handsfree}
              onChange={handleInputChange}
            >
              Käed vabad süsteem
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="gsm"
              name="gsm"
              checked={fields?.gsm}
              error={errors?.gsm}
              onChange={handleInputChange}
            >
              GSM antenn
            </Checkbox>
            <TextArea
              name="addAudio"
              value={fields?.addAudio}
              placeholder="Audio, video, kommunikatsioon lisainfo"
              onChange={handleInputChange}
            />
          </CheckboxGroup>
        </Box>
      </Flex>
      <Flex
        display={['flex', null, null, null, 'inline-flex']}
        flexDirection={['column', null, null, null, 'row']}
        width="100%"
        maxWidth={[null, null, null, null, '670px', '870px']}
        justifyContent="space-between"
      >
        <Box mr={[null, null, null, null, null, '1rem']}>
          <Box minWidth={[null, null, null, null, null, '375px']}>
            <CheckboxGroup label="Mugavusvarustus">
              <Checkbox
                size="small"
                label="right"
                value="kliimaseade"
                name="kliimaseade"
                checked={fields?.kliimaseade}
                error={errors?.kliimaseade}
                onChange={handleInputChange}
              >
                Kliimaseade
              </Checkbox>
              <Checkbox
                size="medium"
                label="right"
                value="elekpeeglid"
                name="elekpeeglid"
                checked={fields?.elekpeeglid}
                error={errors?.elekpeeglid}
                onChange={handleInputChange}
              >
                Elektrilised välispeeglid
              </Checkbox>
              {fields?.elekpeeglid && (
                <Box ml={['12px', null, null, null, null, '24px']}>
                  <Select
                    placeholder="Kirjuta ja vali"
                    name="elekpeeglidExtra"
                    isMulti
                    value={fields?.elekpeeglidExtra}
                    error={errors?.elekpeeglidExtra}
                    onChange={handleSelectChange}
                    options={elektriOptions}
                  />
                </Box>
              )}
              <Checkbox
                size="medium"
                label="right"
                value="elektostikud"
                name="elektostikud"
                checked={fields?.elektostikud}
                error={errors?.elektostikud}
                onChange={handleInputChange}
              >
                Elektrilised akende tõstukid
              </Checkbox>
              <Checkbox
                size="small"
                label="right"
                value="toonitud"
                name="toonitud"
                checked={fields?.toonitud}
                error={errors?.toonitud}
                onChange={handleInputChange}
              >
                Toonitud klaasid
              </Checkbox>
              <Checkbox
                size="small"
                label="right"
                value="katuseluuk"
                name="katuseluuk"
                checked={fields?.katuseluuk}
                error={errors?.katuseluuk}
                onChange={handleInputChange}
              >
                Katuseluuk
              </Checkbox>
              {fields?.katuseluuk && (
                <Box ml={['12px', null, null, null, null, '24px']}>
                  <Checkbox
                    size="small"
                    label="right"
                    value="luukklaasist"
                    name="luukklaasist"
                    checked={fields?.luukklaasist}
                    error={errors?.luukklaasist}
                    onChange={handleInputChange}
                  >
                    Klaasist
                  </Checkbox>
                  <Checkbox
                    size="small"
                    label="right"
                    value="luukelektriline"
                    name="luukelektriline"
                    checked={fields?.luukelektriline}
                    error={errors?.luukelektriline}
                    onChange={handleInputChange}
                  >
                    Elektriline
                  </Checkbox>
                </Box>
              )}
              <Checkbox
                size="medium"
                label="right"
                value="eelsoojendus"
                name="eelsoojendus"
                checked={fields?.eelsoojendus}
                error={errors?.eelsoojendus}
                onChange={handleInputChange}
              >
                Mootori eelsoojendus
              </Checkbox>
              <Checkbox
                size="small"
                label="right"
                value="kohtvalgustid"
                name="kohtvalgustid"
                checked={fields?.kohtvalgustid}
                error={errors?.kohtvalgustid}
                onChange={handleInputChange}
              >
                Kohtvalgustid
              </Checkbox>
              <Checkbox
                size="small"
                label="right"
                value="parkimisandurid"
                name="parkimisandurid"
                checked={fields?.parkimisandurid}
                error={errors?.parkimisandurid}
                onChange={handleInputChange}
              >
                Parkimisandurid
              </Checkbox>
              {fields?.parkimisandurid && (
                <Box ml={['12px', null, null, null, null, '24px']}>
                  <Checkbox
                    size="small"
                    label="right"
                    value="anduridEes"
                    name="anduridEes"
                    checked={fields?.anduridEes}
                    error={errors?.anduridEes}
                    onChange={handleInputChange}
                  >
                    Ees
                  </Checkbox>
                  <Checkbox
                    size="small"
                    label="right"
                    value="anduridTaga"
                    name="anduridTaga"
                    checked={fields?.anduridTaga}
                    error={errors?.anduridTaga}
                    onChange={handleInputChange}
                  >
                    Taga
                  </Checkbox>
                </Box>
              )}
              <TextArea
                name="addMugavus"
                value={fields?.addMugavus}
                placeholder="Mugavusvarustus lisainfo"
                onChange={handleInputChange}
              />
            </CheckboxGroup>
          </Box>
        </Box>
        <Box minWidth={[null, null, null, null, null, '375px']}>
          <CheckboxGroup label="Rehvid ja veljed">
            <Checkbox
              size="small"
              label="right"
              value="suverehvid"
              name="suverehvid"
              checked={fields?.suverehvid}
              error={errors?.suverehvid}
              onChange={handleInputChange}
            >
              Suverehvid
            </Checkbox>
            {fields?.suverehvid && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Checkbox
                  size="medium"
                  label="right"
                  value="suverehvidWithCar"
                  name="suverehvidWithCar"
                  checked={fields?.suverehvidWithCar}
                  error={errors?.suverehvidWithCar}
                  onChange={handleInputChange}
                >
                  Autoga antakse kaasa
                </Checkbox>
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="talverehvid"
              name="talverehvid"
              checked={fields?.talverehvid}
              error={errors?.talverehvid}
              onChange={handleInputChange}
            >
              Talverehvid
            </Checkbox>
            {fields?.talverehvid && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Checkbox
                  size="small"
                  label="right"
                  value="lamellrehvid"
                  name="lamellrehvid"
                  checked={fields?.lamellrehvid}
                  error={errors?.lamellrehvid}
                  onChange={handleInputChange}
                >
                  Lamellrehvid
                </Checkbox>
                {fields?.lamellrehvid && (
                  <Box ml={['12px', null, null, null, null, '24px']}>
                    <Checkbox
                      size="medium"
                      label="right"
                      value="lamellrehvidWithCar"
                      name="lamellrehvidWithCar"
                      checked={fields?.lamellrehvidWithCar}
                      error={errors?.lamellrehvidWithCar}
                      onChange={handleInputChange}
                    >
                      Autoga antakse kaasa
                    </Checkbox>
                  </Box>
                )}
                <Checkbox
                  size="small"
                  label="right"
                  value="naastrehvid"
                  name="naastrehvid"
                  checked={fields?.naastrehvid}
                  error={errors?.naastrehvid}
                  onChange={handleInputChange}
                >
                  Naastrehvid
                </Checkbox>
                {fields?.naastrehvid && (
                  <Box ml={['12px', null, null, null, null, '24px']}>
                    <Checkbox
                      size="medium"
                      label="right"
                      value="naastrehvidWithCar"
                      name="naastrehvidWithCar"
                      checked={fields?.naastrehvidWithCar}
                      error={errors?.naastrehvidWithCar}
                      onChange={handleInputChange}
                    >
                      Autoga antakse kaasa
                    </Checkbox>
                  </Box>
                )}
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="valuveljed"
              name="valuveljed"
              checked={fields?.valuveljed}
              error={errors?.valuveljed}
              onChange={handleInputChange}
            >
              Valuveljed
            </Checkbox>
            {fields?.valuveljed && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Checkbox
                  size="medium"
                  label="right"
                  value="valuveljedWithCar"
                  name="valuveljedWithCar"
                  checked={fields?.valuveljedWithCar}
                  error={errors?.valuveljedWithCar}
                  onChange={handleInputChange}
                >
                  Autoga antakse kaasa
                </Checkbox>
              </Box>
            )}
            <Checkbox
              size="small"
              label="right"
              value="ilukilbid"
              name="ilukilbid"
              checked={fields?.ilukilbid}
              error={errors?.ilukilbid}
              onChange={handleInputChange}
            >
              Ilukilbid
            </Checkbox>
            {fields?.ilukilbid && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Checkbox
                  size="medium"
                  label="right"
                  value="ilukilbidWithCar"
                  name="ilukilbidWithCar"
                  checked={fields?.ilukilbidWithCar}
                  error={errors?.ilukilbidWithCar}
                  onChange={handleInputChange}
                >
                  Autoga antakse kaasa
                </Checkbox>
              </Box>
            )}
            <TextArea
              name="addRehvid"
              value={fields?.addRehvid}
              placeholder="Rehvid ja veljed lisainfo"
              onChange={handleInputChange}
            />
          </CheckboxGroup>
        </Box>
      </Flex>
      <Flex
        display={['flex', null, null, null, 'inline-flex']}
        flexDirection={['column', null, null, null, 'row']}
        width="100%"
        maxWidth={[null, null, null, null, '670px', '870px']}
        justifyContent="space-between"
      >
        <Box mr={[null, null, null, null, null, '1rem']}>
          <Box minWidth={[null, null, null, null, null, '375px']}>
            <CheckboxGroup label="Rool">
              <Checkbox
                size="medium"
                label="right"
                value="roolisammas"
                name="roolisammas"
                checked={fields?.roolisammas}
                error={errors?.roolisammas}
                onChange={handleInputChange}
              >
                Reguleeritav roolisammas
              </Checkbox>
              {fields?.roolisammas && (
                <Box ml={['12px', null, null, null, null, '24px']}>
                  <Select
                    placeholder="Kirjuta ja vali"
                    name="roolisammasExtra"
                    isMulti
                    value={fields?.roolisammasExtra}
                    error={errors?.roolisammasExtra}
                    onChange={handleSelectChange}
                    options={roolisammasOptions}
                  />
                </Box>
              )}
              <Checkbox
                size="medium"
                label="right"
                value="roolmulti"
                name="roolmulti"
                checked={fields?.roolmulti}
                error={errors?.roolmulti}
                onChange={handleInputChange}
              >
                Multifunktsionaalne rool
              </Checkbox>
              <TextArea
                name="addRool"
                value={fields?.addRool}
                placeholder="Rool lisainfo"
                onChange={handleInputChange}
              />
            </CheckboxGroup>
          </Box>
        </Box>
        <Box minWidth={[null, null, null, null, null, '375px']}>
          <CheckboxGroup label="Sportvarustus">
            <TextArea
              name="addSport"
              value={fields?.addSport}
              placeholder="Spordivarustus lisainfo"
              onChange={handleInputChange}
            />
          </CheckboxGroup>
        </Box>
      </Flex>
      <Flex
        display={['flex', null, null, null, 'inline-flex']}
        flexDirection={['column', null, null, null, 'row']}
        width="100%"
        maxWidth={[null, null, null, null, '670px', '870px']}
        justifyContent="space-between"
      >
        <Box mr={[null, null, null, null, null, '1rem']}>
          <Box minWidth={[null, null, null, null, null, '375px']}>
            <CheckboxGroup label="Istmed">
              <Checkbox
                size="small"
                label="right"
                value="polster"
                name="polster"
                checked={fields?.polster}
                error={errors?.polster}
                onChange={handleInputChange}
              >
                Polster
              </Checkbox>
              {fields?.polster && (
                <Box ml={['12px', null, null, null, null, '24px']}>
                  <Select
                    placeholder="Kirjuta ja vali"
                    name="polsterExtra"
                    value={fields?.polsterExtra}
                    error={errors?.polsterExtra}
                    onChange={handleSelectChange}
                    options={polsterOptions}
                  />
                </Box>
              )}
              <Checkbox
                size="medium"
                label="right"
                value="istmedelektrik"
                name="istmedelektrik"
                checked={fields?.istmedelektrik}
                error={errors?.istmedelektrik}
                onChange={handleInputChange}
              >
                Elektriliselt reguleeritavad istmed
              </Checkbox>
              {fields?.istmedelektrik && (
                <Box ml={['12px', null, null, null, null, '24px']}>
                  <Number
                    size="small"
                    step={1}
                    float={0}
                    placeholder="tk"
                    name="elIstmedCount"
                    value={fields?.elIstmedCount}
                    error={errors?.elIstmedCount}
                    onChange={handleInputChange}
                  />
                  <Checkbox
                    size="small"
                    label="right"
                    value="elIstmedMaludega"
                    name="elIstmedMaludega"
                    checked={fields?.elIstmedMaludega}
                    error={errors?.elIstmedMaludega}
                    onChange={handleInputChange}
                  >
                    Mäludega
                  </Checkbox>
                </Box>
              )}
              <Checkbox
                size="small"
                label="right"
                value="istmesoojendused"
                name="istmesoojendused"
                checked={fields?.istmesoojendused}
                error={errors?.istmesoojendused}
                onChange={handleInputChange}
              >
                Istmesoojendused
              </Checkbox>
              {fields?.istmesoojendused && (
                <Box ml={['12px', null, null, null, null, '24px']}>
                  <Number
                    size="small"
                    step={1}
                    float={0}
                    placeholder="tk"
                    name="istmesoojendusCount"
                    value={fields?.istmesoojendusCount}
                    error={errors?.istmesoojendusCount}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              <TextArea
                name="addIstmed"
                value={fields?.addIstmed}
                placeholder="Istmed lisainfo"
                onChange={handleInputChange}
              />
            </CheckboxGroup>
          </Box>
        </Box>
        <Box minWidth={[null, null, null, null, null, '375px']}>
          <CheckboxGroup label="Muu varustus">
            <Checkbox
              size="small"
              label="right"
              value="katusereelingud"
              name="katusereelingud"
              checked={fields?.katusereelingud}
              error={errors?.katusereelingud}
              onChange={handleInputChange}
            >
              Katusereelingud
            </Checkbox>
            <Checkbox
              size="medium"
              label="right"
              value="valistemp"
              name="valistemp"
              checked={fields?.valistemp}
              error={errors?.valistemp}
              onChange={handleInputChange}
            >
              Välistemperatuuri näidik
            </Checkbox>
            <Checkbox
              size="medium"
              label="right"
              value="esiklaaselektrik"
              name="esiklaaselektrik"
              checked={fields?.esiklaaselektrik}
              error={errors?.esiklaaselektrik}
              onChange={handleInputChange}
            >
              Elektrilise soojendusega esiklaas
            </Checkbox>
            <Checkbox
              size="medium"
              label="right"
              value="tagaklaassoojendus"
              name="tagaklaassoojendus"
              checked={fields?.tagaklaassoojendus}
              error={errors?.tagaklaassoojendus}
              onChange={handleInputChange}
            >
              Tagaklaasi soojendus
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="pagasikate"
              name="pagasikate"
              checked={fields?.pagasikate}
              error={errors?.pagasikate}
              onChange={handleInputChange}
            >
              Pagasikate
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="tagaklaasipuhasti"
              name="tagaklaasipuhasti"
              checked={fields?.tagaklaasipuhasti}
              error={errors?.tagaklaasipuhasti}
              onChange={handleInputChange}
            >
              Tagaklaasi puhasti
            </Checkbox>
            <Checkbox
              size="small"
              label="right"
              value="veokonks"
              name="veokonks"
              checked={fields?.veokonks}
              error={errors?.veokonks}
              onChange={handleInputChange}
            >
              Veokonks
            </Checkbox>
            {fields?.veokonks && (
              <Box ml={['12px', null, null, null, null, '24px']}>
                <Checkbox
                  size="small"
                  label="right"
                  value="veokonksElektrik"
                  name="veokonksElektrik"
                  checked={fields?.veokonksElektrik}
                  error={errors?.veokonksElektrik}
                  onChange={handleInputChange}
                >
                  Elektriline
                </Checkbox>
                <Checkbox
                  size="small"
                  label="right"
                  value="veokonksTeisaldatav"
                  name="veokonksTeisaldatav"
                  checked={fields?.veokonksTeisaldatav}
                  error={errors?.veokonksTeisaldatav}
                  onChange={handleInputChange}
                >
                  Teisaldatav
                </Checkbox>
              </Box>
            )}
            <TextArea
              name="addVarustus"
              value={fields?.addVarustus}
              placeholder="Muu varustus lisainfo"
              onChange={handleInputChange}
            />
          </CheckboxGroup>
        </Box>
      </Flex>
      <Flex
        my="3rem"
        display="inline-flex"
        flexDirection={['column', 'row']}
        width="100%"
      >
        <Box mr={[0, '1.5rem']} mb={['1.5rem', 0]}>
          <Button
            type="button"
            version="light-reverse"
            icon={{
              svg: backward,
              position: 'right',
            }}
            onClick={handleBackward}
          >
            Tagasi
          </Button>
        </Box>
        <Button
          type="submit"
          version="primary"
          icon={{
            svg: forward,
            position: 'right',
          }}
          loading={submitting}
        >
          Avalda
        </Button>
      </Flex>
    </Form>
  );
};

const signaOptions = [
  {
    label: 'Kaldeanduriga',
    value: 'Kaldeanduriga',
  },
  {
    label: 'Mahuanduriga',
    value: 'Mahuanduriga',
  },
  {
    label: 'Kahepoolse sidega',
    value: 'Kahepoolse sidega',
  },
];

const stereoOptions = [
  {
    label: 'CD',
    value: 'CD',
  },
  {
    label: 'MP3',
    value: 'MP3',
  },
  {
    label: 'USB pesaga',
    value: 'USB pesaga',
  },
  {
    label: 'Mälukaardi pesaga',
    value: 'Mälukaardi pesaga',
  },
  {
    label: 'Originaal',
    value: 'Originaal',
  },
  {
    label: 'Puldiga',
    value: 'Puldiga',
  },
];

const elektriOptions = [
  {
    label: 'Soojendusega',
    value: 'Soojendusega',
  },
  {
    label: 'Kokkuklapitavad',
    value: 'Kokkuklapitavad',
  },
  {
    label: 'Mäluga',
    value: 'Mäluga',
  },
];

const roolisammasOptions = [
  {
    label: 'Kõrgus ja sügavus',
    value: 'Kõrgus ja sügavus',
  },
  {
    label: 'Elektriliselt',
    value: 'Elektriliselt',
  },
  {
    label: 'Mäluga',
    value: 'Mäluga',
  },
];

const polsterOptions = [
  {
    label: 'Poolnahk',
    value: 'Poolnahk',
  },
  {
    label: 'Tekstiil',
    value: 'Tekstiil',
  },
  {
    label: 'Veluur',
    value: 'Veluur',
  },
  {
    label: 'Nahk',
    value: 'Nahk',
  },
];

const backward = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(1 1)">
      <path
        d="M3,14A11,11,0,1,0,14,3,11,11,0,0,0,3,14Z"
        transform="translate(-3 -3)"
        fill="none"
        stroke="#a1a1a1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M22.4,20.8,18,16.4,22.4,12"
        transform="translate(-11.4 -5.4)"
        fill="none"
        stroke="#a1a1a1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12,18h8.8"
        transform="translate(-5.4 -7)"
        fill="none"
        stroke="#a1a1a1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const forward = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-2 -2)">
      <path
        d="M25,14A11,11,0,1,1,14,3,11,11,0,0,1,25,14Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M18,20.8l4.4-4.4L18,12"
        transform="translate(-4 -2.4)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12,18h8.8"
        transform="translate(-2.4 -4)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default StepFour;
