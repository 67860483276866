import styled from "styled-components";
import {
  space,
  color,
  layout,
  background,
  border,
  position,
  shadow,
  compose,
} from "styled-system";

export const Wrapper = styled.div`
  ${compose(space, color, layout, background, border, position, shadow)}
`;
