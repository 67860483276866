import { combineReducers } from 'redux';

import user from './reducers/user';
import offers from './reducers/offers';

const rootReducer = combineReducers({
  user,
  offers,
});

export default rootReducer;
