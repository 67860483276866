import styled from "styled-components";

export const HideMobile = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
`;
export const HideDesktop = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }
`;
