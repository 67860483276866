import React from "react";
import Flex from "../Layout/Flex";

const TableRow = ({ borderBottom, children }) => {
  return (
    <Flex
      py="1rem"
      display={["flex", null, null, "inline-flex"]}
      width="100%"
      flexDirection={["column", null, null, "row"]}
      borderBottom={borderBottom}
    >
      {children}
    </Flex>
  );
};

export default TableRow;
