import React, { useState } from "react";
import Flex from "../UI/Layout/Flex";
import Box from "../UI/Layout/Box";
import Heading from "../UI/Heading";
import TextField from "../Form/TextField";
import { Button } from "../UI/Button/styles";

const MailingForm = () => {
  const [fields, setFields] = useState(null);

  return (
    <Flex
      backgroundImage={`url(${require("../../assets/images/mailing_car.png")})`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      height={["200px", "250px", "300px", "350px", "400px"]}
      padding="1.5rem 0"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box maxWidth={["310px", "420px", "550px", "720px"]} margin="0 auto">
        <Heading as="h3" textAlign="center" mb="1rem" color="#FFF">
          Saa esimesena teada lisatud pakkumistest
        </Heading>
        <Flex
          display={["flex", "inline-flex"]}
          width="100%"
          flexDirection={["column", "row"]}
          justifyContent="center"
          alignItems="center"
        >
          <Box mb={["0.5rem", 0]} mr={[0, "0.5rem"]} width="100%">
            <TextField
              size="full"
              placeholder="Sisesta e-mail"
              name="email"
              value={fields?.email}
              onChange={(value, name) => setFields({ [name]: value })}
            />
          </Box>
          <Box maxWidth="150px">
            <Button version="light-secondary">Liitu</Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default MailingForm;
