import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { validateOne } from '../../../../store/actions/offer';

import Form from '../../../Form';
import Heading from '../../../UI/Heading';
import Box from '../../../UI/Layout/Box';
import Flex from '../../../UI/Layout/Flex';
import TextField from '../../../Form/TextField';
import Number from '../../../Form/Number';
import Select from '../../../Form/Select';
import locationOptions from '../../../../data/locations.json';
import Button from '../../../UI/Button';
import MultiImages from '../../../Form/MultiImages';
import { Label } from '../../../Form/TextField/styles';
import { CreateToast } from '../../../helpers/CreateToast';
import { uploadImage } from '../../../../store/actions/image';
const { locations } = locationOptions;

const StepOne = ({ setTab, masterFields, setMasterFields, setCompleted }) => {
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState(masterFields || null);
  const [errors, setErrors] = useState(null);

  const dispatch = useDispatch();

  const handleInputChange = (value, name) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
    if (errors?.[name])
      setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const handleImageChange = (value, idx) => {
    value.forEach((file, i) => {
      // Check if file type is allowed
      if (file) {
        if (!file.type.toLowerCase().match('image.*')) {
          alert(
            `${file.name} ei ole õiges formaadis, lubatud ainult pildiformaadid.`
          );
          return;
        } else {
          // Check if file size is allowed
          if (file.size > 2000000) {
            alert(`${file.name} on liiga suur, max 2MB.`);
            return;
          }
          // Making sure that the reader is created on every instance
          // to prevent the reader being busy reading blobs
          dispatch(uploadImage(file))
            .then((res) => {
              const { data } = res.data;
              const parsed = JSON.parse(data?.uploadFile);
              // TODO: ADD IMAGE UPLOAD ERROR
              if (!parsed.success || !parsed.image) return;
              const url = parsed.image;
              if (fields && fields?.images) {
                setFields((prevState) => ({
                  ...prevState,
                  images: [...prevState?.images, url],
                }));
              } else if (fields && !fields?.images) {
                setFields((prevState) => ({ ...prevState, images: [url] }));
              } else if (!fields) {
                setFields({ images: [url] });
              }
            })
            .catch((err) => console.log('err', err));
        }
      }
      if (errors?.images?.[i])
        setErrors((prevState) => ({ ...prevState, [i]: null }));
    });
  };

  const removeImageHandler = (i) => {
    const imagesCopy = fields?.images;
    if (Object.keys(imagesCopy)?.length > 0) {
      Object.keys(imagesCopy).forEach((key) => {
        if (key === i) delete imagesCopy[key];
      });
    }
    setFields((prevState) => ({ ...prevState, images: imagesCopy }));
  };

  const handleSelectChange = (value, action) => {
    setFields((prevState) => ({ ...prevState, [action.name]: value }));
    if (errors?.[action.name])
      setErrors((prevState) => ({ ...prevState, [action.name]: null }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: VALIDATE
    dispatch(validateOne(fields))
      .then((res) => {
        const { errors, data } = res.data;
        setSubmitting(false);
        if (errors && errors[0]?.message) {
          setErrors(JSON.parse(errors[0]?.message));
          CreateToast({
            msg: 'Kontrolli sisestatud välju.',
            type: 'warn',
          });
          return;
        }
        const parsed = JSON.parse(data?.createOfferOne);
        if (parsed?.success) {
          setMasterFields((prevState) => ({
            ...prevState,
            ...fields,
            _id: parsed._id,
          }));
          setCompleted(0);
          setTab(1);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log('err', err);
      });
  };

  return (
    <>
      <Box mb="1.5rem">
        <Heading as="h6" fontFamily="Lato-Regular">
          Müügikuulutusel kuvatav info
        </Heading>
      </Box>
      <Form onSubmit={handleSubmit}>
        <TextField
          size="large"
          placeholder="Sisesta müüja nimi"
          label="Müüja nimi"
          name="name"
          value={fields?.name}
          error={errors?.name}
          onChange={handleInputChange}
        />
        <TextField
          size="large"
          placeholder="+372 000 000"
          label="Telefon"
          name="phone"
          value={fields?.phone}
          error={errors?.phone}
          onChange={handleInputChange}
        />
        <TextField
          size="large"
          placeholder="Sisesta e-maili aadress"
          label="E-mail"
          name="email"
          value={fields?.email}
          error={errors?.email}
          onChange={handleInputChange}
        />
        <Number
          icon={{
            svg: eur,
            position: 'left',
          }}
          size="small"
          step={50}
          float={0}
          placeholder="Hind"
          label="Sõiduki hind"
          name="price"
          value={fields?.price}
          error={errors?.price}
          onChange={handleInputChange}
        />
        <Select
          label="Sõiduki asukoht"
          placeholder="Kirjuta ja vali"
          name="location"
          value={fields?.location}
          error={errors?.location}
          onChange={handleSelectChange}
          options={locations}
        />
        <Flex display="flex" flexDirection="column">
          <Label>Sõiduki pildid</Label>
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            flexWrap="wrap"
          >
            <MultiImages
              label="Sõiduki pildid"
              name="image"
              maxImages={1}
              error={errors?.images}
              fields={fields}
              removeImage={removeImageHandler}
              onChange={handleImageChange}
            />
          </Flex>
        </Flex>
        <Box my="3rem">
          <Button
            type="submit"
            version="primary"
            loading={submitting}
            icon={{
              svg: forward,
              position: 'right',
            }}
          >
            Edasi
          </Button>
        </Box>
      </Form>
    </>
  );
};

const eur = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.728"
    height="10.234"
    viewBox="0 0 7.728 10.234"
  >
    <path
      d="M.161-6.293H1.19a5.936,5.936,0,0,1,.473-1.592A4.3,4.3,0,0,1,2.489-9.1a3.421,3.421,0,0,1,1.134-.766,3.605,3.605,0,0,1,1.4-.266,3.9,3.9,0,0,1,.868.091,3.326,3.326,0,0,1,.739.259,3.033,3.033,0,0,1,.626.413,3.99,3.99,0,0,1,.532.553l-.434.476a.6.6,0,0,1-.109.109.253.253,0,0,1-.157.045.362.362,0,0,1-.249-.133,2.907,2.907,0,0,0-.346-.294A2.431,2.431,0,0,0,5.936-8.9a2.366,2.366,0,0,0-.861-.133,2.26,2.26,0,0,0-1.7.7,3.762,3.762,0,0,0-.9,2.044H6.307v.385a.326.326,0,0,1-.094.228.35.35,0,0,1-.27.1H2.394q-.007.14-.01.28t0,.287V-4.8q0,.105.007.21H5.656v.392a.308.308,0,0,1-.1.22.355.355,0,0,1-.263.1H2.45a3.888,3.888,0,0,0,.864,2.163,2.206,2.206,0,0,0,1.691.721,2.7,2.7,0,0,0,.665-.074,2.235,2.235,0,0,0,.5-.186,1.977,1.977,0,0,0,.361-.242q.15-.13.262-.242t.2-.186a.269.269,0,0,1,.171-.073.234.234,0,0,1,.105.021.407.407,0,0,1,.1.077l.525.49A3.6,3.6,0,0,1,6.664-.294a3.53,3.53,0,0,1-1.722.4A3.708,3.708,0,0,1,3.479-.172,3.282,3.282,0,0,1,2.349-.963a4.066,4.066,0,0,1-.78-1.253,6.444,6.444,0,0,1-.413-1.656H.161v-.714H1.1q-.007-.105-.007-.21t0-.217q0-.14,0-.284t.01-.284H.161Z"
      transform="translate(-0.161 10.129)"
      fill="#8c8c8c"
    />
  </svg>
);

const forward = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-2 -2)">
      <path
        d="M25,14A11,11,0,1,1,14,3,11,11,0,0,1,25,14Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M18,20.8l4.4-4.4L18,12"
        transform="translate(-4 -2.4)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12,18h8.8"
        transform="translate(-2.4 -4)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default StepOne;
