import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { validateTwo } from '../../../../store/actions/offer';
import { CreateToast } from '../../../helpers/CreateToast';

import Form from '../../../Form';
import Box from '../../../UI/Layout/Box';
import TextField from '../../../Form/TextField';
import Select from '../../../Form/Select';
import Button from '../../../UI/Button';
import Flex from '../../../UI/Layout/Flex';
import frameTypeOptions from '../../../../data/frameType.json';
import carBrandOptions from '../../../../data/carBrands.json';
import monthOptions from '../../../../data/months.json';
import yearOptions from '../../../../data/years.json';
import colorOptions from '../../../../data/colors.json';
import axleOptions from '../../../../data/axles.json';
import Number from '../../../Form/Number';
const { frameTypes } = frameTypeOptions;
const { carBrands } = carBrandOptions;
const { years } = yearOptions;
const { months } = monthOptions;
const { colors } = colorOptions;
const { axles } = axleOptions;

const StepTwo = ({ setTab, masterFields, setMasterFields, setCompleted }) => {
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState(masterFields || null);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  const handleInputChange = (value, name) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
    if (errors?.[name])
      setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const handleSelectChange = (value, action) => {
    setFields((prevState) => ({ ...prevState, [action.name]: value }));
    if (errors?.[action.name])
      setErrors((prevState) => ({ ...prevState, [action.name]: null }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: VALIDATE
    dispatch(validateTwo(fields))
      .then((res) => {
        const { errors, data } = res.data;
        setSubmitting(false);
        if (errors && errors[0]?.message) {
          setErrors(JSON.parse(errors[0]?.message));
          CreateToast({
            msg: 'Kontrolli sisestatud välju.',
            type: 'warn',
          });
          return;
        }
        const parsed = JSON.parse(data?.createOfferTwo);
        if (parsed?.success) {
          setMasterFields((prevState) => ({ ...prevState, ...fields }));
          setCompleted(1);
          setTab(2);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log('err', err);
      });
  };

  const handleBackward = () => {
    setMasterFields((prevState) => ({ ...prevState, ...fields }));
    setTab(0);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        size="small"
        placeholder="000AAA"
        label="Sõiduki reg number"
        name="regCode"
        value={fields?.regCode}
        error={errors?.regCode}
        onChange={handleInputChange}
      />
      <TextField
        size="large"
        placeholder="ABCD123456789"
        label="VIN kood"
        name="vin"
        value={fields?.vin}
        error={errors?.vin}
        onChange={handleInputChange}
      />
      <Select
        label="Keretüüp"
        placeholder="Kirjuta ja vali"
        name="frameType"
        value={fields?.frameType}
        error={errors?.frameType}
        onChange={handleSelectChange}
        options={frameTypes}
      />
      <Select
        label="Mark"
        placeholder="Kirjuta ja vali"
        name="carBrand"
        value={fields?.carBrand}
        error={errors?.carBrand}
        onChange={handleSelectChange}
        options={carBrands}
      />
      <Select
        label="Mudel"
        placeholder="Kirjuta ja vali"
        name="model"
        value={fields?.model}
        error={errors?.model}
        onChange={handleSelectChange}
        options={frameTypes}
      />
      <TextField
        size="large"
        placeholder="Sisesta"
        label="Mudeli täpsustus"
        name="modelExtra"
        value={fields?.modelExtra}
        error={errors?.modelExtra}
        onChange={handleInputChange}
      />
      <Flex
        display="inline-flex"
        alignItems="flex-end"
        width="100%"
        maxWidth="340px"
      >
        <Flex mr="12px" width="50%">
          <Select
            label="Esmane registreerimine"
            placeholder="Kuu"
            name="regMonth"
            value={fields?.regMonth}
            error={errors?.regMonth}
            onChange={handleSelectChange}
            options={months}
          />
        </Flex>
        <Flex width="100%">
          <Select
            placeholder="Aasta"
            name="regYear"
            value={fields?.regYear}
            error={errors?.regYear}
            onChange={handleSelectChange}
            options={years}
          />
        </Flex>
      </Flex>
      <Select
        label="Värv"
        placeholder="Kirjuta ja vali"
        name="color"
        value={fields?.color}
        error={errors?.color}
        onChange={handleSelectChange}
        options={colors}
      />
      <Number
        size="small"
        step={10000}
        float={0}
        placeholder="Km"
        label="Läbisõidumõõdiku näit"
        name="mileage"
        value={fields?.mileage}
        error={errors?.mileage}
        onChange={handleInputChange}
      />
      <Select
        label="Vedav sild"
        placeholder="Kirjuta ja vali"
        name="axle"
        value={fields?.axle}
        error={errors?.axle}
        onChange={handleSelectChange}
        options={axles}
      />
      <Flex
        my="3rem"
        display="inline-flex"
        flexDirection={['column', 'row']}
        width="100%"
      >
        <Box mr={[0, '1.5rem']} mb={['1.5rem', 0]}>
          <Button
            type="button"
            version="light-reverse"
            loading={submitting}
            icon={{
              svg: backward,
              position: 'left',
            }}
            onClick={handleBackward}
          >
            Tagasi
          </Button>
        </Box>
        <Button
          type="submit"
          version="primary"
          icon={{
            svg: forward,
            position: 'right',
          }}
        >
          Edasi
        </Button>
      </Flex>
    </Form>
  );
};

const backward = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(1 1)">
      <path
        d="M3,14A11,11,0,1,0,14,3,11,11,0,0,0,3,14Z"
        transform="translate(-3 -3)"
        fill="none"
        stroke="#a1a1a1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M22.4,20.8,18,16.4,22.4,12"
        transform="translate(-11.4 -5.4)"
        fill="none"
        stroke="#a1a1a1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12,18h8.8"
        transform="translate(-5.4 -7)"
        fill="none"
        stroke="#a1a1a1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const forward = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-2 -2)">
      <path
        d="M25,14A11,11,0,1,1,14,3,11,11,0,0,1,25,14Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M18,20.8l4.4-4.4L18,12"
        transform="translate(-4 -2.4)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12,18h8.8"
        transform="translate(-2.4 -4)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default StepTwo;
