import React from "react";
import { Wrapper, Button, Icon } from "../Button/styles";
import { withRouter, Link } from "react-router-dom";

const ButtonWrapper = (props) => {
  return (
    <Link
      to={{
        pathname: props.to,
        state: {
          from: props.location.pathname,
        },
      }}
      style={{
        textDecoration: "none",
      }}
    >
      <Wrapper size={props.size || "medium"} position={props.position}>
        <Button
          version={props.version || "primary"} // ONE OF: primary, primary-reverse, primary-hollow, secondary, success, success-hollow, light
          type={props.type || "button"}
          onClick={props.onClick}
          value={props.type === "submit" && props.value}
        >
          {props.icon && props.icon.position !== "right" && (
            <Icon position={props.icon.position}>{props.icon.svg}</Icon>
          )}
          {props.children}
          {props.icon && props.icon.position === "right" && (
            <Icon position={props.icon.position}>{props.icon.svg}</Icon>
          )}
        </Button>
      </Wrapper>
    </Link>
  );
};

export default withRouter(ButtonWrapper);
