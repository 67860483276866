import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SingleOffer from "../components/Offers/SingleOffer";
import Container from "../components/UI/Layout/Container";

const OfferPage = () => {
  const { id } = useParams();
  const [offer /* setOffer */] = useState(null),
    history = useHistory(),
    location = useLocation();
  useEffect(() => {
    if (!id) {
      if (location.from) {
        history.push(location.from);
      } else {
        history.push("/kuulutused");
      }
      return;
    }
    if (!id && !offer) {
      // DO FETCH FOR DATA
    }
  }, [id, offer, history, location.from]);
  return (
    <>
      <Header />
      <Container>
        <SingleOffer offer={offer} />
      </Container>
      <Footer />
    </>
  );
};

export default OfferPage;
