import styled from "styled-components";
import { space, layout } from "styled-system";

export const Wrapper = styled.div`
  ${space}
  ${layout}
  svg {
    width: inherit;
    height: inherit;
  }
`;
