import React from 'react';
import Box from '../UI/Layout/Box';
import Flex from '../UI/Layout/Flex';
import Heading from '../UI/Heading';
import Image from '../UI/Image';
import Badge from '../UI/Badge';
import Text from '../UI/Text';
import Link from '../UI/Link';

const Item = (props) => {
  const { id, imageUrl, title, discount, price, attributes } = props;
  return (
    <Link to={`/kuulutus/${id}`}>
      <Flex
        as="article"
        border="1px solid #CFE0EC"
        borderRadius="3px"
        padding={['0.5rem', null, null, null, '1rem']}
        mb="1rem"
        flexDirection={['column', 'row']}
        display={['flex', 'inline-flex']}
        background="#FFF"
      >
        <Flex width={[1, null, null, 1, 1 / 3]} display="inline-flex">
          <Image src={imageUrl} alt="" objectFit="cover" borderRadius="3px" />
        </Flex>
        <Flex
          width={[1, null, null, 1, 2 / 3]}
          padding={[
            '0.5rem 0',
            '0.5rem 1rem',
            '1rem',
            '1rem 0.5rem 0.25rem 1.5rem',
            '0.5rem 0.5rem 0.25rem 2.5rem',
            '0.5rem 0.5rem 0.25rem 3rem',
          ]}
        >
          <Flex
            mb="0.25rem"
            display={['inline-flex', 'flex']}
            flexDirection={['row', 'column']}
            width="100%"
          >
            <Box pr="0.5rem" width={[3 / 4, 1]}>
              <Heading as="h2" color="#000">
                {title}
              </Heading>
            </Box>
            <Flex
              width={[1 / 4, 1]}
              display={['flex', null]}
              flexDirection={['column', null]}
              alignItems={['flex-end', 'flex-start']}
              p={[0, '.85rem 0']}
              pr={['0.25rem', null]}
            >
              {discount && (
                <Text
                  as="span"
                  fontFamily="Lato-Bold"
                  color="#A50000"
                  textDecoration="line-through"
                  fontSize={[1, null, 2]}
                >
                  {discount}.-
                </Text>
              )}
              <Heading as="h3" color="#3B5588" mt="4px" fontSize={[2, 3, 4]}>
                {price}.-
              </Heading>
            </Flex>
          </Flex>
          <Flex display="inline-flex" flexWrap="wrap" margin="0.5rem 0">
            {attributes.map((attr, i) => {
              const { name, highlighted } = attr;
              return (
                <Box key={i} pr={0.5} pb={0.5}>
                  <Badge
                    mr="8px"
                    mb="8px"
                    color={highlighted && '#FFF'}
                    bold={highlighted}
                    uppercase={highlighted}
                    background={highlighted && '#898989'}
                  >
                    {name}
                  </Badge>
                </Box>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};

export default Item;
