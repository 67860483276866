import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header";
import Box from "../../components/UI/Layout/Box";
import Heading from "../../components/UI/Heading";
import Button from "../../components/UI/Button";
import TextField from "../../components/Form/TextField";
import Form from "../../components/Form";
import Text from "../../components/UI/Text";

const Reset = (props) => {
  const { match /* history */ } = props;
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState(null);
  const [errors, setErrors] = useState(null);
  const [valid /* setValid */] = useState(false);
  const id = match.params.id;

  const handleInputChange = (value, name) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
    if (errors?.[name])
      setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  // Handle submission for sending a reset link
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    setTimeout(() => setSubmitting(false), 1500);
  };

  // IF ID IS SET; MAKE SURE ITS VALID
  useEffect(() => {
    if (id && !valid) {
      // FETCH DAT
    }
  }, [id, valid]);

  // Handle submission for changing the password

  const handlePasswordChange = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Header />
      <Box
        background="#EFF7F9"
        minHeight={[
          "calc(100vh - 50px)",
          null,
          null,
          null,
          "calc(100vh - 120px)",
        ]}
        py={["2rem", "3rem", "4rem", "5rem"]}
      >
        <Box maxWidth="820px" margin="0 auto">
          <Box
            p="1rem"
            background="#FFF"
            padding={["1rem", null, null, "2rem", "3rem"]}
          >
            <Heading as="h1" color="#2E2E46" fontFamily="Lato-Black">
              Taasta parool
            </Heading>
            <Box py="0.5rem" my="0.5rem">
              <Text
                as="p"
                fontFamily="Lato-Regular"
                textAlign="center"
                my="1.5rem"
                color="#8C8C8C"
              >
                {!id
                  ? "Sisesta enda e-maili aadress ning saadame teile lingi oma parooli taastamiseks."
                  : "Sisesta enda kontole uus parool millega edaspidi sisse logida."}
              </Text>
              {!id ? (
                <Form onSubmit={handleSubmit}>
                  <Box maxWidth="340px" mx="auto">
                    <TextField
                      size="large"
                      placeholder="Sisesta e-maili aadress"
                      label="E-mail"
                      name="email"
                      value={fields?.email}
                      onChange={handleInputChange}
                      error={errors?.email}
                    />
                    <Box my="2rem" mx="4rem">
                      <Button
                        loading={submitting}
                        type="submit"
                        version="success"
                      >
                        Taasta parool
                      </Button>
                    </Box>
                  </Box>
                </Form>
              ) : (
                <Form onSubmit={handlePasswordChange}>
                  <Box maxWidth="340px" mx="auto">
                    <TextField
                      type="password"
                      size="large"
                      placeholder="Sisesta parool"
                      label="Parool"
                      name="password"
                      autoComplete="off"
                      value={fields?.password}
                      onChange={handleInputChange}
                      error={errors?.password}
                    />
                    <TextField
                      type="password"
                      size="large"
                      placeholder="Sisesta parool"
                      label="Parool uuesti"
                      name="repeat-password"
                      autoComplete="off"
                      value={fields?.["repeat-password"]}
                      onChange={handleInputChange}
                      error={errors?.["repeat-password"]}
                    />
                    <Box my="2rem" mx="4rem">
                      <Button
                        loading={submitting}
                        type="submit"
                        version="success"
                      >
                        Uuenda parooli
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withRouter(Reset);
