import styled, { css } from "styled-components";

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  ${(props) =>
    props.size === "medium" &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.size === "small" &&
    css`
      width: 170px;
    `}
  ${(props) =>
    props.size === "full" &&
    css`
      width: 100%;
    `}
`;
export const GroupLabel = styled.div`
  font-size: 1rem;
  font-family: "Lato-Bold";
  line-height: 1.8;
`;

export const GroupInputError = styled.div`
  line-height: 1.6;
  color: #dd2121;
  font-family: "Lato-Regular";
  font-size: 14px;
  padding-left: 0.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) =>
    props.size === "small" &&
    css`
      width: 170px;
    `}
  ${(props) =>
    props.size === "medium" &&
    css`
      width: 300px;
    `}
`;
export const Label = styled.div`
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Lato-Bold")};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  width: 100%;
  line-height: 1.5;
`;

export const Checkmark = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 12px;
    height: 12px;
    margin-top: 2px;
    margin-right: 1px;
  }
`;

export const Input = styled.div`
  position: relative;
  margin: 0.25rem 0;
  input,
  label {
    &:hover {
      cursor: pointer;
    }
  }
  label {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    line-height: 1.25;
    user-select: none;
    text-align: left;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #bebeca;
      border-radius: 3px;
    }
    ${(props) =>
      props.checked &&
      css`
        &:after {
          background: #1774ed;
          border: 1px solid #1774ed;
        }
      `}
  }
  input {
    appearance: none;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  ${(props) =>
    props.invalid &&
    css`
      label {
        &:after {
          border: 1px solid #dd2121;
        }
      }
    `}
  ${(props) =>
    props.label === "right" &&
    css`
      label {
        padding-left: 30px;
        &:after {
          left: 0;
        }
      }
      ${Checkmark} {
        left: 0;
        svg {
          margin-right: 0;
          margin-left: 2px;
        }
      }
    `}
`;

export const ErrorIcon = styled.div`
  position: absolute;
  right: -1.5rem;
  top: 0.15rem;
  svg {
    width: 15px;
    height: 15px;
  }
`;
export const InputError = styled.div`
  line-height: 1.6;
  color: #dd2121;
  font-family: "Lato-Regular";
  font-size: 14px;
  padding-left: 0.5rem;
`;
