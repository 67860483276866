import React from 'react';
import { useHistory } from 'react-router-dom';

import Flex from '../../UI/Layout/Flex';
import Image from '../../UI/Image';
import Box from '../../UI/Layout/Box';
import Heading from '../../UI/Heading';
import Text from '../../UI/Text';
import Badge from '../../UI/Badge';
import Button from '../../UI/Button';
import Modal from '../../Modal';
import { Link } from 'react-router-dom';

const List = ({
  offers,
  tab,
  modal,
  setModal,
  handleOfferSold,
  handleOfferDelete,
}) => {
  const history = useHistory();
  return (
    <>
      <Box>
        {offers?.length === 0 && <Heading as="h2">Autod puuduvad.</Heading>}
        {offers?.length > 0 &&
          offers.map((car) => {
            const { _id, images, price, vehicle } = car;
            return (
              <Flex
                as="article"
                key={_id}
                border="1px solid #CFE0EC"
                borderRadius="3px"
                padding={['0.5rem', null, null, null, '1rem']}
                mb="1rem"
                flexDirection={['column', 'row']}
                display={['flex', 'inline-flex']}
                background="#FFF"
              >
                <Flex
                  width={[1, null, null, 1, 1 / 3]}
                  display="inline-flex"
                  position="relative"
                >
                  <Box position="absolute" top="10px" left="-25px">
                    {tab === 0 && (
                      <Badge background="#1774ED" color="#FFF" fontSize="13px">
                        Kuulutuse lisamine on pooleli
                      </Badge>
                    )}
                    {tab === 1 && (
                      <Badge background="#1BC744" color="#FFF" fontSize="13px">
                        Kuulutus on aktiivne: 3P 23h
                      </Badge>
                    )}
                    {tab === 2 && (
                      <Badge background="#A2A2A2" color="#FFF" fontSize="13px">
                        Kuulutus ei ole aktiivne
                      </Badge>
                    )}
                    {tab === 3 && (
                      <Badge background="#1BC744" color="#FFF" fontSize="13px">
                        Müüdud
                      </Badge>
                    )}
                  </Box>
                  <Link to={`/kuulutus/${_id}`}>
                    <Image
                      src={images?.length > 0 && images[0]}
                      alt=""
                      objectFit="cover"
                      borderRadius="3px"
                    />
                  </Link>
                </Flex>
                <Flex
                  width={[1, null, null, 1, 2 / 3]}
                  padding={[
                    '0.5rem 0',
                    '0.5rem 1rem',
                    '1rem',
                    '1rem 0.5rem 0.25rem 1.5rem',
                    '0.5rem 0.5rem 0.25rem 2.5rem',
                    '0.5rem 0.5rem 0.25rem 3rem',
                  ]}
                >
                  <Flex
                    mb="0.25rem"
                    display={['inline-flex', 'flex']}
                    flexDirection={['row', 'column']}
                    width="100%"
                  >
                    <Box pr="0.5rem" width={[3 / 4, 1]}>
                      <Link
                        to={`/kuulutus/${_id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Heading as="h2" color="#000">
                          {vehicle?.carBrand?.label} {vehicle?.model?.label}{' '}
                          {vehicle?.motor?.mType}
                        </Heading>
                      </Link>
                    </Box>
                    <Flex
                      width={[1 / 4, 1]}
                      display={['flex', null]}
                      flexDirection={['column', null]}
                      alignItems={['flex-end', 'flex-start']}
                      p={[0, '.85rem 0']}
                      pr={['0.25rem', null]}
                    >
                      {/* <Text
                        as="span"
                        fontFamily="Lato-Bold"
                        color="#A50000"
                        textDecoration="line-through"
                        fontSize={[1, null, 2]}
                      >
                        {discount}.-
                      </Text> */}
                      <Heading
                        as="h3"
                        color="#3B5588"
                        mt="4px"
                        fontSize={[2, 3, 4]}
                      >
                        {price}.-
                      </Heading>
                    </Flex>
                  </Flex>
                  {vehicle && (
                    <Flex
                      display="inline-flex"
                      flexWrap="wrap"
                      margin="0.5rem 0"
                    >
                      {vehicle?.registered?.year && (
                        <Box pr={0.5} pb={0.5}>
                          <Badge mr="8px" mb="8px">
                            {vehicle.registered.year.label}
                          </Badge>
                        </Box>
                      )}
                      {vehicle?.mileage && (
                        <Box pr={0.5} pb={0.5}>
                          <Badge mr="8px" mb="8px">
                            {vehicle.mileage} km
                          </Badge>
                        </Box>
                      )}
                      {(vehicle?.motor?.mType || vehicle?.motor?.kw) && (
                        <Box pr={0.5} pb={0.5}>
                          <Badge mr="8px" mb="8px">
                            {vehicle.motor?.mType}{' '}
                            {vehicle.motor?.kw && `(${vehicle.motor?.kw} kW)`}
                          </Badge>
                        </Box>
                      )}
                      {vehicle?.fuel?.sort && (
                        <Box pr={0.5} pb={0.5}>
                          <Badge
                            mr="8px"
                            mb="8px"
                            color="#FFF"
                            uppercase
                            background="#898989"
                          >
                            {vehicle.fuel.sort.label}
                          </Badge>
                        </Box>
                      )}
                    </Flex>
                  )}
                  {tab === 0 && (
                    <Flex
                      as="nav"
                      my="1.5rem"
                      display={['flex', null, null, 'inline-flex']}
                      flexDirection={['column', null, null, 'row']}
                      width="100%"
                      alignItems="center"
                    >
                      <Box
                        mr={[null, null, '2rem']}
                        mb={['1.5rem', null, null, 0]}
                      >
                        <Button
                          version="primary"
                          onClick={() =>
                            history.push(`/konto/kuulutused/muuda/${_id}`)
                          }
                        >
                          Jätka täitmist
                        </Button>
                      </Box>
                      <Button version="light" onClick={() => setModal(car)}>
                        Kustuta
                      </Button>
                    </Flex>
                  )}
                  {tab === 1 && (
                    <Flex
                      as="nav"
                      mb="1rem"
                      display={['flex', null, null, 'inline-flex']}
                      flexDirection={['column', null, null, 'row']}
                      width="100%"
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      <Box
                        mt={['1rem', null, null, '1.5rem']}
                        mr={[null, null, null, '1rem']}
                      >
                        <Button version="primary-reverse" size="small">
                          Muuda hinda
                        </Button>
                      </Box>
                      <Box
                        mt={['1rem', null, null, '1.5rem']}
                        mr={[null, null, null, '1rem']}
                      >
                        <Button
                          version="success-hollow"
                          size="small"
                          onClick={() => setModal({ type: 'SELL', ...vehicle })}
                        >
                          Märgi müüduks
                        </Button>
                      </Box>
                      <Box
                        /* mt={[, , , "1.5rem"]} */ mt={[
                          '1rem',
                          null,
                          null,
                          '1.5rem',
                        ]}
                      >
                        <Button
                          version="light"
                          size="small"
                          onClick={() => setModal(vehicle)}
                        >
                          Kustuta
                        </Button>
                      </Box>
                    </Flex>
                  )}
                  {tab === 2 && (
                    <Flex
                      as="nav"
                      my="1.5rem"
                      display={['flex', null, null, 'inline-flex']}
                      flexDirection={['column', null, null, 'row']}
                      width="100%"
                      alignItems="center"
                    >
                      <Box
                        mr={[null, null, '2rem']}
                        mb={['1.5rem', null, null, 0]}
                      >
                        <Button version="success">Aktiveeri kuulutus</Button>
                      </Box>
                      <Button version="light" onClick={() => setModal(car)}>
                        Kustuta
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            );
          })}
      </Box>
      {modal && (
        <Modal size="small" onClose={() => setModal(null)}>
          <Flex
            my={['1.5rem', null, '2.5rem']}
            display={['flex', null, 'inline-flex']}
            flexDirection={['column', null, 'row']}
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box m={['1.5rem 0 3rem 0', null, '0 1rem 0 3rem']}>
              {modal.type === 'SELL' ? sell : bin}
            </Box>
            <Flex
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mr={[null, null, '1.5rem']}
            >
              <Heading
                as="h5"
                fontFamily="Lato-Bold"
                textAlign="center"
                lineHeight="1.3"
              >
                {modal.type === 'SELL'
                  ? 'Märgi kuulutus müüduks'
                  : 'Soovid kustutada kuulutust?'}
              </Heading>
              <Box my="1rem">
                <Heading
                  as="h4"
                  fontFamily="Lato-Bold"
                  textAlign="center"
                  lineHeight="1.4"
                >
                  {modal?.vehicle?.carBrand?.label}{' '}
                  {modal?.vehicle?.model?.label} {modal?.vehicle?.motor?.mType}
                </Heading>
                <Text
                  as="p"
                  textAlign="center"
                  fontFamily="Lato-Regular"
                  mt="0.25rem"
                >
                  ID: {modal._id}
                </Text>
              </Box>
              <Flex display="inline-flex" my="2rem">
                <Box mr="1.5rem">
                  <Button
                    version="primary-reverse"
                    onClick={() => setModal(null)}
                  >
                    Ei
                  </Button>
                </Box>
                <Button
                  version={modal.type === 'SELL' ? 'success' : 'danger'}
                  onClick={() =>
                    modal.type === 'SELL'
                      ? handleOfferSold(modal._id)
                      : handleOfferDelete(modal._id)
                  }
                >
                  Jah
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  );
};

const bin = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="87.017"
    height="107.097"
    viewBox="0 0 87.017 107.097"
  >
    <path
      d="M11.192,35.4v66.936a6.713,6.713,0,0,0,6.694,6.694H78.128a6.713,6.713,0,0,0,6.694-6.694V35.4H11.192ZM31.273,95.638H24.579V48.783h6.694Zm13.387,0H37.967V48.783H44.66Zm13.387,0H51.354V48.783h6.694Zm13.387,0H64.741V48.783h6.694ZM86.5,15.315H64.741V6.948a5.035,5.035,0,0,0-5.02-5.02H36.293a5.035,5.035,0,0,0-5.02,5.02v8.367H9.519a5.035,5.035,0,0,0-5.02,5.02V28.7H91.515V20.335a5.035,5.035,0,0,0-5.02-5.02Zm-28.448,0H37.967V8.705H58.047v6.61Z"
      transform="translate(-4.499 -1.928)"
    />
  </svg>
);

const sell = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96.056"
    height="106.973"
    viewBox="0 0 96.056 106.973"
  >
    <path
      d="M303.424,382.675c7.776-14.655,25.249-14.607,32.973,0h18.222c1.775,0,3.56-.12,5.321.036,4.617.408,7.53,3.4,7.919,8.065.111,1.323.027,2.661.027,3.991v71.855a25.672,25.672,0,0,1-.041,3.987c-.758,4.781-2.6,7.153-8.007,7.966a27.069,27.069,0,0,1-3.987.039H283.993a27.446,27.446,0,0,1-3.987-.037c-5.59-.83-7.264-3.355-8.024-7.954a20.623,20.623,0,0,1-.036-3.323V394.115c0-.666.052-1.336-.005-2a8.738,8.738,0,0,1,9.268-9.495c5.979.226,11.973.051,17.962.049Zm-7.234,46.258,17.541,17.38c10.023-10.021,19.6-19.59,28.577-28.569l-4.938-5.507c-3.672,3.768-7.321,7.609-11.078,11.342-3.858,3.832-7.541,7.87-11.785,11.51L302.348,423.2Zm27.716-41.228c-.15-2.592-1.6-3.821-4.311-3.666-2.585.147-3.829,1.606-3.672,4.31.151,2.588,1.607,3.828,4.314,3.671C322.825,391.868,324.063,390.411,323.906,387.705Z"
      transform="translate(-271.892 -371.701)"
    />
  </svg>
);

export default List;
