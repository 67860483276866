import React, { useState } from 'react';
import Flex from '../UI/Layout/Flex';
import Box from '../UI/Layout/Box';
import Image from '../UI/Image';
import Text from '../UI/Text';
import Icon from '../UI/Icon';
import ImageModal from './ImageModal';

const SingleOfferImages = ({ images }) => {
  const [modal, setModal] = useState(null);
  return (
    <>
      <Flex
        display="flex"
        flexDirection="column"
        maxWidth="750px"
        mr={[null, null, null, '3rem']}
      >
        <Box
          position="relative"
          onClick={() => setModal(0)}
          style={{
            cursor: 'pointer',
          }}
        >
          <Image src={modal ? images[modal] : images[0]} borderRadius="3px" />
          <Flex
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              p={['0.5rem', null, '1rem', '1.5rem']}
              bg="rgba(0,0,0,0.5)"
              borderRadius="3px"
            >
              <Icon width={['28px', null, '34px', '42px']}>{zoom}</Icon>
            </Box>
          </Flex>
        </Box>
        {images?.length > 1 && (
          <Flex
            display="inline-flex"
            width="100%"
            mt="0.75rem"
            flexWrap="wrap"
            mb={[null, '1rem', null, null, 0]}
          >
            {images.slice(0, 5).map((img, i) => {
              if (i === 0) return <></>;
              return (
                <Box
                  key={i}
                  mr={[i < 4 && i % 2 !== 0 && '1rem', i < 4 && '1rem']}
                  mb={['1rem', 0]}
                  width={['calc(50% - 0.5rem)', 'calc(25% - 0.75rem)']}
                  position="relative"
                  style={{
                    cursor: 'pointer',
                  }}
                  height={['111px', '125px', null, '135px']}
                >
                  <Image
                    src={img}
                    borderRadius="3px"
                    onClick={() => setModal(i)}
                    height="100%"
                    width="100%"
                    objectFit="cover"
                  />
                  {i === 4 && (
                    <Flex
                      position="absolute"
                      top="0"
                      left="0"
                      width="100%"
                      height="100%"
                      bg="rgba(0,0,0,0.5)"
                      borderRadius="3px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      onClick={() => setModal(0)}
                    >
                      <Text
                        as="span"
                        color="#FFF"
                        fontSize={[
                          '16px',
                          '18px',
                          '20px',
                          '22px',
                          '24px',
                          '28px',
                        ]}
                        fontFamily="Lato-Bold"
                        style={{
                          userSelect: 'none',
                        }}
                      >
                        +{`${images.length - 5}`}
                      </Text>
                    </Flex>
                  )}
                </Box>
              );
            })}
          </Flex>
        )}
      </Flex>
      {(modal || modal === 0) && (
        <ImageModal
          images={images}
          index={modal}
          setIndex={setModal}
          onClose={() => setModal(null)}
        />
      )}
    </>
  );
};

const zoom = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55.517"
    height="55.517"
    viewBox="0 0 55.517 55.517"
  >
    <path
      d="M44.178,39.416H41.67l-.889-.857a20.664,20.664,0,1,0-2.222,2.222l.857.889v2.508L55.287,60.017l4.73-4.73Zm-19.045,0A14.284,14.284,0,1,1,39.416,25.132,14.265,14.265,0,0,1,25.132,39.416Zm7.936-12.7H26.719v6.348H23.545V26.719H17.2V23.545h6.348V17.2h3.174v6.348h6.348Z"
      transform="translate(-4.5 -4.5)"
      fill="#fff"
    />
  </svg>
);

export default SingleOfferImages;
