import React from "react";
import styled from "styled-components";
import Box from "../UI/Layout/Box";
import { HideMobile } from "../UI/GlobalStyle";
import Heading from "../UI/Heading";
import Flex from "../UI/Layout/Flex";
import { Button } from "../UI/Button/styles";
import carBrands from "../../data/carBrands.json";
const carBrandOptions = carBrands.carBrands;

const CarList = (props) => {
  const { setFields } = props;
  return (
    <HideMobile>
      <Box background="#F9FBFF" borderRadius="4px" p="1rem" my="2rem">
        <Heading as="h4" color="#3B5588" mt="1rem" mb="1.5rem">
          Margid
        </Heading>
        <CustomFlex
          display="flex"
          flexDirection="column"
          maxHeight="400px"
          overflow="auto"
        >
          {carBrandOptions.map((brand, i) => {
            return (
              <Button
                key={i}
                version="regular"
                onClick={() =>
                  setFields({
                    carBrand: {
                      value: brand.value,
                      label: brand.label,
                    },
                  })
                }
              >
                {brand.label}»
              </Button>
            );
          })}
        </CustomFlex>
      </Box>
    </HideMobile>
  );
};

const CustomFlex = styled(Flex)`
  @media (min-width: 1024px) {
    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.06);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`;

export default CarList;
