import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  ${(props) =>
    props.size === "medium" &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.size === "small" &&
    css`
      width: 170px;
    `}
  ${(props) =>
    props.size === "xsmall" &&
    css`
      width: 100px;
    `}
  ${(props) =>
    props.size === "xxsmall" &&
    css`
      width: 50px;
    `}
`;
export const Label = styled.label`
  font-size: 1rem;
  font-family: "Lato-Bold";
  line-height: 1.8;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.6rem;
  user-select: none;
  pointer-events: none;
  svg {
    width: 15px;
    height: 15px;
  }
  ${(props) =>
    props.position === "right" &&
    css`
      left: unset;
      right: 0.6rem;
    `}
`;

export const Input = styled.div`
  position: relative;
  .SingleDatePicker {
    font-family: "Lato-Regular";
    width: 100%;
    .SingleDatePickerInput {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      .DateInput {
        width: 100%;
        &_input {
          padding: 0.4rem 0.5rem;
          padding-bottom: 0.3rem;
          font-size: 14px;
          font-family: "Lato-Bold";
          &__focused {
            border-bottom: 2px solid #1774ed;
          }
          &::placeholder {
            font-family: "Lato-Regular";
            color: #8c8c8c;
          }
        }
      }
      &_calendarIcon {
        padding: 0.5rem 0.75rem;
        margin: 0;
        border-left: 1px solid #bebeca;
      }
      &__withBorder {
        border: 1px solid #bebeca;
        border-radius: 3px;
      }
    }
    &_picker {
      font-family: "Lato-Regular";
      .CalendarDay {
        vertical-align: middle;
        &__selected,
        &__selected:hover {
          background: #1774ed;
          border: 1px double #1774ed;
        }
      }
    }
    .DayPickerKeyboardShortcuts_show__bottomRight::before {
      border-right: 33px solid #1774ed;
    }
  }
  ${(props) =>
    props.invalid &&
    css`
      .SingleDatePicker {
        .SingleDatePickerInput {
          .DateInput {
            &_input {
              padding-right: 2rem;
            }
          }
          border: 1px solid #dd2121;
          &_calendarIcon {
            border-left: 1px solid #dd2121;
          }
        }
      }
    `}
`;

export const InputError = styled.div`
  line-height: 1.6;
  color: #dd2121;
  font-family: "Lato-Regular";
  font-size: 14px;
  padding-left: 0.5rem;
`;
