import styled from "styled-components";
import { space, typography } from "styled-system";

export const Wrapper = styled.span`
  display: flex;
  width: max-content;
  padding: 0.25rem 0.4rem;
  font-size: 14px;
  font-family: ${(props) => (props.bold ? "Lato-Bold" : "Lato-Regular")};
  color: ${(props) => (props.color ? props.color : "#000")};
  background: ${(props) => (props.background ? props.background : "#E5E9EB")};
  border-radius: 50px;
  ${(props) => props.uppercase && `text-transform: uppercase;`}
  ${space}
  ${typography}
`;
