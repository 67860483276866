import React from 'react';
import styled from 'styled-components';
import Box from '../UI/Layout/Box';
import Image from '../UI/Image';
import Flex from '../UI/Layout/Flex';
import Icon from '../UI/Icon';

const ImageModal = ({ images, onClose, index, setIndex }) => {
  return (
    <>
      <Box
        zIndex="1001"
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100vh"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          pointerEvents: 'none',
        }}
      >
        <Flex
          as="button"
          position="absolute"
          top={['1rem', null, '2rem', '3rem']}
          right={['1rem', null, '2rem', '3rem']}
          background="#FFF"
          border="none"
          onClick={onClose}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          height={['35px', null, null, '45px']}
          width={['35px', null, null, '45px']}
        >
          {close}
        </Flex>
        <Box
          borderRadius="4px"
          position="relative"
          m="auto"
          mt={['5rem', 'auto']}
          style={{ pointerEvents: 'all' }}
          width={['90vw', null, null, '60vw']}
          height={['55vh', '60vh']}
        >
          <Box height="inherit" width="inherit">
            <Box
              position="absolute"
              left={['0', '15px', null, '-70px']}
              top={['calc(100% - 50px)', '50%']}
              zIndex="1"
              style={{
                userSelect: 'none',
                cursor: 'pointer',
                transform: 'translateY(-50%)',
              }}
              onClick={() =>
                index > 0 ? setIndex(index - 1) : setIndex(images.length - 1)
              }
            >
              <Icon width={['32px', null, null, '42px', '48px']}>
                {arrowLeft}
              </Icon>
            </Box>
            <Image
              src={images[index]}
              height="inherit"
              width="inherit"
              objectFit="contain"
            />
            <Box
              position="absolute"
              right={['0', '15px', null, '-70px']}
              top={['calc(100% - 50px)', '50%']}
              zIndex="1"
              style={{
                userSelect: 'none',
                cursor: 'pointer',
                transform: 'translateY(-50%)',
              }}
              onClick={() =>
                index < images.length - 1 ? setIndex(index + 1) : setIndex(0)
              }
            >
              <Icon width={['32px', null, null, '42px', '48px']}>
                {arrowRight}
              </Icon>
            </Box>
          </Box>
          <CustomFlex
            display="inline-flex"
            position="absolute"
            bottom={[
              'calc(-80px - 1rem)',
              'calc(-150px - 1rem)',
              null,
              'calc(-160px - 1rem)',
            ]}
            left="0"
            overflowX="auto"
            width="100%"
            style={{}}
          >
            {images.map((img, i) => {
              return (
                <Box
                  key={i}
                  mr="1rem"
                  pb="1.5rem"
                  onClick={() => index !== i && setIndex(i)}
                  style={{
                    userSelect: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    border={
                      index === i ? '1px solid #FFF' : '1px solid transparent'
                    }
                    borderRadius="3px"
                  >
                    <Image
                      src={img}
                      maxHeight="inherit"
                      maxWidth="inherit"
                      objectFit="cover"
                      width={['130px', null, '150px']}
                      height={['85px', '100px', '120px']}
                      borderRadius="3px"
                    />
                  </Box>
                </Box>
              );
            })}
          </CustomFlex>
        </Box>
      </Box>
      <Box
        zIndex="1000"
        position="fixed"
        top="0"
        left="0"
        height="100vh"
        width="100%"
        background="#464646"
        overflow="hidden"
        onClick={onClose}
      ></Box>
    </>
  );
};

const CustomFlex = styled(Flex)`
  @media (min-width: 1024px) {
    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
    }
  }
`;

const close = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.158"
    height="16.158"
    viewBox="0 0 16.158 16.158"
  >
    <path
      d="M23.658,9.127,22.031,7.5l-6.452,6.452L9.127,7.5,7.5,9.127l6.452,6.452L7.5,22.031l1.627,1.627,6.452-6.452,6.452,6.452,1.627-1.627-6.452-6.452Z"
      transform="translate(-7.5 -7.5)"
      fill="#bdbdbd"
    />
  </svg>
);

const arrowLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51.845"
    height="51.845"
    viewBox="0 0 51.845 51.845"
  >
    <g transform="translate(-1.5 -1.5)">
      <path
        d="M51.845,27.423A24.423,24.423,0,1,1,27.423,3,24.423,24.423,0,0,1,51.845,27.423Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M21.769,12,12,21.769l9.769,9.769"
        transform="translate(5.654 5.654)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M31.538,18H12"
        transform="translate(5.654 9.423)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

const arrowRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51.845"
    height="51.845"
    viewBox="0 0 51.845 51.845"
  >
    <g transform="translate(1.5 1.5)">
      <path
        d="M3,27.423A24.422,24.422,0,1,0,27.422,3,24.422,24.422,0,0,0,3,27.423Z"
        transform="translate(-3 -3)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M12,12l9.769,9.769L12,31.538"
        transform="translate(12.422 2.654)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_64"
        data-name="Path 64"
        d="M12,18H31.538"
        transform="translate(2.653 6.423)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </g>
  </svg>
);

export default ImageModal;
