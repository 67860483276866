import React from "react";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Container from "../UI/Layout/Container";
import Box from "../UI/Layout/Box";
import Nav from "./Nav";
import Flex from "../UI/Layout/Flex";

const Account = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("token");
  if (!token) {
    // If user exists but has no token (expired), log user out
    dispatch({
      type: "SET_USER",
      payload: null,
    });
    history.push("/logi-sisse");
  }

  return (
    <Container>
      <Box
        position="absolute"
        top="0"
        left="0"
        height="100vh"
        width="100%"
        zIndex="-1"
        background="#EFF7F9"
      ></Box>
      <Flex
        display={["flex", null, null, null, "inline-flex"]}
        flexDirection={["column", null, null, null, "row"]}
        width="100%"
        my={["5rem", null, null, null, "4rem"]}
        background="#FFF"
      >
        <Nav />
        <Box
          p={["1rem", "2rem", "3rem", "4rem", "5rem"]}
          my={["1rem", null, null, null, 0]}
          width="100%"
        >
          {children}
        </Box>
      </Flex>
    </Container>
  );
};

export default Account;
