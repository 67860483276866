import React from "react";
import Box from "../UI/Layout/Box";

const Modal = ({ size, children, onClose }) => {
  return (
    <>
      <Box
        zIndex="1001"
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100vh"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          pointerEvents: "none",
        }}
      >
        <Box
          background="#FFF"
          borderRadius="4px"
          position="relative"
          m="auto"
          width={[
            "90vw",
            null,
            size === "small" ? "80vw" : "90vw",
            size === "small" ? "70vw" : "80vw",
            size === "small" ? "50vw" : "65vw",
            size === "small" ? "30vw" : "50vw",
          ]}
          p={["1rem", null, "1.5rem", "2rem"]}
          style={{ pointerEvents: "all" }}
        >
          <Box
            as="button"
            position="absolute"
            top="1rem"
            right="1rem"
            background="none"
            border="none"
            onClick={onClose}
          >
            {close}
          </Box>
          {children}
        </Box>
      </Box>
      <Box
        zIndex="1000"
        position="fixed"
        top="0"
        left="0"
        height="100vh"
        width="100%"
        background="#404460"
        overflow="hidden"
        onClick={onClose}
      ></Box>
    </>
  );
};

const close = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.158"
    height="16.158"
    viewBox="0 0 16.158 16.158"
  >
    <path
      d="M23.658,9.127,22.031,7.5l-6.452,6.452L9.127,7.5,7.5,9.127l6.452,6.452L7.5,22.031l1.627,1.627,6.452-6.452,6.452,6.452,1.627-1.627-6.452-6.452Z"
      transform="translate(-7.5 -7.5)"
      fill="#bdbdbd"
    />
  </svg>
);

export default Modal;
