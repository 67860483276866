import React from "react";

import Box from "../../UI/Layout/Box";
import Table from "../../UI/Table";
import TableHeader from "../../UI/Table/TableHeader";
import Heading from "../../UI/Heading";
import Flex from "../../UI/Layout/Flex";
import TableRow from "../../UI/Table/TableRow";
import Text from "../../UI/Text";
import { Link } from "react-router-dom";

const List = ({ invoices }) => {
  return (
    <Box my="2rem">
      <Table maxWidth="875px">
        <TableHeader>
          <Flex display="flex" flex="0.6">
            <Heading as="h6" fontFamily="Lato-Regular">
              Kuulutus
            </Heading>
          </Flex>
          <Flex display="flex" flex="0.2">
            <Heading as="h6" fontFamily="Lato-Regular">
              ID
            </Heading>
          </Flex>
          <Flex display="flex" flex="0.3">
            <Heading as="h6" fontFamily="Lato-Regular">
              Kuupäev
            </Heading>
          </Flex>
          <Flex display="flex" flex="0.3">
            <Heading as="h6" fontFamily="Lato-Regular">
              Summa
            </Heading>
          </Flex>
          <Flex display="flex" flex="0.2">
            <Heading as="h6" fontFamily="Lato-Regular">
              Arve
            </Heading>
          </Flex>
        </TableHeader>
        {invoices.map((invoice, i) => {
          const { id, offerId, name, date, price, receipt } = invoice;
          return (
            <TableRow
              key={offerId}
              borderBottom={[
                i < invoices.length - 1 && "1px solid #E1E1E1",
                null,
                null,
                "none",
              ]}
            >
              <Box display={[null, null, null, "none"]} mb="1rem">
                <Heading as="h5" color="#3B5588">
                  Kuulutus
                </Heading>
              </Box>
              <Flex display="flex" flex="0.6">
                <Text as="p" fontFamily="Lato-Bold">
                  <Link
                    to={`/kuulutus/${offerId}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {name}
                  </Link>
                </Text>
              </Flex>
              <Box display={[null, null, null, "none"]} my="1rem">
                <Heading as="h5" color="#3B5588">
                  ID
                </Heading>
              </Box>
              <Flex display="flex" flex="0.2">
                <Text as="p" fontFamily="Lato-Regular">
                  #{id}
                </Text>
              </Flex>
              <Box display={[null, null, null, "none"]} my="1rem">
                <Heading as="h5" color="#3B5588">
                  Kuupäev
                </Heading>
              </Box>
              <Flex display="flex" flex="0.3">
                <Text as="p" fontFamily="Lato-Regular">
                  {date}
                </Text>
              </Flex>
              <Box display={[null, null, null, "none"]} my="1rem">
                <Heading as="h5" color="#3B5588">
                  Summa
                </Heading>
              </Box>
              <Flex display="flex" flex="0.3">
                <Text as="p" fontFamily="Lato-Regular">
                  {price}€
                </Text>
              </Flex>
              <Box display={[null, null, null, "none"]} my="1rem">
                <Heading as="h5" color="#3B5588">
                  Arve
                </Heading>
              </Box>
              <Flex display="flex" flex="0.2">
                <a href={receipt} target="_blank" rel="noopener noreferrer">
                  {file}
                </a>
              </Flex>
            </TableRow>
          );
        })}
      </Table>
    </Box>
  );
};

const file = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.5"
    height="22"
    viewBox="0 0 16.5 22"
  >
    <path
      d="M9.625,5.844V0H1.031A1.029,1.029,0,0,0,0,1.031V20.969A1.029,1.029,0,0,0,1.031,22H15.469A1.029,1.029,0,0,0,16.5,20.969V6.875H10.656A1.034,1.034,0,0,1,9.625,5.844Zm2.75,10.141a.517.517,0,0,1-.516.516H4.641a.517.517,0,0,1-.516-.516v-.344a.517.517,0,0,1,.516-.516h7.219a.517.517,0,0,1,.516.516Zm0-2.75a.517.517,0,0,1-.516.516H4.641a.517.517,0,0,1-.516-.516v-.344a.517.517,0,0,1,.516-.516h7.219a.517.517,0,0,1,.516.516Zm0-3.094v.344a.517.517,0,0,1-.516.516H4.641a.517.517,0,0,1-.516-.516v-.344a.517.517,0,0,1,.516-.516h7.219A.517.517,0,0,1,12.375,10.141Zm4.125-4.9V5.5H11V0h.262a1.03,1.03,0,0,1,.73.3L16.2,4.512A1.028,1.028,0,0,1,16.5,5.238Z"
      fill="#a2b2c7"
    />
  </svg>
);

export default List;
