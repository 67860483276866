import React from 'react';

import { Wrapper, Input, ErrorIcon, InputError } from './styles';
import Box from '../../UI/Layout/Box';
import Image from '../../UI/Image';
import Icon from '../../UI/Icon';
import Flex from '../../UI/Layout/Flex';

const MultiImages = (props) => {
  const { maxImages, size, fields, disabled, error, removeImage } = props;
  return (
    <>
      {Array.from(Array(maxImages)).map((_, i) => {
        return (
          <>
            <Wrapper
              key={i}
              size={size || 'medium'}
              preview={fields?.images?.[`${i}`]}
            >
              <Input invalid={error}>
                <input
                  type="file"
                  name={`${i}`}
                  disabled={disabled}
                  multiple
                  onChange={(e) => props.onChange([...e.target.files], i)}
                />
                {fields?.images?.[`${i}`] ? (
                  <Box>
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      width="100%"
                      height="100%"
                      style={{
                        pointerEvents: 'none',
                        userSelect: 'none',
                      }}
                    >
                      <Image
                        src={fields?.images?.[`${i}`]}
                        alt=""
                        width="100%"
                        height="100%"
                        objectFit="cover"
                        borderRadius="3px"
                        border="1px solid #BEBECA"
                      />
                    </Box>
                    <Flex
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      position="absolute"
                      top="50%"
                      left="50%"
                      zIndex="1"
                      p="0.5rem 0.75rem"
                      bg="rgba(64, 64, 64, 0.5)"
                      borderRadius="3px"
                      style={{
                        transform: 'translate(-50%, -50%)',
                        cursor: 'pointer',
                      }}
                      onClick={() => removeImage(`${i}`)}
                    >
                      <Icon>{can}</Icon>
                    </Flex>
                  </Box>
                ) : (
                  <Flex
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    background="#FFF"
                    border="1px dashed #BEBECA"
                    borderRadius="3px"
                    style={{
                      pointerEvents: 'none',
                      userSelect: 'none',
                    }}
                  >
                    <Icon>{add}</Icon>
                  </Flex>
                )}
                {error && <ErrorIcon>{errorIcon}</ErrorIcon>}
              </Input>
              {error && <InputError>{error.msg}</InputError>}
            </Wrapper>
          </>
        );
      })}
    </>
  );
};

const errorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.375"
    height="19"
    viewBox="0 0 21.375 19"
  >
    <path
      d="M21.134,16.329A1.782,1.782,0,0,1,19.592,19H1.783A1.782,1.782,0,0,1,.24,16.329L9.145.89a1.782,1.782,0,0,1,3.086,0ZM10.687,13.137a1.707,1.707,0,1,0,1.707,1.707A1.707,1.707,0,0,0,10.687,13.137ZM9.067,7l.275,5.047a.445.445,0,0,0,.445.421h1.8a.445.445,0,0,0,.445-.421L12.308,7a.445.445,0,0,0-.445-.47H9.511A.445.445,0,0,0,9.067,7Z"
      fill="#dd2121"
    />
  </svg>
);

const add = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.06"
    height="30.06"
    viewBox="0 0 30.06 30.06"
  >
    <g transform="translate(1.5 1.5)">
      <path
        d="M30.06,16.53A13.53,13.53,0,1,1,16.53,3,13.53,13.53,0,0,1,30.06,16.53Z"
        transform="translate(-3 -3)"
        fill="none"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M18,12V22.824"
        transform="translate(-4.47 -3.882)"
        fill="none"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M12,18H22.824"
        transform="translate(-3.882 -4.47)"
        fill="none"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

const can = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.826"
    height="21.633"
    viewBox="0 0 16.826 21.633"
  >
    <path
      d="M8.7,23.729a2.411,2.411,0,0,0,2.4,2.4H20.72a2.411,2.411,0,0,0,2.4-2.4V9.307H8.7Zm2.956-8.557,1.695-1.695,2.56,2.548,2.548-2.548,1.695,1.695L17.607,17.72l2.548,2.548-1.695,1.695-2.548-2.548-2.548,2.548L11.67,20.268l2.548-2.548ZM20.119,5.7l-1.2-1.2H12.908l-1.2,1.2H7.5v2.4H24.326V5.7Z"
      transform="translate(-7.5 -4.5)"
      fill="#fff"
    />
  </svg>
);

export default MultiImages;
