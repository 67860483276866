import React from "react";

import { GroupWrapper, GroupLabel, GroupInputError } from "./styles";

const Group = (props) => {
  return (
    <GroupWrapper size={props.size}>
      {props.label && <GroupLabel>{props.label}</GroupLabel>}
      {props.children}
      {props.error && <GroupInputError>{props.error.msg}</GroupInputError>}
    </GroupWrapper>
  );
};

export default Group;
