import React from "react";
import Box from "../Layout/Box";

const Table = ({ maxWidth, children }) => {
  return (
    <Box width="100%" maxWidth={maxWidth}>
      {children}
    </Box>
  );
};

export default Table;
