import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  ${(props) =>
    props.size === "full" &&
    css`
      max-width: 100%;
    `}
  ${(props) =>
    props.size === "large" &&
    css`
      max-width: 340px;
    `}
  ${(props) =>
    props.size === "medium" &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.size === "small" &&
    css`
      width: 170px;
    `}
  ${(props) =>
    props.size === "xsmall" &&
    css`
      width: 138px;
    `}
  ${(props) =>
    props.size === "xxsmall" &&
    css`
      width: 70px;
    `}
`;
export const Label = styled.label`
  font-size: 1rem;
  font-family: "Lato-Bold";
  line-height: 1.8;
`;

export const Icon = styled.div`
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  user-select: none;
  pointer-events: none;
  svg {
    width: 15px;
    height: 15px;
  }
  ${(props) =>
    props.position === "right" &&
    css`
      left: unset;
      right: 0.6rem;
    `}
`;
export const ErrorIcon = styled(Icon)`
  right: 0.6rem;
  left: unset;
`;
export const Input = styled.div`
  position: relative;
  input {
    border: 1px solid #bebeca;
    padding: 0.5rem;
    font-size: 14px;
    color: #494949;
    font-family: "Lato-Bold";
    border-radius: 3px;
    width: 100%;
    transition: 0.15s border ease;
    &::placeholder {
      font-family: "Lato-Regular";
      color: #8c8c8c;
    }
    &:hover {
      transition: 0.15s border ease;
      border: 1px solid #596d87;
    }
    &:focus {
      transition: 0.15s border ease;
      border: 1px solid #1774ed;
    }
    &:disabled {
        background: #F9F9F9;
        cursor: not-allowed;
        &::placeholder {
            color: #D4D4D4;
        }
        &:hover {
            border: 1px solid #bebeca;
        }
    }
  }
  ${(props) =>
    props.invalid &&
    css`
      input {
        border: 1px solid #dd2121;
        padding-right: 2rem;
      }
    `}
  ${(props) =>
    props.icon &&
    css`
      input {
        padding-left: 2rem;
      }
    `}
    ${(props) =>
      props.icon &&
      props.invalid &&
      css`
        ${Icon} {
          right: 2rem;
          ~ input {
            padding-left: 2rem;
          }
        }
        ${props.icon !== "left" &&
        css`
          ${Icon} {
            ~ input {
              padding-right: 3.25rem;
              padding-left: 0.5rem;
            }
          }
        `}
        ${ErrorIcon} {
          right: 0.6rem;
        }
      `}
`;

export const InputError = styled.div`
  line-height: 1.6;
  color: #dd2121;
  font-family: "Lato-Regular";
  font-size: 14px;
  padding-left: 0.5rem;
`;
