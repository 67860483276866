import React from "react";

import Container from "../../components/UI/Layout/Container";
import Box from "../../components/UI/Layout/Box";
import Heading from "../../components/UI/Heading";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Container>
        <Box
          position="absolute"
          top="0"
          left="0"
          height="100vh"
          width="100%"
          zIndex="-1"
          background="#EFF7F9"
        ></Box>
        <Box my={["5rem", null, null, null, "4rem"]} background="#FFF" p="1rem">
          <Heading as="h1">Privaatsuspoliitika</Heading>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
