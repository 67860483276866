import React from "react";

import Header from "../../components/Header";
import Invoices from "../../components/Account/Invoices";

const InvoicesPage = () => {
  return (
    <>
      <Header />
      <Invoices />
    </>
  );
};

export default InvoicesPage;
