import React from "react";
import Flex from "../Layout/Flex";

const TableHeader = ({ children }) => {
  return (
    <Flex
      display={["none", null, null, "inline-flex"]}
      width="100%"
      py={[null, null, null, "0.75rem"]}
      borderBottom="1px solid #E1E1E1"
      mb="0.5rem"
    >
      {children}
    </Flex>
  );
};

export default TableHeader;
