import React from "react";

import {
  Wrapper,
  Label,
  Input,
  Icon,
  ErrorIcon,
  InputError,
  NumberController,
  Increment,
  Decrement,
} from "./styles";

const Number = (props) => {
  return (
    <Wrapper size={props.size || "medium"}>
      {props.label && <Label>{props.label}</Label>}
      <Input
        invalid={props.error}
        icon={props.icon ? props.icon.position : null}
      >
        {props.icon && (
          <Icon position={props.icon?.position}>{props.icon?.svg}</Icon>
        )}
        <input
          type="number"
          placeholder={props.placeholder}
          name={props.name}
          step={+props.step || 1}
          min={props.min || 0}
          max={props.max}
          value={
            props.float === 0
              ? +props.value > 0
                ? Math.round(+props.value)
                : props.value
                ? 0
                : ""
              : props.float
              ? props.value
                ? props.value > 0
                  ? +props.value.toFixed(props.float || 1)
                  : 0
                : ""
              : ""
          }
          disabled={props.disabled}
          onChange={(e) =>
            props.onChange(
              props.float === 0 ? Math.round(+e.target.value) : +e.target.value,
              props.name
            )
          }
        />
        {props.error && <ErrorIcon>{errorIcon}</ErrorIcon>}
        <NumberController>
          <Increment
            onClick={() =>
              props.onChange(
                +props.step
                  ? +props.value
                    ? props.float === 0
                      ? Math.round(+props.value + +props.step)
                      : parseFloat(
                          (+props.value + +props.step).toFixed(props.float || 1)
                        )
                    : +props.step.toFixed(1)
                  : +props.value + 1,
                props.name
              )
            }
          >
            {increment}
          </Increment>
          <Decrement
            onClick={() =>
              props.onChange(
                +props.value > 0
                  ? props.step
                    ? props.float === 0
                      ? Math.round(+props.value - props.step)
                      : parseFloat(
                          (+props.value - props.step).toFixed(props.float || 1)
                        )
                    : +props.value - 1
                  : 0,
                props.name
              )
            }
          >
            {decrement}
          </Decrement>
        </NumberController>
      </Input>
      {props.error && <InputError>{props.error.msg}</InputError>}
    </Wrapper>
  );
};

const errorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.375"
    height="19"
    viewBox="0 0 21.375 19"
  >
    <path
      d="M21.134,16.329A1.782,1.782,0,0,1,19.592,19H1.783A1.782,1.782,0,0,1,.24,16.329L9.145.89a1.782,1.782,0,0,1,3.086,0ZM10.687,13.137a1.707,1.707,0,1,0,1.707,1.707A1.707,1.707,0,0,0,10.687,13.137ZM9.067,7l.275,5.047a.445.445,0,0,0,.445.421h1.8a.445.445,0,0,0,.445-.421L12.308,7a.445.445,0,0,0-.445-.47H9.511A.445.445,0,0,0,9.067,7Z"
      fill="#dd2121"
    />
  </svg>
);

const increment = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.902"
    height="9.573"
    viewBox="0 0 14.902 9.573"
  >
    <path
      d="M0,0,5.33,5.33,10.66,0"
      transform="translate(12.781 7.451) rotate(180)"
      fill="none"
      stroke="#707070"
      strokeLinecap="round"
      strokeWidth="3"
    />
  </svg>
);

const decrement = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.902"
    height="9.573"
    viewBox="0 0 14.902 9.573"
  >
    <path
      d="M0,0,5.33,5.33,10.66,0"
      transform="translate(2.121 2.121)"
      fill="none"
      stroke="#707070"
      strokeLinecap="round"
      strokeWidth="3"
    />
  </svg>
);

export default Number;
