import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getOffer } from '../../../../store/actions/offer';

import Box from '../../../UI/Layout/Box';
import Heading from '../../../UI/Heading';
import Flex from '../../../UI/Layout/Flex';
import Nav from './Nav';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import formatEditCreator from '../../../helpers/formatEditCreator';

const OfferCreator = ({ editMode, USER }) => {
  const [completed, setCompleted] = useState(-1);
  const [masterFields, setMasterFields] = useState(null);
  const [tab, setTab] = useState(0);

  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get('token');

  if (!token) {
    if (USER) {
      // Clear user if it is set
      dispatch({
        type: 'SET_USER',
        payload: null,
      });
    }
    // Redirect if user has no token
    history.push('/logi-sisse');
  }

  // If is editmode set input values
  const { id } = match.params;
  useEffect(() => {
    if (USER && editMode && id) {
      dispatch(getOffer(id)).then((res) => {
        const { offer } = res.data.data;
        // TODO: SHOW ERROR
        if (!offer) history.push('/konto/kuulutused');
        // TODO: SHOW ERROR
        if (offer.creator !== USER._id) history.push('/konto/kuulutused');
        setMasterFields(formatEditCreator(offer));
      });
    }
  }, [editMode, id, dispatch, USER, history]);

  if (editMode && !masterFields) return null;

  return (
    <Box w="100%" bg="#FFF">
      <Flex
        width="100%"
        display={['flex', null, null, null, 'inline-flex']}
        flexDirection={['column', null, null, null, 'row']}
      >
        <Flex
          minWidth={[null, null, null, null, '375px']}
          maxWidth={[null, null, null, null, '375px']}
          as="nav"
          bg="#F2FBFE"
          p={['1.5rem 1rem', '1.5rem', null, '2rem', '4rem 3rem 4rem 4rem']}
          flexDirection="column"
        >
          <Box mb="1.5rem">
            <Heading as="h4" fontFamily="Lato-Black" color="#2E2E46">
              Kuulutuse lisamine
            </Heading>
          </Box>
          <Flex
            width="100%"
            display={['inline-flex', null, null, null, 'flex']}
            flexDirection={['row', null, null, null, 'column']}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Nav tab={tab} setTab={setTab} completed={completed} />
          </Flex>
        </Flex>
        <Box
          width="100%"
          p={[
            '1rem',
            '1.5rem 1rem',
            '2rem',
            '2.5rem',
            '3.5rem',
            '4.5rem 5.5rem',
          ]}
        >
          {tab === 0 && (
            <StepOne
              setTab={setTab}
              masterFields={masterFields}
              setMasterFields={setMasterFields}
              setCompleted={setCompleted}
            />
          )}
          {tab === 1 && (
            <StepTwo
              setTab={setTab}
              masterFields={masterFields}
              setMasterFields={setMasterFields}
              setCompleted={setCompleted}
            />
          )}
          {tab === 2 && (
            <StepThree
              setTab={setTab}
              masterFields={masterFields}
              setMasterFields={setMasterFields}
              setCompleted={setCompleted}
            />
          )}
          {tab === 3 && (
            <StepFour
              setTab={setTab}
              masterFields={masterFields}
              setMasterFields={setMasterFields}
              setCompleted={setCompleted}
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.user?.data,
  };
};

export default connect(mapStateToProps)(OfferCreator);
