import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, getUser } from "../../store/actions/user";
import { CreateToast } from "../../components/helpers/CreateToast";

import Header from "../../components/Header";
import Box from "../../components/UI/Layout/Box";
import Heading from "../../components/UI/Heading";
import Button from "../../components/UI/Button";
import Flex from "../../components/UI/Layout/Flex";
import TextField from "../../components/Form/TextField";
import { Link } from "react-router-dom";
import Form from "../../components/Form";

const Login = () => {
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState(null);
  const [errors, setErrors] = useState(null);
  const USER = useSelector((state) => state.user?.data);
  const history = useHistory();
  const dispatch = useDispatch();

  // Redirect if user exists

  useEffect(() => {
    if (USER) {
      history.push("/");
    }
  }, [USER, history]);

  const handleInputChange = (value, name) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
    if (errors?.[name])
      setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrors(null);
    dispatch(login(fields))
      .then((res) => {
        const { errors, data } = res.data;
        setSubmitting(false);
        if (errors && errors[0]?.message) {
          setErrors(JSON.parse(errors[0]?.message));
          CreateToast({
            msg: "Kontrolli sisestatud välju.",
            type: "warn",
          });
        }
        if (data?.login) {
          const cookies = new Cookies();
          const token = data.login;
          cookies.set("token", token, {
            path: "/",
            expires: moment().add(14, "days").toDate(),
          });
          dispatch(getUser(token));
        }
      })
      .catch(() => {
        setSubmitting(false);
        CreateToast({
          msg: "Tekkis tundmatu viga, proovige uuesti.",
          type: "warn",
        });
      });
  };

  return (
    <>
      <Header />
      <Box
        background="#EFF7F9"
        minHeight={[
          "calc(100vh - 50px)",
          null,
          null,
          null,
          "calc(100vh - 120px)",
        ]}
        py={["2rem", "3rem", "4rem", "5rem"]}
      >
        <Box maxWidth="820px" margin="0 auto">
          <Box
            p="1rem"
            background="#FFF"
            padding={["1rem", null, null, "2rem", "3rem"]}
          >
            <Heading as="h1" color="#2E2E46" fontFamily="Lato-Black">
              Logi sisse
            </Heading>
            <Flex
              display="flex"
              flexDirection="column"
              width="100%"
              justifyContent="center"
              alignItems="center"
              my="1rem"
            >
              <Button version="secondary-hollow">
                Logi sisse Gmaili kaudu
              </Button>
              <Button version="secondary-hollow">
                Logi sisse Facebooki kaudu
              </Button>
            </Flex>
            <Box borderTop="1px solid #EFF7F9" py="0.5rem" my="0.5rem">
              <Form onSubmit={handleSubmit}>
                <Box maxWidth="340px" mx="auto">
                  <TextField
                    size="large"
                    placeholder="Sisesta e-mail"
                    label="Kasutaja"
                    name="email"
                    autoComplete="on"
                    value={fields?.email}
                    error={errors?.email}
                    onChange={handleInputChange}
                  />
                  <TextField
                    type="password"
                    size="large"
                    placeholder="Sisesta parool"
                    label="Parool"
                    name="password"
                    autoComplete="on"
                    value={fields?.password}
                    error={errors?.password}
                    onChange={handleInputChange}
                  />
                  <Box my="0.4rem">
                    <Link
                      to="/taasta-parool"
                      style={{
                        color: "#2E2E46",
                        textDecoration: "none",
                        fontFamily: "Lato-Regular",
                        fontSize: "12px",
                      }}
                    >
                      Unustasid parooli?
                    </Link>
                  </Box>
                  <Box my="2rem" mx="4rem">
                    <Button
                      loading={submitting}
                      type="submit"
                      version="primary"
                      icon={{
                        svg: enter,
                        position: "right",
                      }}
                    >
                      Sisene
                    </Button>
                  </Box>
                </Box>
              </Form>
            </Box>
          </Box>
          <Flex
            width="100%"
            py="3rem"
            background="#F9FBFF"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Link
              to="/registreeri"
              style={{
                textDecoration: "none",
              }}
            >
              <Box mx="auto">
                <Button version="primary-reverse">Loo konto</Button>
              </Box>
            </Link>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

const enter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.228"
    height="20.228"
    viewBox="0 0 20.228 20.228"
  >
    <g transform="translate(-3.375 -3.375)">
      <path
        d="M13.5,16.959h9.224L20.6,19.132l1.454,1.454,4.668-4.668L22.058,11.25,20.551,12.7l2.174,2.174H13.5Z"
        transform="translate(-3.123 -2.429)"
        fill="#fff"
      />
      <g transform="translate(3.375 3.375)">
        <path
          d="M13.474,21.527a8.038,8.038,0,0,1,0-16.075A7.967,7.967,0,0,1,19.139,7.8l1.473-1.473a10.747,10.747,0,0,0-1.347-1.128,10.122,10.122,0,1,0,1.342,15.458l-1.468-1.468A7.959,7.959,0,0,1,13.474,21.527Z"
          transform="translate(-3.375 -3.375)"
          fill="#fff"
        />
        <path
          d="M31.458,17.958h.058v.058h-.058Z"
          transform="translate(-12.037 -7.873)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default Login;
