import React, { useState } from "react";
import moment from "moment";
import "moment/locale/et";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { Wrapper, Label, Input, ErrorIcon, InputError } from "./styles";
moment.locale("et");

const Date = (props) => {
  const [focused, setFocused] = useState(false);
  return (
    <Wrapper size={props.size || "medium"}>
      {props.label && <Label>{props.label}</Label>}
      <Input invalid={props.error}>
        <SingleDatePicker
          date={props.value || null}
          onDateChange={(date) =>
            props.onChange(date ? moment(date) : date, props.name)
          }
          focused={focused}
          displayFormat="DD.MM.YYYY"
          placeholder={props.placeholder}
          numberOfMonths={1}
          customInputIcon={dateIcon}
          onFocusChange={({ focused }) => setFocused(focused)}
          id={props.id}
          firstDayOfWeek={1}
          disabled={props.disabled}
          isOutsideRange={props.isOutsideRange}
          withPortal={window.innerWidth < 992 ? true : false}
        />
        {props.error && <ErrorIcon>{errorIcon}</ErrorIcon>}
      </Input>
      {props.error && <InputError>{props.error.msg}</InputError>}
    </Wrapper>
  );
};

const errorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.375"
    height="19"
    viewBox="0 0 21.375 19"
  >
    <path
      d="M21.134,16.329A1.782,1.782,0,0,1,19.592,19H1.783A1.782,1.782,0,0,1,.24,16.329L9.145.89a1.782,1.782,0,0,1,3.086,0ZM10.687,13.137a1.707,1.707,0,1,0,1.707,1.707A1.707,1.707,0,0,0,10.687,13.137ZM9.067,7l.275,5.047a.445.445,0,0,0,.445.421h1.8a.445.445,0,0,0,.445-.421L12.308,7a.445.445,0,0,0-.445-.47H9.511A.445.445,0,0,0,9.067,7Z"
      fill="#dd2121"
    />
  </svg>
);

const dateIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.417"
    height="15.908"
    viewBox="0 0 14.417 15.908"
  >
    <g transform="translate(-4 -2.5)">
      <path
        d="M5.991,6H16.427a1.491,1.491,0,0,1,1.491,1.491V17.927a1.491,1.491,0,0,1-1.491,1.491H5.991A1.491,1.491,0,0,1,4.5,17.927V7.491A1.491,1.491,0,0,1,5.991,6Z"
        transform="translate(0 -1.509)"
        fill="none"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M24,3V5.982"
        transform="translate(-9.81)"
        fill="none"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M12,3V5.982"
        transform="translate(-3.773)"
        fill="none"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M4.5,15H17.917"
        transform="translate(0 -6.037)"
        fill="none"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Date;
