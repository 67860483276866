import React, { useState } from 'react';
import Box from '../UI/Layout/Box';
import Flex from '../UI/Layout/Flex';
import Heading from '../UI/Heading';
import Text from '../UI/Text';
import { Button } from '../UI/Button/styles';
import Modal from '../Modal';

const SingleOfferContent = ({ offer }) => {
  const [showPhone, setShowPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { price, location, phone, email } = offer;
  const {
    carBrand,
    model,
    motor,
    frameType,
    registered,
    mileage,
    gearbox,
    fuel,
    vin,
  } = offer?.vehicle;

  return (
    <>
      <Box width="100%">
        <Flex display="flex" flexDirection="column" width="100%">
          <Heading as="h1" pb="0.5rem" mt={[null, null, null, null, '1rem']}>
            {carBrand?.label} {model?.label} {motor?.mType}
          </Heading>
          <Flex display="inline-flex" width="100%">
            <Text
              as="span"
              color="#979797"
              fontFamily="Lato-Bold"
              pr="1rem"
              style={{
                textTransform: 'uppercase',
              }}
            >
              {frameType?.label}
            </Text>
            <Text
              as="span"
              color="#979797"
              fontFamily="Lato-Bold"
              style={{
                textTransform: 'uppercase',
              }}
            >
              {model?.label}
            </Text>
          </Flex>
          <Box as="ul" my="2rem">
            <Box as="li" py="0.25rem">
              <Flex display="inline-flex" width="100px">
                <Text as="span" fontFamily="Lato-Regular" color="#494949">
                  Aasta:
                </Text>
              </Flex>
              <Text as="span" fontFamily="Lato-Bold">
                {registered?.year?.label}
              </Text>
            </Box>
            <Box as="li" py="0.25rem">
              <Flex display="inline-flex" width="100px">
                <Text as="span" fontFamily="Lato-Regular" color="#494949">
                  Läbisõit:
                </Text>
              </Flex>
              <Text as="span" fontFamily="Lato-Bold">
                {mileage}km
              </Text>
            </Box>
            <Box as="li" py="0.25rem">
              <Flex display="inline-flex" width="100px">
                <Text as="span" fontFamily="Lato-Regular" color="#494949">
                  Mootor:
                </Text>
              </Flex>
              <Text as="span" fontFamily="Lato-Bold">
                {motor?.mType} {motor?.kw && `(${motor?.kw} kW)`}
              </Text>
            </Box>
            <Box as="li" py="0.25rem">
              <Flex display="inline-flex" width="100px">
                <Text as="span" fontFamily="Lato-Regular" color="#494949">
                  Käigukast:
                </Text>
              </Flex>
              <Text as="span" fontFamily="Lato-Bold">
                {gearbox?.label}
              </Text>
            </Box>
            <Box as="li" py="0.25rem">
              <Flex display="inline-flex" width="100px">
                <Text as="span" fontFamily="Lato-Regular" color="#494949">
                  Kütus:
                </Text>
              </Flex>
              <Text as="span" fontFamily="Lato-Bold">
                {fuel?.sort?.label}
              </Text>
            </Box>
            <Box as="li" py="0.25rem">
              <Flex display="inline-flex" width="100px">
                <Text as="span" fontFamily="Lato-Regular" color="#494949">
                  Keretüüp:
                </Text>
              </Flex>
              <Text as="span" fontFamily="Lato-Bold">
                {frameType?.label}
              </Text>
            </Box>
            <Box as="li" py="0.25rem">
              <Flex display="inline-flex" width="100px">
                <Text as="span" fontFamily="Lato-Regular" color="#494949">
                  VIN:
                </Text>
              </Flex>
              <Text as="span" fontFamily="Lato-Bold">
                {vin}
              </Text>
            </Box>
          </Box>
          <Box mb="2rem">
            <Box mb="0.25rem">
              <Text as="p" fontFamily="Lato-Regular" color="#494949">
                Hind:
              </Text>
            </Box>
            {/*             <Heading
              as="h5"
              color="#A50000"
              lineHeight="1.5"
              style={{
                textDecoration: 'line-through',
              }}
            >
              {discount}.-
            </Heading> */}
            <Heading as="h3" color="#494949" lineHeight="1.4">
              {price}.-
            </Heading>
          </Box>
          <Box
            my="1rem"
            p="1rem"
            borderRadius="4px"
            background="#F9FBFF"
            maxWidth="750px"
          >
            <Flex
              display={['flex', null, 'inline-flex']}
              flexDirection={['column', 'row']}
              justifyContent="space-between"
              width="100%"
            >
              <Flex display="inline-flex" mr="1rem">
                <Box width="100px" mb={['0.35rem', 0]}>
                  <Text as="p" color="#494949" fontFamily="Lato-Regular">
                    Asukoht:
                  </Text>
                </Box>
                <Text as="span" fontFamily="Lato-Bold">
                  {location?.label}
                </Text>
              </Flex>
              <Flex
                display="flex"
                flexDirection="column"
                mr={[null, '2rem', '3rem', 0, null, '4rem']}
              >
                <Flex display={[null, 'inline-flex']}>
                  <Box width={[null, '100px']} my={['0.35rem', 0]}>
                    <Text as="p" color="#494949" fontFamily="Lato-Regular">
                      Telefon:
                    </Text>
                  </Box>
                  {showPhone ? (
                    <a
                      href={`tel:${phone}`}
                      style={{
                        textDecoration: 'none',
                        color: '#000',
                        fontFamily: 'Lato-Bold',
                        lineHeight: '1.3',
                      }}
                    >
                      {phone}
                    </a>
                  ) : (
                    <Box
                      as="button"
                      type="button"
                      onClick={() => setShowPhone(!showPhone)}
                      background="none"
                      padding="0"
                      border="none"
                    >
                      <Text as="span" fontFamily="Lato-Bold" lineHeight="1.3">
                        Näita numbrit
                      </Text>
                    </Box>
                  )}
                </Flex>
                <Flex display={[null, 'inline-flex']}>
                  <Box width={[null, '100px']} my={['0.35rem', 0]}>
                    <Text as="p" color="#494949" fontFamily="Lato-Regular">
                      E-Mail:
                    </Text>
                  </Box>
                  {showEmail ? (
                    <a
                      href={`mailto:${email}`}
                      style={{
                        textDecoration: 'none',
                        color: '#000',
                        fontFamily: 'Lato-Bold',
                        lineHeight: '1.3',
                      }}
                    >
                      {email}
                    </a>
                  ) : (
                    <Box
                      as="button"
                      type="button"
                      onClick={() => setShowEmail(!showEmail)}
                      background="none"
                      padding="0"
                      border="none"
                    >
                      <Text as="span" fontFamily="Lato-Bold" lineHeight="1.3">
                        Näita E-maili
                      </Text>
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Box my="1.5rem">
              <Text
                as="p"
                textAlign="center"
                fontFamily="Lato-Bold"
                lineHeight="1.5"
              >
                Saada müüjale teade enda ostusoovist läbi Auto1000 keskkonna
              </Text>
            </Box>
            <Flex my="1rem" maxWidth="250px" mx="auto">
              <Button
                version="primary"
                onClick={() => setShowModal(!showModal)}
              >
                Saada päring
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
      {showModal && (
        <Modal onClose={() => setShowModal(!showModal)} size="smal">
          <Heading as="h2">Saada päring</Heading>
        </Modal>
      )}
    </>
  );
};

export default SingleOfferContent;
