import styled from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  compose,
} from "styled-system";

export const Columns = styled.div`
  ${compose(
    space,
    color,
    layout,
    flexbox,
    background,
    border,
    position,
    shadow
  )}
`;

export const Column = styled.div`
  ${compose(
    space,
    color,
    layout,
    flexbox,
    background,
    border,
    position,
    shadow
  )}
`;
