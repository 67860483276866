import styled, { css } from "styled-components";

export const Wrapper = styled.header`
  width: 100%;
  background: #f2fbfe;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
`;

export const WrapperInner = styled.nav`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  height: 50px;
  @media (min-width: 1200px) {
    height: 120px;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  @media (min-width: 1440px) {
    max-width: 1370px;
  }
  @media (min-width: 1740px) {
    max-width: 1640px;
  }
`;

export const Burger = styled.button`
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1200px) {
    display: none;
  }
`;
export const Logo = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -40%);
  z-index: 100;
  a {
    padding: 1rem;
  }
  @media (min-width: 1200px) {
    position: relative;
    transform: none;
    left: unset;
    top: unset;
    a {
      padding: 1.5rem 1rem;
    }
    transform: translateY(30%);
    svg {
      width: 115px;
      height: 50px;
    }
  }
`;
export const NavItems = styled.ul`
  position: fixed;
  height: calc(100vh - 50px);
  top: 50px;
  left: 0;
  width: 280px;
  background: #eff7f9;
  z-index: 99;
  padding: 1.5rem 0;
  transform: translateX(-100%);
  transition: 0.15s transform ease-in;
  li a {
    padding: 1rem;
    display: flex;
    text-decoration: none;
    color: #3b5588;
    font-family: "Lato-Bold";
    &:hover {
      background: #eff7f9;
    }
    &.active {
      color: #1774ed;
    }
  }
  ${({ open }) =>
    open &&
    css`
      @media (max-width: 1200px) {
        transform: translateX(0%);
        transition: 0.15s transform ease-in-out;
      }
    `}
  @media (min-width: 1200px) {
    position: relative;
    background: none;
    height: auto;
    display: inline-flex;
    width: 100%;
    transform: none;
    top: unset;
    padding: 0;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-left: 1rem;
  }
  @media (min-width: 1440px) {
    margin-left: 3rem;
  }
  @media (min-width: 1740px) {
    margin-left: 5rem;
  }
`;
export const User = styled.div`
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: "Lato-Bold";
    color: #3b5588;
    white-space: nowrap;
  }
  svg {
    width: 25px;
    height: 25px;
    margin-bottom: 0.4rem;
  }
  @media (min-width: 922px) {
    margin-left: 1.5rem;
    display: inline-flex;
  }
`;
export const Spacing = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  @media (min-width: 1200px) {
    height: 120px;
  }
`;
export const Close = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  background: rgba(0, 0, 0, 0.3);
  transform: translateX(-100%);
  z-index: 100;
  ${({ open }) =>
    open &&
    css`
      @media (max-width: 1200px) {
        transform: translateX(0%);
      }
    `}
  @media (min-width: 1200px) {
    display: none;
  }
`;
