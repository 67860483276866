import React from 'react';
import Flex from '../UI/Layout/Flex';
import Heading from '../UI/Heading';
import Text from '../UI/Text';
import { Link } from 'react-router-dom';
import Box from '../UI/Layout/Box';

const Footer = () => {
  return (
    <Flex
      as="footer"
      background="#414141"
      padding={['2rem 1rem', null, null, '4rem 1rem', '4rem 1rem 8rem 1rem']}
    >
      <Box px="1rem" maxWidth="700px" margin="0 auto">
        <Heading as="h2" color="#FFF">
          Kontaktinfo
        </Heading>
        <Flex
          as="nav"
          display={['flex', 'inline-flex']}
          width="100%"
          flexDirection={['column', 'row']}
          m={[0, '1rem 0', '1.5rem 0']}
        >
          <Flex
            my={['1.5rem', 0]}
            pt={[0, '0.4rem']}
            display="flex"
            flexDirection="column"
            justifyContent={[null, 'space-between']}
            width="100%"
          >
            <Text as="p" color="#FFF" fontFamily="Lato-Regular" mb="0.5rem">
              Kliendiabi argipäevati 9:00-17:00
            </Text>
            <a
              href="tel:+37212345678"
              style={{
                fontFamily: 'Lato-Regular',
                color: '#FFF',
                padding: '0.4rem 0',
                textDecoration: 'none',
              }}
            >
              +372 12345678
            </a>
            <a
              href="mailto:info@auto1000.ee"
              style={{
                fontFamily: 'Lato-Regular',
                color: '#FFF',
                padding: '0.4rem 0',
                textDecoration: 'none',
              }}
            >
              info@auto1000.ee
            </a>
          </Flex>
          <Flex my="2.5rem 1rem" display="flex" flexDirection="column">
            <Link
              to="/kasutustingimused"
              style={{
                color: '#FFF',
                fontFamily: 'Lato-Regular',
                padding: '0.4rem 0',
                textDecoration: 'none',
              }}
            >
              Kasutustingimused
            </Link>
            <Link
              to="/privaatsuspoliitika"
              style={{
                color: '#FFF',
                fontFamily: 'Lato-Regular',
                padding: '0.4rem 0',
                textDecoration: 'none',
              }}
            >
              Privaatsuspoliitika
            </Link>
            <Link
              to="/reklaam"
              style={{
                color: '#FFF',
                fontFamily: 'Lato-Regular',
                padding: '0.4rem 0',
                textDecoration: 'none',
              }}
            >
              Reklaam
            </Link>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Footer;
