import React from 'react';

import Box from '../UI/Layout/Box';
import Heading from '../UI/Heading';
import Form from '../Form';
import Select from '../Form/Select';
import TextField from '../Form/TextField';
import Columns from '../UI/Layout/Column/Columns';
import Column from '../UI/Layout/Column';
import Button from '../UI/Button';
import frameOptions from '../../data/frameType.json';
import carBrandOptions from '../../data/carBrands.json';
import fuelOptions from '../../data/fuelOptions.json';
import gearboxOptions from '../../data/gearboxOptions.json';
import { SearchContainer, SearchClose, SearchCloseIcon } from './styles';
import Flex from '../UI/Layout/Flex';
import Icon from '../UI/Icon';

const frameTypes = frameOptions.frameTypes;
const carBrands = carBrandOptions.carBrands;
const fuelTypes = fuelOptions.fuelTypes;
const gearboxTypes = gearboxOptions.gearboxTypes;

const Search = (props) => {
  const { open, setOpen, fields, setFields } = props;

  const handleInputChange = (value, name) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (value, action) => {
    setFields((prevState) => ({ ...prevState, [action.name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <SearchContainer open={open}>
        <SearchCloseIcon onClick={() => setOpen(!open)}>
          {close}
        </SearchCloseIcon>
        <Box
          as="aside"
          background="#F9FBFF"
          padding="1rem"
          borderRadius={[null, null, null, '0 0 5px 5px']}
        >
          <Flex
            display="inline-flex"
            width="100%"
            alignItems="center"
            mb="1rem"
            mt={[null, null, null, '.5rem']}
          >
            <Icon mr="1rem" width={['18px', '20px', '22px', '24px']}>
              {search}
            </Icon>
            <Heading as="h4">Otsing</Heading>
          </Flex>
          <Form onSubmit={handleSubmit}>
            <Select
              label="Keretüüp"
              placeholder="Vali"
              name="frameType"
              isMulti
              value={fields?.frameType}
              onChange={handleSelectChange}
              options={frameTypes}
            />
            <Select
              label="Mark"
              placeholder="Vali"
              name="carBrand"
              isMulti
              value={fields?.carBrand}
              onChange={handleSelectChange}
              options={carBrands}
            />
            <Select
              label="Mudel"
              placeholder="Vali"
              name="model"
              isMulti
              value={fields?.model}
              onChange={handleSelectChange}
              options={frameTypes}
            />
            <Columns display="inline-flex" alignItems="flex-end">
              <Column mr="12px">
                <TextField
                  size="xsmall"
                  label="Aasta"
                  placeholder="Alates"
                  name="yearFrom"
                  value={fields?.yearFrom}
                  onChange={handleInputChange}
                />
              </Column>
              <Column>
                <TextField
                  size="xsmall"
                  placeholder="Kuni"
                  name="yearTo"
                  value={fields?.yearTo}
                  onChange={handleInputChange}
                />
              </Column>
            </Columns>
            <Columns display="inline-flex" alignItems="flex-end">
              <Column mr="12px">
                <TextField
                  size="xsmall"
                  label="Hind"
                  placeholder="Alates"
                  name="priceFrom"
                  value={fields?.priceFrom}
                  onChange={handleInputChange}
                  icon={{
                    position: 'left',
                    svg: euro,
                  }}
                />
              </Column>
              <Column>
                <TextField
                  size="xsmall"
                  placeholder="Kuni"
                  name="priceTo"
                  value={fields?.priceTo}
                  onChange={handleInputChange}
                  icon={{
                    position: 'left',
                    svg: euro,
                  }}
                />
              </Column>
            </Columns>
            <Columns display="inline-flex" alignItems="flex-end">
              <Column mr="12px">
                <TextField
                  size="xsmall"
                  label="Läbisõit"
                  placeholder="Alates"
                  name="mileageFrom"
                  value={fields?.mileageFrom}
                  onChange={handleInputChange}
                />
              </Column>
              <Column>
                <TextField
                  size="xsmall"
                  placeholder="Kuni"
                  name="mileageTo"
                  value={fields?.mileageTo}
                  onChange={handleInputChange}
                />
              </Column>
            </Columns>
            <Select
              label="Kütus"
              placeholder="Vali"
              name="fuel"
              isMulti
              value={fields?.fuel}
              onChange={handleSelectChange}
              options={fuelTypes}
            />
            <Select
              label="Käigukast"
              placeholder="Vali"
              name="gearbox"
              isMulti
              value={fields?.gearbox}
              onChange={handleSelectChange}
              options={gearboxTypes}
            />
            <Columns display="inline-flex" alignItems="flex-end">
              <Column mr="12px">
                <TextField
                  size="xsmall"
                  label="Võimsus (kWh)"
                  placeholder="Alates"
                  name="capacityFrom"
                  value={fields?.capacityFrom}
                  onChange={handleInputChange}
                />
              </Column>
              <Column>
                <TextField
                  size="xsmall"
                  placeholder="Kuni"
                  name="capacityTo"
                  value={fields?.capacityTo}
                  onChange={handleInputChange}
                />
              </Column>
            </Columns>
            <Box margin={['1.5rem 1rem', '2rem 1rem']}>
              <Button version="secondary" type="submit">
                Otsi
              </Button>
            </Box>
          </Form>
        </Box>
      </SearchContainer>
      <SearchClose open={open} onClick={() => setOpen(!open)} />
    </>
  );
};

const euro = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.728"
    height="10.234"
    viewBox="0 0 7.728 10.234"
  >
    <path
      d="M.238-6.293H1.267A5.936,5.936,0,0,1,1.74-7.885,4.3,4.3,0,0,1,2.566-9.1,3.412,3.412,0,0,1,3.7-9.863a3.631,3.631,0,0,1,1.4-.266,3.4,3.4,0,0,1,1.6.35,3.623,3.623,0,0,1,1.155.966l-.427.476a.967.967,0,0,1-.112.109.238.238,0,0,1-.154.045.362.362,0,0,1-.248-.133,2.907,2.907,0,0,0-.346-.294A2.413,2.413,0,0,0,6.017-8.9a2.4,2.4,0,0,0-.865-.133,2.26,2.26,0,0,0-1.7.7,3.762,3.762,0,0,0-.9,2.044H6.384v.385a.326.326,0,0,1-.095.228.341.341,0,0,1-.262.1H2.471q-.007.14-.011.28t0,.287V-4.8q0,.105.007.21H5.733v.392a.313.313,0,0,1-.1.22.353.353,0,0,1-.266.1H2.527a3.872,3.872,0,0,0,.868,2.163,2.217,2.217,0,0,0,1.694.721,2.7,2.7,0,0,0,.665-.074,2.235,2.235,0,0,0,.5-.186,1.977,1.977,0,0,0,.36-.242q.15-.13.263-.242t.2-.186a.269.269,0,0,1,.172-.073.191.191,0,0,1,.1.024.664.664,0,0,1,.1.074l.525.49A3.479,3.479,0,0,1,6.748-.291a3.571,3.571,0,0,1-1.729.4A3.708,3.708,0,0,1,3.556-.172,3.246,3.246,0,0,1,2.429-.963a4.156,4.156,0,0,1-.78-1.253,6.349,6.349,0,0,1-.416-1.656H.238v-.714h.938q-.007-.105-.007-.21t0-.217q0-.14,0-.284t.011-.284H.238Z"
      transform="translate(-0.238 10.129)"
      fill="#494949"
    />
  </svg>
);

const close = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.158"
    height="16.158"
    viewBox="0 0 16.158 16.158"
  >
    <path
      d="M23.658,9.127,22.031,7.5l-6.452,6.452L9.127,7.5,7.5,9.127l6.452,6.452L7.5,22.031l1.627,1.627,6.452-6.452,6.452,6.452,1.627-1.627-6.452-6.452Z"
      transform="translate(-7.5 -7.5)"
      fill="#bdbdbd"
    />
  </svg>
);

const search = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.235"
    height="26.235"
    viewBox="0 0 26.235 26.235"
  >
    <path
      d="M23.25,21H22.065l-.42-.405a9.765,9.765,0,1,0-1.05,1.05l.405.42V23.25l7.5,7.485L30.735,28.5Zm-9,0A6.75,6.75,0,1,1,21,14.25,6.741,6.741,0,0,1,14.25,21Z"
      transform="translate(-4.5 -4.5)"
      fill="#1bc744"
    />
  </svg>
);

export default Search;
