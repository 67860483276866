import React from "react";

import Header from "../../components/Header";
import Settings from "../../components/Account/Settings";

const SettingsPage = ({ USER }) => {
  return (
    <>
      <Header />
      <Settings USER={USER} />
    </>
  );
};

export default SettingsPage;
