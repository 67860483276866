import React from "react";

import Account from "..";
import Heading from "../../UI/Heading";
import List from "./List";

const Inquiries = () => {
  return (
    <Account>
      <Heading as="h3" mt="-4px" color="#2E2E46">
        Päringud
      </Heading>
      <List inquiries={inquiries} />
    </Account>
  );
};

const inquiries = [
  {
    id: 123,
    offerId: 1,
    name: "Vokswagen Golf VARIANT",
    phone: "56565656",
    email: "asd.asd.com",
    content:
      "<p>Norm pill sul. Ilgelt tahaks ära osta. Millal <strong>põllupäälee proovima saab minna</strong>. Mul laupäev möll, siis ei saa. Reedel äkki? <br /> Kuueni olen tööl ka, ära siis hella.  A pärast võid küll tirtsata. <br /> Davai Naisi ja õlut</p>",
  },
  {
    id: 123,
    offerId: 2,
    name: "Vokswagen Golf VARIANT",
    phone: "56565656",
    email: "asd.asd.com",
    content:
      "<p>Norm pill sul. Ilgelt tahaks ära osta. Millal <strong>põllupäälee proovima saab minna</strong>. Mul laupäev möll, siis ei saa. Reedel äkki? <br /> Kuueni olen tööl ka, ära siis hella.  A pärast võid küll tirtsata. <br /> Davai Naisi ja õlut</p>",
  },
];

export default Inquiries;
