import React from "react";
import { Link as LinkWrapper } from "react-router-dom";
import { Wrapper } from "./styles";

const Link = (props) => (
  <Wrapper {...props}>
    <LinkWrapper to={props.to || "/"} state={props.state}>
      {props.children}
    </LinkWrapper>
  </Wrapper>
);

export default Link;
