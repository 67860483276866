import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Account from '..';
import Heading from '../../UI/Heading';
import Nav from './Nav';
import List from './List';
import { getUserOffers, deleteOffer } from '../../../store/actions/offer';

const Offers = () => {
  const [offers, setOffers] = useState(null);
  const [filterOptions, setFilterOptions] = useState(null);
  const [filteredOffers, setFilteredOffers] = useState(null);
  const [modal, setModal] = useState(null);
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const USER = useSelector((state) => state.user?.data);

  useEffect(() => {
    if (!offers && USER) {
      dispatch(getUserOffers(USER._id)).then((res) => {
        const {
          data: { userOffers },
        } = res.data;
        setOffers(userOffers);
        const incomplete = userOffers?.filter((offer) => offer.status === 0)
          ?.length;
        const active = userOffers?.filter((offer) => offer.active)?.length;
        const inactive = userOffers?.filter((offer) => !offer.active)?.length;
        const sold = userOffers?.filter((offer) => offer.sold)?.length;
        setFilterOptions([
          {
            tab: 0,
            name: 'Pooleli',
            count: incomplete,
          },
          {
            tab: 1,
            name: 'Aktiivsed',
            count: active,
          },
          {
            tab: 2,
            name: 'Mitteaktiivsed',
            count: inactive,
          },
          {
            tab: 3,
            name: 'Müüdud',
            count: sold,
          },
        ]);
      });
    }
  }, [offers, USER, dispatch]);

  useEffect(() => {
    if (offers && tab >= 0) {
      if (tab === 0) {
        const filtered = offers.filter(
          (offer) => offer.status === 0 && !offer.active
        );
        setFilteredOffers(filtered);
      } else if (tab === 1) {
        const filtered = offers.filter(
          (offer) => offer.status === 1 && offer.active
        );
        setFilteredOffers(filtered);
      } else if (tab === 2) {
        const filtered = offers.filter((offer) => !offer.active);
        setFilteredOffers(filtered);
      } else if (tab === 3) {
        const filtered = offers.filter((offer) => offer.sold);
        setFilteredOffers(filtered);
      }
    }
  }, [offers, tab]);

  const handleOfferSold = (id) => {
    // TODO: SHOW ERROR
    if (!id) return;
  };
  const handleOfferDelete = (id) => {
    // TODO: SHOW ERROR
    if (!id) return;
    setModal(null);
    dispatch(deleteOffer(id)).then((res) => {
      const { data } = res.data;
      const parsed = JSON.parse(data?.deleteOffer);
      // TODO: SHOW ERROR
      if (!parsed.success) return;
      const _offers = offers;
      const newOffers = _offers.filter((offer) => offer._id !== id);
      setOffers(newOffers);
    });
    // TODO: DO CATCH
  };

  if (!offers && !filteredOffers) return <p>Laadimine...</p>;

  return (
    <Account>
      <Heading as="h3" mt="-4px" color="#2E2E46">
        Sinu kuulutused
      </Heading>
      {filterOptions && <Nav tab={tab} setTab={setTab} btns={filterOptions} />}
      <List
        tab={tab}
        offers={filteredOffers}
        modal={modal}
        setModal={setModal}
        handleOfferSold={handleOfferSold}
        handleOfferDelete={handleOfferDelete}
      />
    </Account>
  );
};

export default Offers;
