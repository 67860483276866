import moment from 'moment';

export default function formatEditCreator(fields) {
  const { _id, name, phone, email, price, location, images } = fields;
  const {
    regCode,
    vin,
    frameType,
    carBrand,
    model,
    modelExtra,
    registered,
    color,
    mileage,
    axle,
    motor,
    mass,
    haagis,
    rattavalem,
    sillad,
    gearbox,
    fuel,
    acceleration,
    seats,
    doors,
    quarantee,
    hooldusraamat,
    ulevaatus,
  } = fields?.vehicle;
  return {
    _id,
    name,
    phone,
    email,
    price,
    location,
    images,
    regCode,
    vin,
    frameType,
    carBrand,
    model,
    modelExtra,
    regMonth: registered?.month,
    regYear: registered?.year,
    color,
    mileage,
    axle,
    motorL: motor?.litres,
    motorCubic: motor?.cubic,
    motorType: motor?.mType,
    motorKw: motor?.kw,
    emptyMass: mass?.empty,
    fullMass: mass?.full,
    kandevoime: mass?.kandevoime,
    pidurHaagis: haagis?.piduriga,
    piduritaHaagis: haagis?.pidurita,
    length: haagis?.length,
    width: haagis?.width,
    height: haagis?.height,
    rattavalem,
    sillad,
    gearbox,
    fuel: fuel?.sort,
    fuelMax: fuel?.max,
    fuelCity: fuel?.city,
    fuelRoad: fuel?.road,
    fuelAverage: fuel?.average,
    acceleration: acceleration?.speed,
    maxSpeed: acceleration?.max,
    seats,
    doors,
    quarantee: quarantee?.applicable,
    quaranteeDate: moment.unix(quarantee?.date / 1000),
    hooldusraamat,
    ulevaatus: ulevaatus?.applicable,
    ulevaatusDate: moment.unix(ulevaatus?.date / 1000),
  };
}
