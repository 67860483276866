import React from "react";
import Flex from "../../../UI/Layout/Flex";
import Text from "../../../UI/Text";
import Heading from "../../../UI/Heading";
import { HideMobile } from "../../../UI/GlobalStyle";
import Box from "../../../UI/Layout/Box";
import Icon from "../../../UI/Icon";

const Nav = ({ tab, setTab, completed }) => {
  return (
    <>
      <Flex
        as="button"
        background="none"
        border="none"
        display={["flex", null, null, "inline-flex"]}
        flexDirection={["column", null, null, "row"]}
        alignItems="center"
        justifyContent="center"
        my="1rem"
        padding="0"
        onClick={() => tab !== 0 && setTab(0)}
      >
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={["32px", null, null, "42px"]}
          height={["32px", null, null, "42px"]}
          bg={completed >= 0 ? "#1BC744" : tab === 0 ? "#FFF" : "#EFF7F9"}
          border={tab === 0 ? "2px solid #1774ED" : "2px solid transparent"}
          borderRadius="50%"
          mb={["0.5rem", null, null, "0"]}
          mr={[null, null, null, "1rem"]}
        >
          <Text
            as="span"
            fontSize={["15px", null, "16px", "18px", "22px"]}
            fontFamily="Lato-Regular"
            color={completed >= 0 && "#FFF"}
          >
            1
          </Text>
        </Flex>
        <Heading
          as="h5"
          color={completed >= 0 ? "#000" : tab === 0 ? "#000" : "#B3B3B3"}
          fontFamily={
            completed >= 0
              ? "Lato-Bold"
              : tab === 0
              ? "Lato-Bold"
              : "Lato-Regular"
          }
        >
          Müügi info
        </Heading>
      </Flex>
      <Flex
        display={["flex", null, null, "inline-flex"]}
        flexDirection={["column", null, null, "row"]}
        alignItems={["center", null, null, null, "flex-start"]}
        justifyContent="center"
        my="1rem"
        padding="0"
      >
        <Flex
          as="button"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={["32px", null, null, "42px"]}
          height={["32px", null, null, "42px"]}
          bg={
            completed >= 2
              ? "#1BC744"
              : tab === 1 || tab === 2
              ? "#FFF"
              : "#EFF7F9"
          }
          border={
            tab === 1 || tab === 2
              ? "2px solid #1774ED"
              : "2px solid transparent"
          }
          borderRadius="50%"
          mb={["0.5rem", null, null, "0"]}
          mr={[null, null, null, "1rem"]}
          onClick={() => tab !== 1 && setTab(1)}
        >
          <Text
            as="span"
            fontSize={["15px", null, "16px", "18px", "22px"]}
            fontFamily="Lato-Regular"
            color={completed >= 2 && "#FFF"}
          >
            2
          </Text>
        </Flex>
        <Flex
          display="flex"
          flexDirection="column"
          alignItems={["center", null, null, "flex-start"]}
        >
          <Box
            as="button"
            background="none"
            p="0"
            border="none"
            onClick={() => tab !== 1 && completed >= 1 && setTab(1)}
          >
            <Heading
              as="h5"
              color={
                completed >= 2
                  ? "#000"
                  : tab === 1 || tab === 2
                  ? "#000"
                  : "#B3B3B3"
              }
              fontFamily={
                completed >= 2
                  ? "Lato-Bold"
                  : tab === 1 || tab === 2
                  ? "Lato-Bold"
                  : "Lato-Regular"
              }
            >
              Sõiduki andmed
            </Heading>
          </Box>
          <HideMobile>
            <Flex
              position="relative"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mt="0.35rem"
            >
              {(tab === 1 || tab === 2) && (
                <Box
                  position="absolute"
                  top={tab === 1 ? "5px" : "30px"}
                  left="-20px"
                >
                  <Icon>{arrowRight}</Icon>
                </Box>
              )}
              <Box
                as="button"
                background="none"
                p="0"
                border="none"
                onClick={() => tab !== 1 && completed >= 1 && setTab(1)}
              >
                <Text
                  as="span"
                  color={
                    completed >= 1 ? "#000" : tab === 1 ? "#000" : "#B3B3B3"
                  }
                  fontFamily={
                    completed >= 1
                      ? "Lato-Bold"
                      : tab === 1
                      ? "Lato-Bold"
                      : "Lato-Regular"
                  }
                  lineHeight="1.6"
                >
                  1.1 Üldandmed
                </Text>
              </Box>
              <Box
                as="button"
                background="none"
                p="0"
                border="none"
                onClick={() => tab !== 2 && completed >= 2 && setTab(2)}
              >
                <Text
                  as="span"
                  color={
                    completed >= 2 ? "#000" : tab === 2 ? "#000" : "#B3B3B3"
                  }
                  fontFamily={
                    completed >= 2
                      ? "Lato-Bold"
                      : tab === 2
                      ? "Lato-Bold"
                      : "Lato-Regular"
                  }
                  lineHeight="1.6"
                >
                  1.2 Tehnilised andmed
                </Text>
              </Box>
            </Flex>
          </HideMobile>
        </Flex>
      </Flex>
      <Flex
        as="button"
        background="none"
        border="none"
        display={["flex", null, null, "inline-flex"]}
        flexDirection={["column", null, null, "row"]}
        alignItems="center"
        justifyContent="center"
        my="1rem"
        padding="0"
        onClick={() => tab !== 3 && completed >= 3 && setTab(3)}
      >
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={["32px", null, null, "42px"]}
          height={["32px", null, null, "42px"]}
          bg={completed >= 3 ? "#1BC744" : tab === 3 ? "#FFF" : "#EFF7F9"}
          border={tab === 3 ? "2px solid #1774ED" : "2px solid transparent"}
          borderRadius="50%"
          mb={["0.5rem", null, null, "0"]}
          mr={[null, null, null, "1rem"]}
        >
          <Text
            as="span"
            fontSize={["15px", null, "16px", "18px", "22px"]}
            fontFamily="Lato-Regular"
            color={completed >= 3 && "#FFF"}
          >
            3
          </Text>
        </Flex>
        <Heading
          as="h5"
          color={completed >= 3 ? "#000" : tab === 3 ? "#000" : "#B3B3B3"}
          fontFamily={
            completed >= 3
              ? "Lato-Bold"
              : tab === 3
              ? "Lato-Bold"
              : "Lato-Regular"
          }
        >
          Varustus ja lisainfo
        </Heading>
      </Flex>
    </>
  );
};

const arrowRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.39"
    height="15"
    viewBox="0 0 15.39 15"
  >
    <path
      d="M8.843,16.643l-.763.763a.821.821,0,0,1-1.164,0L.239,10.731a.821.821,0,0,1,0-1.164L6.916,2.889a.821.821,0,0,1,1.164,0l.763.763A.825.825,0,0,1,8.829,4.83L4.69,8.773h9.872a.822.822,0,0,1,.824.824v1.1a.822.822,0,0,1-.824.824H4.69l4.139,3.943A.819.819,0,0,1,8.843,16.643Z"
      transform="translate(15.386 17.647) rotate(180)"
      fill="#1774ed"
    />
  </svg>
);

export default Nav;
