import React from "react";

import Header from "../../components/Header";
import Inquiries from "../../components/Account/Inquiries";

const InquiriesPage = () => {
  return (
    <>
      <Header />
      <Inquiries />
    </>
  );
};

export default InquiriesPage;
