import axios from "axios";
import { API_URL } from "../../constants";
import { CreateToast } from "../../components/helpers/CreateToast";

export const create = (fields) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    password,
    "repeat-password": repeatPassword,
    privacy,
    terms,
    newsletter,
  } = fields || {};

  return () => {
    return axios({
      url: API_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
          mutation ($firstName: String!, $lastName: String!, $email: String!, $phone: String!, $password: String!, $repeatPassword: String!, $privacy: Boolean!, $terms: Boolean!, $newsletter: Boolean) {
            createUser(input: {firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, password: $password, repeatPassword: $repeatPassword, privacy: $privacy, terms: $terms, newsletter: $newsletter})
          }
        `,
        variables: {
          firstName: firstName || "",
          lastName: lastName || "",
          email: email || "",
          phone: phone || "",
          password: password || "",
          repeatPassword: repeatPassword || "",
          privacy: privacy || false,
          terms: terms || false,
          newsletter: newsletter || false,
        },
      },
    });
  };
};

export const login = (fields) => {
  const { email, password } = fields || {};
  return () => {
    return axios({
      url: API_URL,
      method: "POST",
      data: {
        query: `
          mutation ($email: String!, $password: String!) {
            login(input: {email: $email, password: $password})
          }
          `,
        variables: {
          email: email || "",
          password: password || "",
        },
      },
    });
  };
};

export const getUser = (token) => {
  return (dispatch) => {
    axios({
      url: API_URL,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        query: `
            query {
                me {
                  _id
                  firstName
                  lastName
                  email
                  phone
                  newsletter
                }
            }
        `,
      },
    })
      .then((res) => {
        const { data } = res.data;
        const user = data?.me;
        if (!user) {
          CreateToast({
            msg: "Kasutajakontot ei suudetud tuvastada.",
            type: "warn",
          });
          return;
        }
        dispatch({
          type: "SET_USER",
          payload: user,
        });
      })
      .catch(() =>
        CreateToast({
          msg: "Tekkis tundmatu viga, proovige uuesti.",
          type: "warn",
        })
      );
  };
};

export const updateUser = (fields, token) => {
  return () => {
    const {
      _id,
      firstName,
      lastName,
      phone,
      email,
      password,
      "repeat-password": repeatPassword,
      newsletter,
    } = fields || {};
    return axios({
      url: API_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        query: `
          mutation($_id: ID!, $firstName: String!, $lastName: String!, $email: String!, $phone: String, $newsletter: Boolean, $password: String, $repeatPassword: String) {
            editUser(input: {_id: $_id, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, newsletter: $newsletter, password: $password, repeatPassword: $repeatPassword})
          }
        `,
        variables: {
          _id: _id || "",
          firstName: firstName || "",
          lastName: lastName || "",
          phone: phone || "",
          email: email || "",
          password: password || "",
          repeatPassword: repeatPassword || "",
          newsletter: newsletter || false,
        },
      },
    });
  };
};
