import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { getOffer } from '../../store/actions/offer';

import Box from '../UI/Layout/Box';
import Flex from '../UI/Layout/Flex';
import SingleOfferImages from './SingleOfferImages';
import SingleOfferContent from './SingleOfferContent';
import SingleOfferAdditional from './SingleOfferAdditional';
import { HideMobile } from '../UI/GlobalStyle';
import Banner from '../Banner';
import Image from '../UI/Image';

const SingleOffer = (/* { offer } */) => {
  const [offer, setOffer] = useState(null);
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { id } = match.params;
  useEffect(() => {
    if (id) {
      dispatch(getOffer(id)).then((res) => {
        const { offer } = res.data.data;
        // TODO: SHOW ERROR
        if (!offer) {
        }
        // TODO: SHOW SOLD
        if (offer?.sold) {
        }
        setOffer(offer);
      });
    }
  }, [id, dispatch]);

  // TODO: SHOW PLACEHOLDER
  if (!offer) return null;

  //const { images } = offer;
  return (
    <>
      <Box
        position="absolute"
        top="0"
        left="0"
        height="100vh"
        width="100%"
        zIndex="-1"
        background="#EFF7F9"
      ></Box>
      <Flex
        display={['flex', null, null, null, 'inline-flex']}
        flexDirection={['column', null, null, null, 'row']}
        width="100%"
        my={['5rem', null, null, null, '4rem']}
        background="#FFF"
        p="1rem"
      >
        <SingleOfferImages images={offer.images} />
        <SingleOfferContent offer={offer} />
      </Flex>
      <Box py="2rem" mb="3rem">
        <SingleOfferAdditional />
      </Box>
      <HideMobile>
        <Banner mt="1rem" mb="5rem" mx="5rem">
          <Image
            src="https://jayinniss.files.wordpress.com/2015/03/rhino-metaphor.jpg"
            alt=""
            borderRadius="5px"
            objectFit="cover"
            width="100%"
            height="350px"
          />
        </Banner>
      </HideMobile>
    </>
  );
};

export default SingleOffer;
