import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${(props) =>
    props.size === "small"
      ? css`
          max-width: 170px;
        `
      : props.size === "medium"
      ? css`
          max-width: 240px;
        `
      : props.size === "large"
      ? css`
          width: 100%;
          max-width: 320px;
        `
      : css`
          max-width: 300px;
        `}
  ${(props) =>
    props.position === "center" &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
    ${(props) =>
      props.position === "right" &&
      css`
        margin-left: auto;
      `}
`;
export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: none;
  border: 1px solid transparent;
  border-radius: 3px;
  text-transform: uppercase;
  font-family: "Lato-Bold";
  letter-spacing: 0.75px;
  font-size: 16px;
  padding: 0.75rem 2.5rem;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  transition: 0.15s all ease;
  &:hover,
  &:focus {
    transition: 0.15s all ease;
  }
  ${(props) =>
    props.version === "danger" &&
    css`
      color: #fff;
      background: #dd2727;
    `}
  ${(props) =>
    props.version === "regular" &&
    css`
      background: none;
      color: #000;
      align-items: flex-start;
      justify-content: flex-start;
      font-family: "Lato-Regular";
      text-transform: none;
      border: none;
      padding: 0.5rem 0;
    `}
  ${(props) =>
    props.version === "primary" &&
    css`
      background: #1774ed;
      color: #fff;
      &:hover {
        background: #3089fd;
      }
      &:focus {
        border: 1px solid #0050b7;
      }
    `}
  ${(props) =>
    props.version === "primary-reverse" &&
    css`
      border: 1px solid #1774ed;
      background: #fff;
      color: #1774ed;
      &:hover {
        background: #f9fbff;
      }
      &:focus {
        border: 1px solid #0050b7;
      }
    `}
  ${(props) =>
    props.version === "primary-hollow" &&
    css`
      border: 1px solid #3b5588;
      background: #fff;
      color: #3b5588;
      &:hover {
        background: #f9fbff;
      }
      &:focus {
        border: 1px solid #1d3970;
      }
    `}
    ${(props) =>
      props.version === "secondary" &&
      css`
        background: #2e2e46;
        color: #fff;
        &:hover {
          background: #363656;
        }
        &:focus {
          border: 1px solid #15151c;
        }
      `}
    ${(props) =>
      props.version === "success" &&
      css`
        background: #1bc744;
        color: #fff;
        &:hover {
          background: #27da51;
        }
        &:focus {
          border: 1px solid #12ae37;
        }
      `}
    ${(props) =>
      props.version === "success-hollow" &&
      css`
        background: #fff;
        color: #1bc744;
        border: 1px solid #1bc744;
        &:hover {
          background: #f9fbff;
        }
        &:focus {
          border: 1px solid #12ae37;
        }
      `}
    ${(props) =>
      props.version === "light" &&
      css`
        background: #c4c4c4;
        color: #fff;
        &:hover {
          background: #bdbdbd;
        }
        &:focus {
          border: 1px solid #a2a2a2;
        }
      `}
    ${(props) =>
      props.version === "light-secondary" &&
      css`
        background: #f2fbfe;
        color: #2e2e46;
        &:hover {
          background: #ffffff;
        }
        &:focus {
          border: 1px solid #a2a2a2;
        }
      `}
    ${(props) =>
      props.version === "light-reverse" &&
      css`
        background: #fff;
        color: #a1a1a1;
        border: 1px solid #a1a1a1;
        &:hover {
          background: #fff;
        }
        &:focus {
          border: 1px solid #000;
        }
      `}
`;
export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 18px;
    height: 18px;
  }
  @media (min-width: 992px) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  @media (min-width: 1200px) {
    svg {
      width: 22px;
      height: 22px;
    }
  }
  @media (min-width: 1440px) {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  ${(props) =>
    props.position === "right"
      ? css`
          margin-left: 0.5rem;
        `
      : css`
          margin-right: 0.5rem;
        `}
`;

export const Loader = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #000;
  pointer-events: none;
  cursor: not-allowed;
`;
export const LoaderRing = styled.div`
  width: 40px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  &:after {
    content: " ";
    display: block;
    width: 16px;
    height: 16px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
