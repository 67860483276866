import styled, { css } from "styled-components";

export const SearchContainer = styled.div`
  transition: 0.15s transform ease-in;
  width: 320px;
  @media (max-width: 1200px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    height: 100vh;
    overflow-y: auto;
    background: #f9fbff;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
  }
  ${(props) =>
    !props.open &&
    css`
      @media (max-width: 1200px) {
        transition: 0.15s transform ease-in-out;
        transform: translateX(-100%);
      }
    `}
`;

export const SearchClose = styled.div`
  transform: translateX(0);
  @media (max-width: 1200px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
  }
  @media (min-width: 1200px) {
    display: none;
  }
  ${(props) =>
    !props.open &&
    css`
      @media (max-width: 1200px) {
        transform: translateX(-100%);
      }
    `}
`;

export const SearchCloseIcon = styled.div`
  @media (max-width: 1200px) {
    position: fixed;
    top: 0.6rem;
    right: 0.6rem;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`;
