import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 30vh;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
  h1 {
    text-align: center;
    margin: 2rem 0;
    color: #fff;
    font-family: 'Lato-Bold';
    text-transform: uppercase;
    display: none;
  }
  @media (min-width: 1200px) {
    top: 120px;
    img {
      max-height: 50vh;
    }
    h1 {
      display: block;
    }
  }
  @media (min-width: 1440px) {
    img {
      max-height: 1080px;
    }
  }
`;

export const OffsetWrapper = styled.div`
  margin-top: 30vh;
  background: #fff;
  @media (min-width: 1200px) {
    margin-top: 20vh;
  }
  @media (min-width: 1440px) {
    margin-top: 200px;
  }
`;
