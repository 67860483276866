import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '../UI/Layout/Box';
import Flex from '../UI/Layout/Flex';
import Heading from '../UI/Heading';
import Icon from '../UI/Icon';
import Item from './Item';
import Select from '../Form/Select';

const Catalogue = (props) => {
  const { home, fields, offers } = props;
  const match = useLocation();
  const [sort, setSort] = useState(match?.state || null);

  useEffect(() => {
    // Fetch items whenever fields or sort change
  }, [fields, sort]);

  const handleSelectChange = (value, action) => {
    setSort((prevState) => ({ ...prevState, [action.name]: value }));
  };
  return (
    <Box
      //padding={[null, "1rem"]}
      margin={['1rem 0', null, null, null, '1rem 15% 1rem 1.5rem']}
    >
      <Flex
        display="inline-flex"
        width="100%"
        alignItems="center"
        mb={['1rem', null, null, '1.5rem']}
        mt={[null, null, null, '.5rem']}
      >
        {home ? (
          <>
            <Icon mr="1rem" width={['18px', '20px', '22px', '24px']}>
              {newReleases}
            </Icon>

            <Heading as="h3">Esiletõstetud kuulutused</Heading>
          </>
        ) : (
          <Flex
            display="inline-flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            minHeight="46px"
          >
            <Heading as="h3">Otsingu tulemused</Heading>
            <Select
              size="small"
              placeholder="Järjesta"
              name="sort"
              isClearable
              value={sort?.sort}
              onChange={handleSelectChange}
              options={sortOptions}
            />
          </Flex>
        )}
      </Flex>
      {offers && offers?.length <= 0 && (
        <Heading as="h3">Autod puuduvad</Heading>
      )}
      {offers &&
        offers?.length > 0 &&
        offers.map((offer) => {
          const { _id, price, images } = offer;
          const {
            carBrand,
            model,
            motor,
            registered,
            mileage,
            gearbox,
            fuel,
          } = offer?.vehicle;
          return (
            <Item
              id={_id}
              imageUrl={images[0]}
              title={`${carBrand?.label} ${model?.label} ${motor?.mType}`}
              /* discount="10 890" */
              price={price}
              attributes={[
                {
                  name: registered?.year?.label,
                },
                {
                  name: `${mileage}km`,
                },
                {
                  name: gearbox?.label,
                },
                {
                  name: `${motor?.mType} (${motor?.kw} kW)`,
                },
                {
                  name: fuel?.sort?.label,
                  highlighted: true,
                },
              ]}
            />
          );
        })}
      {/*       <Item
        imageUrl="https://images.unsplash.com/photo-1568605117036-5fe5e7bab0b7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"
        title="Volkswagen Golf VARIANT"
        discount="10 890"
        price="12 890"
        attributes={[
          {
            name: '2015',
          },
          {
            name: '147 400km',
          },
          {
            name: 'Automaat',
          },
          {
            name: '1.4 TGI (81 kW)',
          },
          {
            name: 'D',
            highlighted: true,
          },
        ]}
      />
      <Item
        imageUrl="https://images.unsplash.com/photo-1568605117036-5fe5e7bab0b7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"
        title="Volkswagen Golf VARIANT"
        discount="10 890"
        price="12 890"
        attributes={[
          {
            name: '2015',
          },
          {
            name: '147 400km',
          },
          {
            name: 'Automaat',
          },
          {
            name: '1.4 TGI (81 kW)',
          },
          {
            name: 'D',
            highlighted: true,
          },
        ]}
      />
      <Item
        imageUrl="https://images.unsplash.com/photo-1568605117036-5fe5e7bab0b7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"
        title="Volkswagen Golf VARIANT"
        discount="10 890"
        price="12 890"
        attributes={[
          {
            name: '2015',
          },
          {
            name: '147 400km',
          },
          {
            name: 'Automaat',
          },
          {
            name: '1.4 TGI (81 kW)',
          },
          {
            name: 'D',
            highlighted: true,
          },
        ]}
      /> */}
    </Box>
  );
};

const newReleases = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="31.395"
    viewBox="0 0 33 31.395"
  >
    <path
      d="M34.5,18l-3.66-4.17.51-5.52L25.935,7.08,23.1,2.31,18,4.5,12.9,2.31,10.065,7.08,4.65,8.3l.51,5.52L1.5,18l3.66,4.17L4.65,27.7l5.415,1.23L12.9,33.7,18,31.5l5.1,2.19,2.835-4.77,5.415-1.23-.51-5.52Zm-15,7.5h-3v-3h3Zm0-6h-3v-9h3Z"
      transform="translate(-1.5 -2.31)"
      fill="#1bc744"
    />
  </svg>
);

const sortOptions = [
  {
    label: 'Viimati lisatud',
    value: 'latest',
    type: 'latest',
    sort: 'DESC',
  },
  {
    label: 'Populaarsemad enne',
    value: 'popular',
    type: 'popularity',
    sort: 'DESC',
  },
  {
    label: 'Odavamad enne',
    value: 'cheaper',
    type: 'price',
    sort: 'DESC',
  },
  {
    label: 'Kallimad enne',
    value: 'pricier',
    type: 'price',
  },
];

export default Catalogue;
