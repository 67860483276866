import React from "react";

import Box from "../../UI/Layout/Box";
import Table from "../../UI/Table";
import TableHeader from "../../UI/Table/TableHeader";
import Heading from "../../UI/Heading";
import Flex from "../../UI/Layout/Flex";
import TableRow from "../../UI/Table/TableRow";
import Text from "../../UI/Text";
import { Link } from "react-router-dom";
import { sanitize } from "dompurify";

const List = ({ inquiries }) => {
  return (
    <Box my="2rem">
      <Table maxWidth="875px">
        <TableHeader>
          <Flex display="flex" flex="0.5">
            <Heading as="h6" fontFamily="Lato-Regular">
              Kuulutus
            </Heading>
          </Flex>
          <Flex display="flex" flex="0.3">
            <Heading as="h6" fontFamily="Lato-Regular">
              Saatja tel nr
            </Heading>
          </Flex>
          <Flex display="flex" flex="0.3">
            <Heading as="h6" fontFamily="Lato-Regular">
              Saatja E-mail
            </Heading>
          </Flex>
          <Flex display="flex" flex="0.4">
            <Heading as="h6" fontFamily="Lato-Regular">
              Sisu
            </Heading>
          </Flex>
        </TableHeader>
        {inquiries.map((inquiry, i) => {
          const { offerId, name, phone, email, content } = inquiry;
          return (
            <TableRow
              key={offerId}
              borderBottom={[
                i < inquiry.length - 1 && "1px solid #E1E1E1",
                null,
                null,
                "none",
              ]}
            >
              <Box display={[null, null, null, "none"]} mb="1rem">
                <Heading as="h5" color="#3B5588">
                  Kuulutus
                </Heading>
              </Box>
              <Flex display="flex" flex="0.5">
                <Text as="p" fontFamily="Lato-Bold">
                  <Link
                    to={`/kuulutus/${offerId}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {name}
                  </Link>
                </Text>
              </Flex>
              <Box display={[null, null, null, "none"]} my="1rem">
                <Heading as="h5" color="#3B5588">
                  Saatja tel nr
                </Heading>
              </Box>
              <Flex display="flex" flex="0.3">
                <a
                  href={`tel:${phone}`}
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    fontFamily: "Lato-Regular",
                  }}
                >
                  {phone}
                </a>
              </Flex>
              <Box display={[null, null, null, "none"]} my="1rem">
                <Heading as="h5" color="#3B5588">
                  Saatja E-mail
                </Heading>
              </Box>
              <Flex display="flex" flex="0.3">
                <a
                  href={`mailto:${email}`}
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    fontFamily: "Lato-Regular",
                  }}
                >
                  {email}
                </a>
              </Flex>
              <Box display={[null, null, null, "none"]} my="1rem">
                <Heading as="h5" color="#3B5588">
                  Sisu
                </Heading>
              </Box>
              <Flex display="flex" flex="0.4">
                <Text
                  as="div"
                  fontFamily="Lato-Regular"
                  lineHeight="1.4"
                  mt="-4px"
                  dangerouslySetInnerHTML={{ __html: sanitize(content) }}
                />
              </Flex>
            </TableRow>
          );
        })}
      </Table>
    </Box>
  );
};

export default List;
