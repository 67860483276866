import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  @media (min-width: 578px) {
    margin-right: 1rem;
  }
`;
export const Label = styled.label`
  font-size: 1rem;
  font-family: "Lato-Bold";
  line-height: 1.8;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  right: 0.6rem;
  left: unset;
  user-select: none;
  pointer-events: none;
  svg {
    width: 15px;
    height: 15px;
  }
`;
export const Input = styled.div`
  position: relative;
  input {
    border: 1px solid #bebeca;
    padding: 0.5rem;
    font-size: 14px;
    color: #494949;
    font-family: "Lato-Bold";
    border-radius: 3px;
    width: 100%;
    transition: 0.15s border ease;
    height: 130px;
    border-radius: 3px;
    cursor: pointer;
    &::placeholder {
      font-family: "Lato-Regular";
      color: #8c8c8c;
    }
    &:hover {
      transition: 0.15s border ease;
      border: 1px solid #596d87;
    }
    &:focus {
      transition: 0.15s border ease;
      border: 1px solid #1774ed;
    }
    &:disabled {
      background: #f9f9f9;
      cursor: not-allowed;
      &::placeholder {
        color: #d4d4d4;
      }
      &:hover {
        border: 1px solid #bebeca;
      }
    }
  }
  ${(props) =>
    props.invalid &&
    css`
      input {
        border: 1px solid #dd2121;
        padding-right: 2rem;
      }
    `}
  @media (min-width: 578px) {
    input {
      max-width: 220px;
    }
  }
`;

export const InputError = styled.div`
  line-height: 1.6;
  color: #dd2121;
  font-family: "Lato-Regular";
  font-size: 14px;
  padding-left: 0.5rem;
`;
