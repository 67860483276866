import React from 'react';
import styled from 'styled-components';
import {
  space,
  color,
  layout,
  flexbox,
  background,
  border,
  compose,
} from 'styled-system';

import Box from '../../UI/Layout/Box';
import Text from '../../UI/Text';
import Flex from '../../UI/Layout/Flex';

const Nav = ({ btns, tab, setTab }) => {
  return (
    <Box as="nav" mt="2rem" mb="1.5rem">
      {btns.map((btn) => {
        if (btn.count <= 0) return null;
        return (
          <CustomFlex
            key={btn.tab}
            as="button"
            type="button"
            borderRadius="3px"
            background={tab === btn.tab ? '#EFF7F9' : '#FFF'}
            color={tab === btn.tab ? '#1774ED' : '#2E2E46'}
            display="inline-flex"
            alignItems="center"
            padding="0.1rem 0.6rem 0.1rem 0.25rem"
            onClick={() => setTab(btn.tab)}
            mr={[null, '0.5rem', '0.75rem', '1rem']}
            border="1px solid transparent"
          >
            <Text
              as="p"
              fontFamily="Lato-Bold"
              padding="0.25rem 0.5rem"
              fontSize={['15px', '16px', '18px', '20px']}
            >
              {btn.name}
            </Text>
            <Box background="#2E2E46" borderRadius="2px" p="0.05rem 0.35rem">
              <Text
                as="span"
                fontFamily="Lato-Bold"
                color="#FFF"
                fontSize="12px"
              >
                {btn.count}
              </Text>
            </Box>
          </CustomFlex>
        );
      })}
    </Box>
  );
};

const CustomFlex = styled(Flex)`
  ${compose(space, color, layout, flexbox, background, border)}
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
`;

export default Nav;
