import React from "react";

import Account from "..";
import Heading from "../../UI/Heading";
import List from "./List";

const Invoices = () => {
  return (
    <Account>
      <Heading as="h3" mt="-4px" color="#2E2E46">
        Arved
      </Heading>
      <List invoices={invoices} />
    </Account>
  );
};

const invoices = [
  {
    id: 123,
    offerId: 1,
    name: "Vokswagen Golf VARIANT",
    date: "10.08.20",
    price: "1500",
    receipt: "/receipt.pdf",
  },
  {
    id: 123,
    offerId: 2,
    name: "Vokswagen Golf VARIANT",
    date: "10.08.20",
    price: "1500",
    receipt: "/receipt.pdf",
  },
];

export default Invoices;
