import React from 'react';

import { Wrapper } from './styles';
import Heading from '../UI/Heading';
import HeroImage from '../../assets/images/hero.png';
import ButtonLink from '../UI/ButtonLink';
import Button from '../UI/Button';
import { HideDesktop } from '../UI/GlobalStyle';
import Box from '../UI/Layout/Box';
import Image from '../UI/Image';

const Hero = (props) => {
  const { open, setOpen } = props;
  return (
    <Wrapper>
      <Box margin={['3rem 0', '5rem 0']}>
        <Heading as="h1">Müügikuulutuse lisamine vaid 2€</Heading>
      </Box>
      <Image src={HeroImage} alt="Auto1000" />
      <HideDesktop>
        <Box margin="2rem 0">
          <Box mb="1rem">
            <ButtonLink
              version="success"
              to="/konto/kuulutused/lisa"
              position="center"
              icon={{
                position: 'left',
                svg: carIcon,
              }}
            >
              Lisa kuulutus
            </ButtonLink>
          </Box>
          <Button
            version="secondary"
            position="center"
            onClick={() => setOpen(!open)}
            icon={{
              position: 'left',
              svg: searchIcon,
            }}
          >
            Otsi kuulutusi
          </Button>
        </Box>
      </HideDesktop>
    </Wrapper>
  );
};

const carIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.664"
    height="22.217"
    viewBox="0 0 31.664 22.217"
  >
    <g transform="translate(-1398.989 -43.454)">
      <path
        d="M24.87,8.792A1.912,1.912,0,0,0,23.053,7.5H8.978A1.924,1.924,0,0,0,7.161,8.792L4.5,16.456V26.692a1.283,1.283,0,0,0,1.279,1.279H7.059a1.283,1.283,0,0,0,1.279-1.279V25.413H23.692v1.279a1.283,1.283,0,0,0,1.279,1.279h1.279a1.283,1.283,0,0,0,1.279-1.279V16.456ZM8.978,21.574A1.919,1.919,0,1,1,10.9,19.655,1.917,1.917,0,0,1,8.978,21.574Zm14.074,0a1.919,1.919,0,1,1,1.919-1.919A1.917,1.917,0,0,1,23.053,21.574Zm-15.994-6.4L8.978,9.419H23.053l1.919,5.758Z"
        transform="translate(1394.489 37.699)"
        fill="#fff"
      />
      <path
        d="M8.6-4.488H5.64V-1.3H3.624V-4.488H.68V-6.264H3.624V-9.432H5.64v3.168H8.6Z"
        transform="translate(1422.053 52.886)"
        fill="#fff"
      />
    </g>
  </svg>
);

const searchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M21.653,19.594H20.569l-.384-.37a8.933,8.933,0,1,0-.961.961l.37.384v1.084L26.455,28.5,28.5,26.455Zm-8.233,0a6.175,6.175,0,1,1,6.175-6.175A6.167,6.167,0,0,1,13.419,19.594Z"
      transform="translate(-4.5 -4.5)"
      fill="#fff"
    />
  </svg>
);

export default Hero;
