import React from 'react';
import Flex from '../UI/Layout/Flex';
import Icon from '../UI/Icon';
import Heading from '../UI/Heading';
import LatestItem from './LatestItem';

const Latest = ({ offers }) => {
  return (
    <Flex display="flex" flexDirection="column">
      <Flex
        display="inline-flex"
        width="100%"
        alignItems="center"
        m={['2rem 0', '3rem 0', '4rem 0', '5rem 0']}
      >
        <Icon mr="1rem" width={['18px', '20px', '22px', '24px']}>
          {latest}
        </Icon>
        <Heading as="h3">Viimati lisatud</Heading>
      </Flex>
      <Flex
        display={['flex', 'inline-flex']}
        flexDirection={['column', 'row']}
        flexWrap="wrap"
      >
        {offers &&
          offers?.length > 0 &&
          offers
            .sort((a, b) =>
              b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
            )
            .map((offer) => {
              const { _id, price, images } = offer;
              const {
                carBrand,
                model,
                motor,
                registered,
                mileage,
                gearbox,
                fuel,
              } = offer?.vehicle;
              return (
                <LatestItem
                  id={_id}
                  imageUrl={images[0]}
                  title={`${carBrand?.label} ${model?.label} ${motor?.mType}`}
                  /* discount="10 890" */
                  price={price}
                  attributes={[
                    {
                      name: registered?.year?.label,
                    },
                    {
                      name: `${mileage}km`,
                    },
                    {
                      name: gearbox?.label,
                    },
                    {
                      name: `${motor?.mType} (${motor?.kw} kW)`,
                    },
                    {
                      name: fuel?.sort?.label,
                      highlighted: true,
                    },
                  ]}
                />
              );
            })}
      </Flex>
    </Flex>
  );
};

const latest = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <path
      d="M13.063.563a12.5,12.5,0,1,0,12.5,12.5A12.5,12.5,0,0,0,13.063.563Zm2.878,17.646-4.446-3.231a.609.609,0,0,1-.247-.489V6.006a.607.607,0,0,1,.6-.6h2.419a.607.607,0,0,1,.6.6v6.941l3.2,2.329a.6.6,0,0,1,.131.847l-1.421,1.956A.609.609,0,0,1,15.941,18.209Z"
      transform="translate(-0.563 -0.563)"
      fill="#1bc744"
    />
  </svg>
);

export default Latest;
