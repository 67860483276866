import "react-hot-loader";
import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import "./fonts.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorker from "./serviceWorker";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
      <ToastContainer newestOnTop />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
      <ToastContainer />
    </Provider>,
    rootElement
  );
}

serviceWorker.unregister();
