import React from "react";
import { withTheme } from "styled-components";

import { Wrapper } from "./styles";

const Container = (props) => {
  const { theme } = props;

  return <Wrapper maxWidth={theme.breakpoints}>{props.children}</Wrapper>;
};

export default withTheme(Container);
