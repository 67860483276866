import React from 'react';
import Box from '../../components/UI/Layout/Box';
import Container from '../../components/UI/Layout/Container';
import Header from '../../components/Header';
import OfferCreator from '../../components/Account/Offers/Creator';

const OfferCreatorPage = ({ editMode }) => {
  return (
    <>
      <Header />
      <Box bg="#EFF7F9" py={['5rem', null, null, null, '4rem']}>
        <Container>
          <OfferCreator editMode={editMode} />
        </Container>
      </Box>
    </>
  );
};

export default OfferCreatorPage;
