import React, { useState } from 'react';

import Search from '../Search';
import Container from '../UI/Layout/Container';
import Flex from '../UI/Layout/Flex';
import { HideMobile, HideDesktop } from '../UI/GlobalStyle';
import Banner from '../Banner';
import Catalogue from '../Catalogue';
import Image from '../UI/Image';
import Box from '../UI/Layout/Box';
import Text from '../UI/Text';
import { Icon } from '../UI/Button/styles';
import CarList from '../Catalogue/CarList';

const Advertisements = ({ offers }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [fields, setFields] = useState(null);
  return (
    <Container>
      <Box
        position="absolute"
        top="0"
        left="0"
        height="100vh"
        width="100%"
        zIndex="-1"
        background="#EFF7F9"
      ></Box>
      <HideDesktop>
        <Flex
          position="fixed"
          top="50px"
          left="0"
          width="100%"
          padding="1.5rem 1rem"
          background="#FFF"
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => setSearchOpen(!searchOpen)}
          borderBottom="1px solid #EFF7F9"
          boxShadow="0 3px 16px rgba(0,0,0,0.06)"
          zIndex="1"
        >
          <Icon>{filter} </Icon>
          <Text fontFamily="Lato-Bold" as="span" color="#3b5588">
            Ava otsing
          </Text>
        </Flex>
      </HideDesktop>
      <Flex
        display={['flex', 'inline-flex']}
        mt={['5rem', null, null, null, '4rem']}
      >
        <Flex width={[0, 0, 0, 0, '320px']}>
          <Search
            open={searchOpen}
            setOpen={setSearchOpen}
            fields={fields}
            setFields={setFields}
          />
          <CarList setFields={setFields} />
          <HideMobile>
            <Banner mt="1rem" mb="2rem">
              <Image
                src="https://jayinniss.files.wordpress.com/2015/03/rhino-metaphor.jpg"
                alt=""
                borderRadius="5px"
                objectFit="cover"
              />
            </Banner>
          </HideMobile>
        </Flex>
        <Flex
          width={[1, 1, 1, 1, 3 / 4]}
          background={[null, null, null, null, '#FFF']}
        >
          <Catalogue fields={fields} offers={offers} />
        </Flex>
      </Flex>
      <HideDesktop>
        <Banner m="1.5rem">
          <Image
            src="https://jayinniss.files.wordpress.com/2015/03/rhino-metaphor.jpg"
            alt=""
            borderRadius="5px"
            objectFit="cover"
            width="100%"
            maxHeight="300px"
          />
        </Banner>
      </HideDesktop>
    </Container>
  );
};

const filter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="30"
    viewBox="0 0 33 30"
  >
    <path
      d="M33,4.5H3L15,18.69V28.5l6,3V18.69Z"
      transform="translate(-1.5 -3)"
      fill="none"
      stroke="#3b5588"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
);

export default Advertisements;
