import React from "react";

import {
  Wrapper,
  Label,
  Input,
  ErrorIcon,
  InputError,
  Checkmark,
} from "./styles";

const Checkbox = (props) => {
  return (
    <Wrapper size={props.size || "medium"}>
      <Input invalid={props.error} label={props.label} checked={props.checked}>
        <label htmlFor={props.name}>
          {props.label !== "right" && (
            <Label fontFamily={props.fontFamily} fontSize={props.fontSize}>
              {props.children}
            </Label>
          )}
          <input
            type="checkbox"
            id={props.name}
            name={props.name}
            value={props.name || ""}
            checked={props.checked || false}
            disabled={props.disabled}
            onChange={(e) => props.onChange(e.target.checked, props.name)}
          />
          {props.label === "right" && (
            <Label fontFamily={props.fontFamily} fontSize={props.fontSize}>
              {props.children}
            </Label>
          )}
          {props.checked && <Checkmark>{checkmark}</Checkmark>}
        </label>
        {props.error && <ErrorIcon>{errorIcon}</ErrorIcon>}
      </Input>
      {props.error && <InputError>{props.error.msg}</InputError>}
    </Wrapper>
  );
};

const errorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.375"
    height="19"
    viewBox="0 0 21.375 19"
  >
    <path
      d="M21.134,16.329A1.782,1.782,0,0,1,19.592,19H1.783A1.782,1.782,0,0,1,.24,16.329L9.145.89a1.782,1.782,0,0,1,3.086,0ZM10.687,13.137a1.707,1.707,0,1,0,1.707,1.707A1.707,1.707,0,0,0,10.687,13.137ZM9.067,7l.275,5.047a.445.445,0,0,0,.445.421h1.8a.445.445,0,0,0,.445-.421L12.308,7a.445.445,0,0,0-.445-.47H9.511A.445.445,0,0,0,9.067,7Z"
      fill="#dd2121"
    />
  </svg>
);

const checkmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.422"
    height="8.863"
    viewBox="0 0 11.422 8.863"
  >
    <path
      d="M16811.539,6435.5l3.572,3.57,6.418-6.742"
      transform="translate(-16810.832 -6431.636)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
);

export default Checkbox;
