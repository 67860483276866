import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { connect, useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { setConfig } from 'react-hot-loader';

import ScrollToTop from './components/helpers/ScrollToTop';
import Home from './pages/Home';
import NotFound from './pages/static/NotFound';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Reset from './pages/auth/Reset';
import Offers from './pages/OffersPage';
import PrivacyPolicy from './pages/static/PrivacyPolicy';
import TermsAndConditions from './pages/static/TermsAndConditions';
import Commercial from './pages/static/Commercial';
import Account from './pages/account';
import Offer from './pages/OfferPage';
import Info from './pages/static/Info';
import { getUser } from './store/actions/user';
import { getOffers } from './store/actions/offer';

setConfig({
  showReactDomPatchNotification: false,
});

const App = ({ USER }) => {
  const [loaded, setLoaded] = useState(false);
  const OFFERS = useSelector((state) => state.offers.data);
  const dispatch = useDispatch();

  // Fetch user when token is already set but no user is defined in state
  useEffect(() => {
    const cookies = new Cookies(),
      token = cookies.get('token');
    if (!USER) {
      if (token) {
        dispatch(getUser(token));
        setLoaded(true);
      } else {
        setLoaded(true);
      }
    } else {
      setLoaded(true);
    }
  }, [USER, dispatch]);

  // Load all offers
  useEffect(() => {
    if (!OFFERS) dispatch(getOffers());
  }, [OFFERS, dispatch]);

  if (!loaded) return null;

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" render={() => <Home offers={OFFERS} />} />
          <Route path="/logi-sisse" component={Login} />
          <Route path="/registreeri" component={Register} />
          <Route path="/taasta-parool/:id?" component={Reset} />
          <Route path="/kuulutused" render={() => <Offers offers={OFFERS} />} />
          <Route path="/kuulutus/:id" component={Offer} />
          <Route path="/privaatsuspoliitika" component={PrivacyPolicy} />
          <Route path="/kasutustingimused" component={TermsAndConditions} />
          <Route path="/reklaam" component={Commercial} />
          <Route path="/info" component={Info} />
          <Route path="/konto" render={() => <Account USER={USER} />} />
          {/* 404 ROUTE */}
          <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export const mapStateToProps = (state) => {
  return {
    USER: state.user?.data,
  };
};

export default process.env.NODE_ENV === 'development'
  ? hot(connect(mapStateToProps)(App))
  : connect(mapStateToProps)(App);
