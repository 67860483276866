import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Wrapper,
  WrapperInner,
  Burger,
  Logo,
  NavItems,
  User,
  Spacing,
  Close,
} from './styles';
import { HideMobile, HideDesktop } from '../UI/GlobalStyle';
import ButtonLink from '../UI/ButtonLink';
import Box from '../UI/Layout/Box';
import Flex from '../UI/Layout/Flex';
import Text from '../UI/Text';

const Header = () => {
  const [open, setOpen] = useState(false);
  const USER = useSelector((state) => state.user?.data);
  const dispatch = useDispatch();

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove('token', { path: '/' });
    dispatch({
      type: 'SET_USER',
      payload: null,
    });
  };

  return (
    <>
      <Spacing />
      <Wrapper>
        <WrapperInner>
          <Burger onClick={() => setOpen(!open)}>{burger}</Burger>
          <Logo>
            <Link to="/">{logo}</Link>
          </Logo>
          <HideDesktop>
            <Box position="absolute" right="1rem" top="0.75rem">
              <Link to={!USER ? `/logi-sisse` : '/konto/kuulutused'}>
                {user}
              </Link>
            </Box>
          </HideDesktop>
          <NavItems open={open} onClick={() => setOpen(!open)}>
            <li>
              <NavLink
                to={{
                  pathname: '/kuulutused',
                  state: {
                    sort: {
                      label: 'Viimati lisatud',
                      value: 'latest',
                      type: 'latest',
                      sort: 'DESC',
                    },
                  },
                }}
                activeStyle={{
                  color: '#3b5588',
                }}
              >
                Viimati lisatud
              </NavLink>
            </li>
            <li>
              <NavLink to="/kuulutused">Kõik kuulutused</NavLink>
            </li>
            <li>
              <NavLink to="/info">Lisainfo</NavLink>
            </li>
            {!USER && (
              <Box
                margin={{
                  xs: '2rem 0 0 0',
                  md: '0 0 0 1.5rem',
                }}
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                }}
              >
                <HideDesktop>
                  <li>
                    <NavLink to="/logi-sisse">Logi sisse</NavLink>
                  </li>
                </HideDesktop>
                <li>
                  <NavLink to="/registreeri">Registreeru</NavLink>
                </li>
              </Box>
            )}
            {USER && (
              <HideDesktop>
                <Box mt="1.5rem">
                  <li>
                    <NavLink
                      to="/"
                      onClick={handleLogout}
                      activeStyle={{
                        color: '#3b5588',
                      }}
                    >
                      Logi välja
                    </NavLink>
                  </li>
                </Box>
              </HideDesktop>
            )}
          </NavItems>
          <HideMobile>
            <Box ml="1.5rem">
              <ButtonLink
                version="success"
                to="/konto/kuulutused/lisa"
                position="center"
                icon={{
                  position: 'left',
                  svg: carIcon,
                }}
              >
                Lisa kuulutus
              </ButtonLink>
            </Box>
          </HideMobile>
          <User>
            <HideMobile>
              <Flex
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                margin={{
                  md: USER && '0.5rem 0 0 0',
                }}
              >
                {!USER ? (
                  <Link to="/logi-sisse">Logi sisse</Link>
                ) : (
                  <>
                    <Link to="/konto/kuulutused">
                      <Flex
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {user}
                        {USER?.firstName} {USER?.lastName.charAt(0)}.
                      </Flex>
                    </Link>
                    <NavLink
                      to="/"
                      onClick={handleLogout}
                      activeStyle={{
                        color: '#3b5588',
                      }}
                    >
                      <Flex
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        ml="1rem"
                      >
                        {logout}
                        <Text as="span">Logi välja</Text>
                      </Flex>
                    </NavLink>
                  </>
                )}
              </Flex>
            </HideMobile>
          </User>
        </WrapperInner>
      </Wrapper>
      <Close open={open} onClick={() => setOpen(!open)} />
    </>
  );
};

const burger = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="16"
    viewBox="0 0 24 16"
  >
    <g transform="translate(-16 -16)">
      <path
        d="M6.427,26.283h24V23.616h-24v2.667Zm0-6.667h24V16.95h-24v2.667Zm0-9.333V12.95h24V10.283h-24Z"
        transform="translate(9.573 5.717)"
      />
    </g>
  </svg>
);

const user = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.942"
    height="23.934"
    viewBox="0 0 20.942 23.934"
  >
    <path
      d="M10.471,11.967A5.983,5.983,0,1,0,4.488,5.983,5.983,5.983,0,0,0,10.471,11.967Zm4.188,1.5h-.781a8.137,8.137,0,0,1-6.815,0H6.283A6.284,6.284,0,0,0,0,19.745V21.69a2.244,2.244,0,0,0,2.244,2.244H18.7a2.244,2.244,0,0,0,2.244-2.244V19.745A6.284,6.284,0,0,0,14.659,13.463Z"
      fill="#3b5588"
    />
  </svg>
);

const logo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53.188"
    height="21.988"
    viewBox="0 0 53.188 21.988"
  >
    <g transform="translate(0 0)">
      <path
        d="M126.17,654.824h-4.5v-6.236h-3.095v9.331h10.691v-9.331H126.17Z"
        transform="translate(-100.112 -648.549)"
        fill="#0e334c"
      />
      <path
        d="M139.238,651.683h3.517v6.236h3.095v-6.236h3.517v-3.095H139.238Z"
        transform="translate(-108.453 -648.549)"
        fill="#0e334c"
      />
      <path
        d="M97.659,657.731V655.2h-3.1l3.579-3.517h2.876v6.236h3.095v-9.331H96.858l-9.24,9.132,4.353.024.012-.012Z"
        transform="translate(-87.619 -648.549)"
        fill="#0e334c"
      />
      <path
        d="M104.089,672.4h4.076v6.192h2.624v-8.816h-6.7Z"
        transform="translate(-94.266 -657.099)"
        fill="#0e334c"
      />
      <path
        d="M122.117,669.311a4.8,4.8,0,1,0,4.795,4.8A4.8,4.8,0,0,0,122.117,669.311Zm0,7.193a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,122.117,676.5Z"
        transform="translate(-99.607 -656.913)"
        fill="#0e334c"
      />
      <path
        d="M139.406,669.311a4.8,4.8,0,1,0,4.795,4.8A4.8,4.8,0,0,0,139.406,669.311Zm0,7.193a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,139.406,676.5Z"
        transform="translate(-106.585 -656.913)"
        fill="#0e334c"
      />
      <path
        d="M157.378,669.311a4.8,4.8,0,1,0,4.795,4.8A4.8,4.8,0,0,0,157.378,669.311Zm0,7.193a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,157.378,676.5Z"
        transform="translate(-113.839 -656.913)"
        fill="#0e334c"
      />
      <path
        d="M170.018,658.546H159.994V648.522h10.024Zm-7.43-2.594h4.836v-4.836h-4.836Z"
        transform="translate(-116.83 -648.522)"
        fill="#0e334c"
      />
    </g>
  </svg>
);

const carIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.664"
    height="22.217"
    viewBox="0 0 31.664 22.217"
  >
    <g transform="translate(-1398.989 -43.454)">
      <path
        d="M24.87,8.792A1.912,1.912,0,0,0,23.053,7.5H8.978A1.924,1.924,0,0,0,7.161,8.792L4.5,16.456V26.692a1.283,1.283,0,0,0,1.279,1.279H7.059a1.283,1.283,0,0,0,1.279-1.279V25.413H23.692v1.279a1.283,1.283,0,0,0,1.279,1.279h1.279a1.283,1.283,0,0,0,1.279-1.279V16.456ZM8.978,21.574A1.919,1.919,0,1,1,10.9,19.655,1.917,1.917,0,0,1,8.978,21.574Zm14.074,0a1.919,1.919,0,1,1,1.919-1.919A1.917,1.917,0,0,1,23.053,21.574Zm-15.994-6.4L8.978,9.419H23.053l1.919,5.758Z"
        transform="translate(1394.489 37.699)"
        fill="#fff"
      />
      <path
        d="M8.6-4.488H5.64V-1.3H3.624V-4.488H.68V-6.264H3.624V-9.432H5.64v3.168H8.6Z"
        transform="translate(1422.053 52.886)"
        fill="#fff"
      />
    </g>
  </svg>
);

const logout = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g transform="translate(-3 -3)">
      <path
        d="M13.5,31.5h-6a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3h6"
        fill="none"
        stroke="#3b5588"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M24,25.5,31.5,18,24,10.5"
        fill="none"
        stroke="#3b5588"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M31.5,18h-18"
        fill="none"
        stroke="#3b5588"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export default Header;
