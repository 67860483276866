import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { create } from '../../store/actions/user';
import { CreateToast } from '../../components/helpers/CreateToast';

import Header from '../../components/Header';
import Box from '../../components/UI/Layout/Box';
import Heading from '../../components/UI/Heading';
import Button from '../../components/UI/Button';
import TextField from '../../components/Form/TextField';
import CheckboxGroup from '../../components/Form/Checkbox/Group';
import Checkbox from '../../components/Form/Checkbox';
import { Link } from 'react-router-dom';
import Form from '../../components/Form';

const Register = () => {
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState(null);
  const [errors, setErrors] = useState(null);
  const USER = useSelector((state) => state.user?.data);
  const history = useHistory();
  const dispatch = useDispatch();

  // If user is already logged in, redirect
  useEffect(() => {
    if (USER) history.push('/');
  }, [USER, history]);

  const handleInputChange = (value, name) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
    if (errors?.[name])
      setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrors(null);
    dispatch(create(fields))
      .then((res) => {
        const { errors, data } = res.data;
        setSubmitting(false);
        if (errors && errors[0]?.message) {
          setErrors(JSON.parse(errors[0]?.message));
          CreateToast({
            msg: 'Kontrolli sisestatud välju.',
            type: 'warn',
          });
        }
        if (data?.createUser) history.push('/logi-sisse');
      })
      .catch(() => {
        setSubmitting(false);
        CreateToast({
          msg: 'Tekkis tundmatu viga, proovige uuesti.',
          type: 'warn',
        });
      });
  };

  return (
    <>
      <Header />
      <Box
        background="#EFF7F9"
        minHeight={[
          'calc(100vh - 50px)',
          null,
          null,
          null,
          'calc(100vh - 120px)',
        ]}
        py={['2rem', '3rem', '4rem', '5rem']}
      >
        <Box maxWidth="820px" margin="0 auto">
          <Box
            p="1rem"
            background="#FFF"
            padding={['1rem', null, null, '2rem', '3rem']}
          >
            <Heading as="h1" color="#2E2E46" fontFamily="Lato-Black">
              Registreeru
            </Heading>
            <Box py="0.5rem" my="0.5rem">
              <Form onSubmit={handleSubmit}>
                <Box maxWidth="340px" mx="auto">
                  <TextField
                    size="large"
                    placeholder="Sisesta eesnimi"
                    label="Eesnimi"
                    name="firstName"
                    value={fields?.firstName}
                    onChange={handleInputChange}
                    error={errors?.firstName}
                  />
                  <TextField
                    size="large"
                    placeholder="Sisesta perekonnanimi"
                    label="Perekonnanimi"
                    name="lastName"
                    value={fields?.lastName}
                    onChange={handleInputChange}
                    error={errors?.lastName}
                  />
                  <TextField
                    size="large"
                    placeholder="Sisesta e-mail"
                    label="E-mail"
                    name="email"
                    value={fields?.email}
                    onChange={handleInputChange}
                    error={errors?.email}
                  />
                  <TextField
                    size="large"
                    placeholder="Sisesta telefoni number"
                    label="Telefon"
                    name="phone"
                    value={fields?.phone}
                    onChange={handleInputChange}
                    error={errors?.phone}
                  />
                  <Box mt="2rem" mb="1rem">
                    <TextField
                      type="password"
                      size="large"
                      placeholder="Sisesta parool"
                      label="Parool"
                      name="password"
                      autoComplete="off"
                      value={fields?.password}
                      onChange={handleInputChange}
                      error={errors?.password}
                    />
                    <TextField
                      type="password"
                      size="large"
                      placeholder="Sisesta parool"
                      label="Parool uuesti"
                      name="repeat-password"
                      autoComplete="off"
                      value={fields?.['repeat-password']}
                      onChange={handleInputChange}
                      error={errors?.['repeat-password']}
                    />
                  </Box>
                  <CheckboxGroup size="full">
                    <Checkbox
                      fontFamily="Lato-Regular"
                      fontSize="14px"
                      label="right"
                      name="newsletter"
                      checked={fields?.newsletter}
                      onChange={handleInputChange}
                    >
                      Nõustun auto1000 uudispakkumiste kirjaga
                    </Checkbox>
                    <Checkbox
                      fontFamily="Lato-Regular"
                      fontSize="14px"
                      label="right"
                      name="privacy"
                      checked={fields?.privacy}
                      error={errors?.privacy}
                      onChange={handleInputChange}
                    >
                      Nõustun{' '}
                      <Link
                        to="/privaatsuspoliitika"
                        target="_blank"
                        style={{ color: '#313131' }}
                      >
                        privaatsustingimustega
                      </Link>
                    </Checkbox>
                    <Checkbox
                      fontFamily="Lato-Regular"
                      fontSize="14px"
                      label="right"
                      name="terms"
                      checked={fields?.terms}
                      error={errors?.terms}
                      onChange={handleInputChange}
                    >
                      Nõustun{' '}
                      <Link
                        to="/privaatsuspoliitika"
                        target="_blank"
                        style={{ color: '#313131' }}
                      >
                        kasutajatingimustega
                      </Link>
                    </Checkbox>
                  </CheckboxGroup>
                  <Box my="2rem" mx="4rem">
                    <Button
                      loading={submitting}
                      type="submit"
                      version="success"
                    >
                      Registreeri
                    </Button>
                  </Box>
                </Box>
              </Form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Register;
