import React from "react";
import Header from "../../components/Header";
import Offers from "../../components/Account/Offers";

const AccountOffersPage = () => {
  return (
    <>
      <Header />
      <Offers />
    </>
  );
};

export default AccountOffersPage;
