const theme = {
  breakpoints: ["36.125em", "48em", "62em", "75em", "102.5em"],
  headings: {
    h1: [22, 26, 28, 32],
    h2: [20, 24, 26, 30],
    h3: [18, 22, 24, 28],
    h4: [16, 20, 22, 26],
    h5: [14, 18, 20, 24],
    h6: [12, 16, 18, 20],
  },
};

export default theme;
