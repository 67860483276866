import React, { useState } from 'react';
import { validateThree } from '../../../../store/actions/offer';
import { useDispatch } from 'react-redux';
import { CreateToast } from '../../../helpers/CreateToast';

import Form from '../../../Form';
import Box from '../../../UI/Layout/Box';
import TextField from '../../../Form/TextField';
import Number from '../../../Form/Number';
import Select from '../../../Form/Select';
import Checkbox from '../../../Form/Checkbox';
import Date from '../../../Form/Date';
import Button from '../../../UI/Button';
import Flex from '../../../UI/Layout/Flex';
import fuelOptions from '../../../../data/fuelOptions.json';
import gearboxOptions from '../../../../data/gearboxOptions.json';
const { fuelTypes } = fuelOptions;
const { gearboxTypes } = gearboxOptions;

const StepThree = ({ setTab, masterFields, setMasterFields, setCompleted }) => {
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState(masterFields || null);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  const handleInputChange = (value, name) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
    if (errors?.[name])
      setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const handleSelectChange = (value, action) => {
    setFields((prevState) => ({ ...prevState, [action.name]: value }));
    if (errors?.[action.name])
      setErrors((prevState) => ({ ...prevState, [action.name]: null }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: VALIDATE
    setSubmitting(true);
    dispatch(validateThree(fields))
      .then((res) => {
        const { errors, data } = res.data;
        setSubmitting(false);
        if (errors && errors[0]?.message) {
          setErrors(JSON.parse(errors[0]?.message));
          CreateToast({
            msg: 'Kontrolli sisestatud välju.',
            type: 'warn',
          });
          return;
        }
        const parsed = JSON.parse(data?.createOfferThree);
        if (parsed?.success) {
          setMasterFields((prevState) => ({ ...prevState, ...fields }));
          setCompleted(2);
          setTab(3);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log('err', err);
      });
  };

  const handleBackward = () => {
    setMasterFields((prevState) => ({ ...prevState, ...fields }));
    setTab(1);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Flex
          display={['flex', 'inline-flex']}
          flexDirection={['column', 'row']}
          width="100%"
        >
          <Flex mr={[0, '12px', '16px', '24px']}>
            <Number
              size="small"
              step={0.1}
              float={1}
              placeholder="1.x"
              label="Mootori maht (L)"
              name="motorL"
              value={fields?.motorL}
              error={errors?.motorL}
              onChange={handleInputChange}
            />
          </Flex>
          <Flex width="100%">
            <Number
              size="small"
              step={200}
              float={0}
              placeholder="1234"
              label="Mootori maht (cm3)"
              name="motorCubic"
              value={fields?.motorCubic}
              error={errors?.motorCubic}
              onChange={handleInputChange}
            />
          </Flex>
        </Flex>
        <Flex
          display={['flex', 'inline-flex']}
          flexDirection={['column', 'row']}
          width="100%"
        >
          <Flex mr={[0, '12px', '16px', '24px']}>
            <TextField
              size="small"
              placeholder="R2"
              label="Mootori tüüp"
              name="motorType"
              value={fields?.motorType}
              error={errors?.motorType}
              onChange={handleInputChange}
            />
          </Flex>
          <Flex width="100%">
            <Number
              size="small"
              step={20}
              float={0}
              placeholder="123"
              label="Mootori võimsus (kW)"
              name="motorKw"
              value={fields?.motorKw}
              error={errors?.motorKw}
              onChange={handleInputChange}
            />
          </Flex>
        </Flex>
        <Box my="2rem">
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
          >
            <Flex mr={[0, '12px', '16px', '24px']}>
              <Number
                size="small"
                step={100}
                float={0}
                placeholder="1234"
                label="Tühimass (kg)"
                name="emptyMass"
                value={fields?.emptyMass}
                error={errors?.emptyMass}
                onChange={handleInputChange}
              />
            </Flex>
            <Flex width="100%">
              <Number
                size="small"
                step={100}
                float={0}
                placeholder="1234"
                label="Täismass (kg)"
                name="fullMass"
                value={fields?.fullMass}
                error={errors?.fullMass}
                onChange={handleInputChange}
              />
            </Flex>
          </Flex>
          <Number
            size="small"
            step={100}
            float={0}
            placeholder="1234"
            label="Kandevõime (kg)"
            name="kandevoime"
            value={fields?.kandevoime}
            error={errors?.kandevoime}
            onChange={handleInputChange}
          />
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
          >
            <Flex mr={[0, '12px', '16px', '24px']}>
              <Number
                size="small"
                step={100}
                float={0}
                placeholder="1234"
                label="Piduriga haagis (kg)"
                name="pidurHaagis"
                value={fields?.pidurHaagis}
                error={errors?.pidurHaagis}
                onChange={handleInputChange}
              />
            </Flex>
            <Flex width="100%">
              <Number
                size="small"
                step={100}
                float={0}
                placeholder="1234"
                label="Pidurita haagis (kg)"
                name="piduritaHaagis"
                value={fields?.piduritaHaagis}
                error={errors?.piduritaHaagis}
                onChange={handleInputChange}
              />
            </Flex>
          </Flex>
        </Box>
        <Box my="2rem">
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
          >
            <Flex mr={[0, '12px', '16px', '24px']}>
              <Number
                size="small"
                step={200}
                float={0}
                placeholder="1234"
                label="Pikkus (mm)"
                name="length"
                value={fields?.length}
                error={errors?.length}
                onChange={handleInputChange}
              />
            </Flex>
            <Flex width="100%">
              <Number
                size="small"
                step={200}
                float={0}
                placeholder="1234"
                label="Laius (mm)"
                name="width"
                value={fields?.width}
                error={errors?.width}
                onChange={handleInputChange}
              />
            </Flex>
          </Flex>
          <Number
            size="small"
            step={100}
            float={0}
            placeholder="1234"
            label="Kõrgus (mm)"
            name="height"
            value={fields?.height}
            error={errors?.height}
            onChange={handleInputChange}
          />
        </Box>
        <Box my="2rem">
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
            maxWidth="364px"
          >
            <Flex mr={[0, '12px', '16px', '24px']} width="100%">
              <Select
                label="Rattavalem"
                placeholder="Kirjuta ja vali"
                name="rattavalem"
                value={fields?.rattavalem}
                error={errors?.rattavalem}
                onChange={handleSelectChange}
                options={[
                  {
                    label: 'test',
                    value: 'test',
                  },
                ]}
              />
            </Flex>
            <Flex width="100%">
              <Number
                size="small"
                step={1}
                float={0}
                placeholder="2"
                label="Sildade arv"
                name="sillad"
                value={fields?.sillad}
                error={errors?.sillad}
                onChange={handleInputChange}
              />
            </Flex>
          </Flex>
        </Box>
        <Box my="2rem">
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
            maxWidth="364px"
          >
            <Flex mr={[0, '12px', '16px', '24px']} width="100%">
              <Select
                label="Kütus"
                placeholder="Kirjuta ja vali"
                name="fuel"
                value={fields?.fuel}
                error={errors?.fuel}
                onChange={handleSelectChange}
                options={fuelTypes}
              />
            </Flex>
            <Flex width="100%">
              <Number
                size="small"
                step={10}
                float={0}
                placeholder="2"
                label="Paak (L)"
                name="fuelMax"
                value={fields?.fuelMax}
                error={errors?.fuelMax}
                onChange={handleInputChange}
              />
            </Flex>
          </Flex>
          <Select
            size="small"
            label="Käigukast"
            placeholder="Kirjuta ja vali"
            name="gearbox"
            value={fields?.gearbox}
            error={errors?.gearbox}
            onChange={handleSelectChange}
            options={gearboxTypes}
          />
        </Box>
        <Box my="2rem">
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
          >
            <Flex mr={[0, '12px', '16px', '24px']}>
              <Number
                size="small"
                step={1}
                float={1}
                placeholder="5.x"
                label="Kütusekulu linnas (l/100km)"
                name="fuelCity"
                value={fields?.fuelCity}
                error={errors?.fuelCity}
                onChange={handleInputChange}
              />
            </Flex>
            <Flex width="100%">
              <Number
                size="small"
                step={1}
                float={1}
                placeholder="5.x"
                label="Kütusekulu maanteel (l/100km)"
                name="fuelRoad"
                value={fields?.fuelRoad}
                error={errors?.fuelRoad}
                onChange={handleInputChange}
              />
            </Flex>
          </Flex>
          <Number
            size="small"
            step={1}
            float={1}
            placeholder="5.x"
            label="Kütusekulu keskmine (l/100km)"
            name="fuelAverage"
            value={fields?.fuelAverage}
            error={errors?.fuelAverage}
            onChange={handleInputChange}
          />
        </Box>
        <Box my="2rem">
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
          >
            <Flex mr={[0, '12px', '16px', '24px']}>
              <Number
                size="small"
                step={1}
                float={1}
                placeholder="5.x"
                label="Kiirendus 0-100km/h (s)"
                name="acceleration"
                value={fields?.acceleration}
                error={errors?.acceleration}
                onChange={handleInputChange}
              />
            </Flex>
            <Flex width="100%">
              <Number
                size="small"
                step={20}
                float={0}
                placeholder="123"
                label="Tippkiirus (km/h)"
                name="maxSpeed"
                value={fields?.maxSpeed}
                error={errors?.maxSpeed}
                onChange={handleInputChange}
              />
            </Flex>
          </Flex>
        </Box>
        <Box my="2rem">
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
          >
            <Flex mr={[0, '12px', '16px', '24px']}>
              <Number
                size="small"
                step={1}
                float={0}
                placeholder="3"
                label="Istekohti"
                name="seats"
                value={fields?.seats}
                error={errors?.seats}
                onChange={handleInputChange}
              />
            </Flex>
            <Flex width="100%">
              <Number
                size="small"
                step={1}
                float={0}
                placeholder="3"
                label="Uste arv"
                name="doors"
                value={fields?.doors}
                error={errors?.doors}
                onChange={handleInputChange}
              />
            </Flex>
          </Flex>
        </Box>
        <Box my="2rem">
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
          >
            <Flex mr={[0, '12px', '16px', '24px']}>
              <Checkbox
                size="small"
                label="left"
                value="quarantee"
                name="quarantee"
                checked={fields?.quarantee}
                error={errors?.quarantee}
                onChange={handleInputChange}
              >
                Garantii
              </Checkbox>
            </Flex>
            {fields?.quarantee && (
              <Flex width="100%">
                <Date
                  size="small"
                  label="Garantii kehtib kuni"
                  placeholder="Vali kuupäev"
                  id="quarantee_date"
                  name="quaranteeDate"
                  value={fields?.quaranteeDate}
                  error={errors?.quaranteeDate}
                  onChange={handleInputChange}
                />
              </Flex>
            )}
          </Flex>
        </Box>
        <Checkbox
          size="small"
          label="left"
          value="hooldusraamat"
          name="hooldusraamat"
          checked={fields?.hooldusraamat}
          error={errors?.hooldusraamat}
          onChange={handleInputChange}
        >
          Hooldusraamat
        </Checkbox>
        <Box my="2rem">
          <Flex
            display={['flex', 'inline-flex']}
            flexDirection={['column', 'row']}
            width="100%"
          >
            <Flex mr={[0, '12px', '16px', '24px']}>
              <Checkbox
                size="small"
                label="left"
                value="ulevaatus"
                name="ulevaatus"
                checked={fields?.ulevaatus}
                error={errors?.ulevaatus}
                onChange={handleInputChange}
              >
                Ülevaatus
              </Checkbox>
            </Flex>
            {fields?.ulevaatus && (
              <Flex width="100%">
                <Date
                  size="small"
                  label="Ülevaatus kehtib kuni"
                  placeholder="Vali kuupäev"
                  id="ulevaatus_date"
                  name="ulevaatusDate"
                  value={fields?.ulevaatusDate}
                  error={errors?.ulevaatusDate}
                  onChange={handleInputChange}
                />
              </Flex>
            )}
          </Flex>
        </Box>
        <Flex
          my="3rem"
          display="inline-flex"
          flexDirection={['column', 'row']}
          width="100%"
        >
          <Box mr={[0, '1.5rem']} mb={['1.5rem', 0]}>
            <Button
              type="button"
              version="light-reverse"
              icon={{
                svg: backward,
                position: 'left',
              }}
              onClick={handleBackward}
            >
              Tagasi
            </Button>
          </Box>
          <Button
            type="submit"
            version="primary"
            loading={submitting}
            icon={{
              svg: forward,
              position: 'right',
            }}
          >
            Edasi
          </Button>
        </Flex>
      </Form>
    </>
  );
};

const backward = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(1 1)">
      <path
        d="M3,14A11,11,0,1,0,14,3,11,11,0,0,0,3,14Z"
        transform="translate(-3 -3)"
        fill="none"
        stroke="#a1a1a1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M22.4,20.8,18,16.4,22.4,12"
        transform="translate(-11.4 -5.4)"
        fill="none"
        stroke="#a1a1a1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12,18h8.8"
        transform="translate(-5.4 -7)"
        fill="none"
        stroke="#a1a1a1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const forward = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-2 -2)">
      <path
        d="M25,14A11,11,0,1,1,14,3,11,11,0,0,1,25,14Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M18,20.8l4.4-4.4L18,12"
        transform="translate(-4 -2.4)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12,18h8.8"
        transform="translate(-2.4 -4)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default StepThree;
