import React from "react";
import styled from "styled-components";

import Box from "../UI/Layout/Box";
import { NavLink } from "react-router-dom";

const Nav = () => {
  return (
    <Box as="aside">
      <NavItems>
        <NavLink
          style={{
            textDecoration: "none",
            color: "#2E2E46",
          }}
          activeStyle={{
            color: "#1774ED",
            textDecoration: "none",
          }}
          to="/konto/kuulutused"
        >
          Kuulutused
        </NavLink>
        <NavLink
          style={{
            textDecoration: "none",
            color: "#2E2E46",
          }}
          activeStyle={{
            color: "#1774ED",
            textDecoration: "none",
          }}
          to="/konto/arved"
        >
          Arved
        </NavLink>
        <NavLink
          style={{
            textDecoration: "none",
            color: "#2E2E46",
          }}
          activeStyle={{
            color: "#1774ED",
            textDecoration: "none",
          }}
          to="/konto/paringud"
        >
          Päringud
        </NavLink>
        <NavLink
          style={{
            textDecoration: "none",
            color: "#2E2E46",
          }}
          activeStyle={{
            color: "#1774ED",
            textDecoration: "none",
          }}
          to="/konto/seaded"
        >
          Seaded
        </NavLink>
      </NavItems>
    </Box>
  );
};

const NavItems = styled.nav`
  display: inline-flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1.25rem 0;
  padding-left: 1rem;
  background: #f9f9f9;
  a {
    display: flex;
    text-decoration: "none";
    color: "#2E2E46";
    font-family: "Lato-Bold";
    font-size: 14px;
    margin-right: 1.75rem;
  }
  @media (min-width: 48em) {
    a {
      font-size: 16px;
    }
  }
  @media (min-width: 62em) {
    a {
      font-size: 18px;
    }
  }
  @media (min-width: 75em) {
    display: flex;
    flex-direction: column;
    background: none;
    padding: 5rem 0 5rem 5rem;
    a {
      font-size: 20px;
      margin-right: 0;
      margin-bottom: 1.75rem;
    }
  }
`;

export default Nav;
